/* eslint-disable react/self-closing-comp */
import React from 'react';

function loader() {
  return (
    <div
      className="loader w-100 d-flex justify-content-center align-items-center"
      style={{ height: 'max(60vh, 300px)' }}
    >
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default loader;
