import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import { defaultError } from '../../../utils/constants';
// import { parseVideo, openInNewTab } from '../../utils/helper';
import customToast from '../../../utils/customToast';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import useLocalStorage from '../../../hooks/useLocalStorage';

function UserContext({ children, Layout }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showcaseList, setShowcaseList] = useState([]);
  const filterDataLocal = useLocalStorage('filterData');

  useEffect(() => {
    callShowcaseList();
  }, []);

  const callShowcaseList = async () => {
    try {
      const data = await apiMiddleWare({ type: 'get', url: '/showcase/list-all' });
      if (!data) {
        return false;
      }
      if (data?.data?.ok) {
        const allPacket = data?.data?.packet;
        setShowcaseList(allPacket);
        if (!filterDataLocal?.showcaseID) {
          secureLocalStorage?.setItem('filterData', {
            showcaseID: allPacket?.[0]?._id,
            searchTime: 'last7',
            startDate: '',
            endDate: '',
          });
          window.dispatchEvent(new Event('storage'));
        }

        return true;
      }

      return false;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  return isLoading ? (
    <>Loading...</>
  ) : (
    <Layout>
      <Outlet context={{ showcaseList }} />
    </Layout>
  );
}

export default UserContext;
