import { Container, Stack } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
// import { sentenceCase } from 'change-case';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  Tooltip,
  TextField,
  FormControl,
  Select,
  Grid,
  Checkbox,
  ListItemText,
  Button,
} from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';

import Iconify from '../../../components/iconify';

import useLocalStorage from '../../../hooks/useLocalStorage';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { defaultError, informaGreen, tableBgColor } from '../../../utils/constants';
import { customRenderValue, handleDownload, timeZeroFormat } from '../../../utils/helper';
import useResponsive from '../../../hooks/useResponsive';

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const orderOfTable = [
  // '_id',
  'name',
  'email',
  'phone',
  'company',
  'designation',
  // 'country',
  // 'state',
  // 'industry',
  'leadSource',
  'showcaseQuery',
  'productQuery',
  'leadStatus',
  'leadScore',
  'lastClicked',
  'timeSpent',
  'createdAt',
  'notes',
  'leadQuality',
];
const customStyles = {
  _id: { label: 'ID' },
  name: { label: 'Full Name' },
  email: { label: 'Email', customClass: 'fixed-left table-header-clr' },
  phone: { label: 'Phone' },
  company: { label: 'Company' },
  designation: { label: 'Designation' },
  country: { label: 'Country' },
  state: { label: 'State' },
  industry: { label: 'Industry' },
  leadSource: { label: 'Lead Source', newLine: true },
  showcaseQuery: { label: 'Showcase Query' },
  productQuery: { label: 'Product Query' },
  leadStatus: {
    label: 'Lead Status',
    editable: true,
    type: 'select',
    options: ['New Lead', 'Reach Out', 'Meeting Scheduled', 'Not Qualified', 'Deal Won', 'Deal Lost'],
  },
  leadScore: { label: 'Lead Score', type: 'number' },
  lastClicked: { label: 'Last Visited', format: (time) => moment(time).format('llll'), width: '220px' },
  timeSpent: { label: 'Time Spent (min)' },
  createdAt: { label: 'Time Stamp', format: (time) => moment(time).format('llll'), width: '220px' },
  notes: { label: 'Notes', editable: true, type: 'textarea', multiline: true, rows: 2, width: '400px' },
  // leadQuality: { label: 'Lead Quality', editable: true, type: 'number' },
  leadQuality: { label: 'Lead Quality', editable: true, type: 'select', options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
};

function UserLeads() {
  const [isLoading, setIsLoading] = useState(false);
  const timeout = useRef();
  const smUp = useResponsive('up', 'sm');

  const { showcaseList } = useOutletContext();

  const [tableData, setTableData] = useState([
    // {
    //   _id: '45dfg4234',
    //   name: 'Kavan',
    //   email: 'kavan@samaaro.com',
    //   phone: '123212334',
    //   company: 'Samaaro',
    //   designation: 'PE',
    //   country: 'India',
    //   state: 'Gujarat',
    //   industry: 'Industry 1',
    //   leadSource: 'site',
    //   showcaseQuery: 'THIS IS THE QUERY ',
    //   productQuery: 'Yes',
    //   leadStatus: 'New Lead',
    //   leadScore: 8,
    //   timeSpent: '30m',
    //   timeStamp: 'first Time Arrived 1',
    //   notes: 'by 1 visitor',
    //   leadQuality: 2,
    // },
    // {
    //   _id: 'j98324ddqw',
    //   name: 'Anfus',
    //   email: 'anfuslonglonglonglongname@samaaro.com',
    //   phone: '8989889899',
    //   company: 'Samaaro',
    //   designation: 'PE',
    //   country: 'India',
    //   state: 'Kerala',
    //   industry: 'Industry 4',
    //   leadSource: 'site',
    //   showcaseQuery: 'THIS IS THE QUERY 2',
    //   productQuery: 'Yes',
    //   leadStatus: 'Deal Lost',
    //   leadScore: 9,
    //   timeStamp: 'first Time Arrived 2',
    //   timeSpent: '4h',
    //   notes: 'by 2 visitor',
    //   leadQuality: 3,
    // },
  ]);
  const showcaseRef = useLocalStorage('showcaseID');
  const filterDataLocal = useLocalStorage('filterData');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const [editIndex, setEditIndex] = useState(-1);
  const [callApi, setCallApi] = useState(false);
  const [filterData, setFilterData] = useState({
    showcaseID: filterDataLocal?.showcaseID || '',
    searchTime: filterDataLocal?.searchTime || 'last7',
    startDate: filterDataLocal?.startDate || '',
    endDate: filterDataLocal?.endDate || '',
  });
  const [filter, setFilter] = useState({
    value: [],
    options: ['New Lead', 'Reach Out', 'Meeting Scheduled', 'Not Qualified', 'Deal Won', 'Deal Lost'],
  });
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (filterData?.searchTime === 'custom' && filterData?.startDate !== '' && filterData?.endDate !== '') {
      secureLocalStorage?.setItem('filterData', {
        showcaseID: filterData?.showcaseID,
        searchTime: 'custom',
        startDate: filterData?.startDate,
        endDate: filterData?.endDate,
      });
      window.dispatchEvent(new Event('storage'));
      setCallApi((prev) => !prev);
    }
  }, [filterData]);

  useEffect(() => {
    getInitialData();
    // console.log('hit api');
  }, [page, rowsPerPage, callApi, filter?.value]);

  useEffect(() => {
    if (!filterDataLocal || !filterDataLocal?.showcaseID) {
      setFilterDataFunc();
    }
  }, []);

  const setFilterDataFunc = () => {
    setFilterData((prev) => ({
      ...prev,
      showcaseList,
      showcaseID: prev?.showcaseID || showcaseList?.[0]?._id || '',
    }));
    secureLocalStorage?.setItem('filterData', {
      showcaseID: filterData?.showcaseID || showcaseList?.[0]?._id || '',
      searchTime: filterData?.searchTime,
      startDate: filterData?.startDate,
      endDate: filterData?.endDate,
    });
    window.dispatchEvent(new Event('storage'));
  };

  const getInitialData = async () => {
    try {
      setIsLoading(true);
      const sendData = {
        showcaseRef: filterData?.showcaseID,
        startDate: filterData?.startDate && timeZeroFormat({ date: filterData?.startDate }),
        endDate: filterData?.endDate && timeZeroFormat({ date: filterData?.endDate, hour: '48' }),
        // startDate: filterData?.startDate,
        // endDate: filterData?.endDate,
        page: Number(page) + 1,
        items: rowsPerPage,
        filter: filter?.value,
        search: searchValue,
      };
      const res = await apiMiddleWare({
        type: 'post',
        callType: 'analytics',
        url: '/dashboard/lead-table',
        data: sendData,
      });
      if (!res) {
        setIsLoading(false);
        return false;
      }
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }

      setTotalDataCount(res?.data?.count);
      setTableData(res?.data?.packet);
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const indexField = e?.target?.dataset?.index;
    const oldValue = tableData?.[indexField]?.[name];
    if (oldValue === value) {
      return;
    }
    setEditIndex(indexField);
    setTableData((prev) => {
      const list = prev.map((i, k) => {
        if (k === Number(indexField)) {
          return { ...i, [name]: value };
        }
        return i;
      });
      return list;
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const spliteName = name.split('-')[0];
    const indexField = name.split('-')[1];
    const oldValue = tableData?.[indexField]?.[name];
    if (oldValue === value) {
      return;
    }
    setEditIndex(indexField);
    setTableData((prev) => {
      const list = prev.map((i, k) => {
        if (k === Number(indexField)) {
          return { ...i, [spliteName]: value };
        }
        return i;
      });
      return list;
    });
  };

  const handleBlurText = async () => {
    try {
      if (editIndex === -1) {
        return false;
      }

      const sendData = {
        leadID: tableData?.[editIndex]?._id,
        leadStatus: tableData?.[editIndex]?.leadStatus,
        notes: tableData?.[editIndex]?.notes,
        leadQuality: tableData?.[editIndex]?.leadQuality,
      };

      const res = await apiMiddleWare({ type: 'post', url: '/showcase/lead/edit', data: sendData });
      if (!res) {
        return false;
      }
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }

      customToast({ msg: 'Data Updated Successfully', type: 'success' });
      setEditIndex(-1);
      // setCallApi((prev) => !prev);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
      startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
      endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
    }));

    const showcaseVal = name === 'showcaseID' ? value : filterData?.showcaseID;
    const searchTimeVal = name === 'showcaseID' ? filterData?.searchTime : value;
    secureLocalStorage?.setItem('filterData', {
      showcaseID: showcaseVal,
      searchTime: searchTimeVal,
      startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
      endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
    });
    window.dispatchEvent(new Event('storage'));

    if (value !== 'custom') {
      setCallApi((prev) => !prev);
    }
  };

  const handleMultiSelect = (event) => {
    const {
      target: { value },
    } = event;
    setFilter((prev) => ({
      ...prev,
      // On autofill we get a stringified value.
      value: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSearchDebounce = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(timeout.current);
  timeout.current = setTimeout(async () => {
      setCallApi((prev) => !prev);
    }, 1500);
  };

  const getDownloadData = async ({ apiUrl, apiType = 'get', apiBody = {} }) => {
    console.log( {apiBody} )
    const res = await apiMiddleWare({ type: apiType, url: apiUrl, data: apiBody });
    if (!res?.data?.ok) {
      throw new Error(res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError);
    }
    return res?.data?.packet;
  };

  const callDownload = async () => {
    try {
      const apiBody =  {
        showcaseRef: filterData?.showcaseID,
        startDate: filterData?.startDate && timeZeroFormat({ date: filterData?.startDate }),
        endDate: filterData?.endDate && timeZeroFormat({ date: filterData?.endDate, hour: '48' }),
        page: Number(page) + 1,
        items: rowsPerPage,
        filter: filter?.value,
        search: searchValue,
      };
      const data = await getDownloadData({ apiUrl: `/download/lead-table`, apiType: "post", apiBody  });
      if (data.length === 0) {
        customToast({ msg: 'No Data Found', type: 'error' });
        return false;
      }
      customToast( { type:'success', msg : 'Download will start in few seconds'})

      const dataOrder = ['index', 'name', 'email',  'phone', 'company','designation', 'showcaseQuery','productQuery','leadStatus', 'leadScore','lastVisit','timeSpent','createdAt','note','leadQuality'];
      const dataObj = {
        index: { label: '#', valueLocation: 'index' },
        name: { label: 'Name', valueLocation: 'name' },
        email: { label: 'Email', valueLocation: 'email' },
        phone: { label: 'Phone', valueLocation: 'phone' },
        company: { label: 'Company', valueLocation: 'company' },
        designation: { label: 'Designation', valueLocation: 'designation' },
        showcaseQuery: { label: 'Showcase Query', valueLocation: 'showcaseQuery' },
        productQuery: { label: 'Product Query', valueLocation: 'productQuery' },
        leadStatus: { label: 'Lead Status', valueLocation: 'leadStatus' },
        leadScore: { label: 'Lead Score', valueLocation: 'leadScore' },
        lastVisit: { label: 'Last Visit', valueLocation: 'lastClicked',format: (time) => moment(time).format('llll') },
        timeSpent: { label: 'Time Spent(min)', valueLocation: 'timeSpent' },
        createdAt: { label: 'Time Stamp', valueLocation: 'createdAt' ,format: (time) => moment(time).format('llll')},
        note: { label: 'Notes', valueLocation: 'notes' },
        leadQuality: { label: 'Lead Quality', valueLocation: 'leadQuality' },
      };

      await handleDownload({ fileName: `lead-table`, dataOrder, dataObj, data });

      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  return (
    <>
      <Container maxWidth="xl" className="mt-4 mt-md-0">
        {isLoading ? (
          <>Loading...</>
        ) : (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3} sx={{ mb: '2rem' }}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    name="showcaseID"
                    id="outlined-select-currency"
                    select
                    label="Showcase"
                    value={filterData?.showcaseID}
                    onChange={onChangeFilter}
                  >
                    {showcaseList?.length > 0 &&
                      showcaseList?.map((i) => (
                        <MenuItem key={i?._id} value={i?._id}>
                          {i?.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    name="searchTime"
                    id="outlined-select-currency"
                    select
                    label="Search Time"
                    value={filterData?.searchTime}
                    onChange={onChangeFilter}
                    required
                  >
                    <MenuItem key={'last7'} value={'last7'}>
                      Last 7 Days
                    </MenuItem>
                    <MenuItem key={'last30'} value={'last30'}>
                      Last 30 Days
                    </MenuItem>
                    <MenuItem key={'custom'} value={'custom'}>
                      Custom
                    </MenuItem>
                  </TextField>
                </Grid>
                {filterData?.searchTime === 'custom' && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <DatePicker
                        disableFuture
                        label="Start Date"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={filterData?.startDate}
                        onChange={(newValue) => {
                          setFilterData((prev) => ({
                            ...prev,
                            startDate: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DatePicker
                        disableFuture
                        label="End Date"
                        openTo="day"
                        minDate={filterData?.startDate || dayjs()}
                        views={['year', 'month', 'day']}
                        value={filterData?.endDate}
                        onChange={(newValue) => {
                          setFilterData((prev) => ({
                            ...prev,
                            endDate: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </LocalizationProvider>

            <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
              <Form className="col-md-5 col-12 mb-3 ">
                <InputGroup>
                  <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                    <Iconify icon="ic:outline-search" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-0 shadow-none"
                    placeholder="Search by Name, Email"
                    aria-label="Search"
                    value={searchValue}
                    onChange={handleSearchDebounce}
                  />
                </InputGroup>
              </Form>
              
              <div className="col-md-4 col-12 row mb-3">
                <Form className="col-md-6 ">
                  <InputGroup>
                    <FormControl
                      className="ms-0 ms-md-auto mb-3 mt-0 me-0"
                      sx={{ m: 1, width: 'auto', flexGrow: '1', maxWidth: smUp ? 230 : '100%' }}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        displayEmpty
                        value={filter.value}
                        onChange={handleMultiSelect}
                        // input={<OutlinedInput label="Tag" />}
                        renderValue={customRenderValue}
                        sx={{ height: 36, backgroundColor: 'white', border: '0' }}
                        MenuProps={MenuProps}
                      >
                        {filter?.options?.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={filter?.value?.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputGroup>
                </Form>

                <div className="col-md-6 ">
                  <Button variant="contained" onClick={callDownload} className=" w-100">
                    Download Excel
                  </Button>
                </div>
              </div>

            </Card>

            <Card>
              {/* <Scrollbar> */}

              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      '& .MuiTableCell-head': {
                        color: 'white',
                        backgroundColor: informaGreen,
                        // textAlign: 'center',
                      },
                    }}
                  >
                    <TableRow>
                      {orderOfTable?.map((cellID, index) => (
                        <TableCell key={cellID} className={`${customStyles?.[cellID]?.customClass || ''} `}>
                          {customStyles?.[cellID]?.label || `NotFound ${cellID}`}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.length > 0 ? (
                      tableData?.map((rowData, index1) => (
                        <TableRow
                          key={rowData?._id}
                          sx={{
                            '&:nth-child(odd)': {
                              backgroundColor: tableBgColor,
                            },
                            '&:nth-child(even)': {
                              backgroundColor: 'white',
                            },
                          }}
                        >
                          {orderOfTable?.length > 0 &&
                            orderOfTable?.map((cellID, index2) => (
                              <TableCell
                                key={cellID}
                                className={` ${customStyles?.[cellID]?.customClass || ''}`}
                                sx={{
                                  maxWidth: customStyles?.[cellID]?.width || '200px',
                                  minWidth: customStyles?.[cellID]?.width || '150px',
                                  padding: '8px 14px',
                                  '& .MuiOutlinedInput-input': {
                                    backgroundColor: 'white',
                                    borderRadius: '7px',
                                  },
                                }}
                              >
                                {customStyles?.[cellID]?.editable ? (
                                  customStyles?.[cellID]?.type === 'select' ? (
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        // id="demo-simple-select"
                                        sx={{
                                          '& .MuiOutlinedInput-input': {
                                            padding: '12px 8px',
                                          },
                                        }}
                                        name={`${cellID}-${index1}`}
                                        value={rowData?.[cellID]}
                                        onChange={handleSelectChange}
                                        onBlur={handleBlurText}
                                        inputProps={{ 'data-index': index1 }}
                                      >
                                        {customStyles?.[cellID]?.options?.length > 0 &&
                                          customStyles?.[cellID]?.options?.map((i) => (
                                            <MenuItem value={i} key={i}>
                                              {i}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <TextField
                                      type={customStyles?.[cellID]?.type || 'text'}
                                      sx={{
                                        '& .MuiOutlinedInput-input': {
                                          padding: '6px 8px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                          padding: '0',
                                        },
                                      }}
                                      fullWidth
                                      name={cellID}
                                      onChange={handleFieldChange}
                                      onBlur={handleBlurText}
                                      value={rowData?.[cellID]}
                                      multiline={customStyles?.[cellID]?.multiline || false}
                                      rows={customStyles?.[cellID]?.rows || 1}
                                      inputProps={{ 'data-index': index1 }}
                                    />
                                  )
                                ) : (
                                  <>
                                    {customStyles?.[cellID]?.newLine && Array?.isArray(rowData?.[cellID]) > 0 ? (
                                      <>
                                        {rowData?.[cellID]?.map((i, index) => (
                                          <div key={index}>
                                            <Tooltip title={i} placement="bottom-start" className="cursor-pointer">
                                              <div className="ellipse-text">{i}</div>
                                            </Tooltip>
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      <Tooltip
                                        title={
                                          rowData?.[cellID] === true
                                            ? 'Yes'
                                            : rowData?.[cellID] === false
                                            ? 'No'
                                            : customStyles?.[cellID]?.format
                                            ? customStyles?.[cellID]?.format(rowData?.[cellID])
                                            : rowData?.[cellID]
                                        }
                                        placement="bottom-start"
                                        className="cursor-pointer"
                                        // onClick={() => i?.name && copyText(i?.name, 'Name Copied')}
                                      >
                                        <div className="ellipse-text">
                                          {rowData?.[cellID] === true
                                            ? 'Yes'
                                            : rowData?.[cellID] === false
                                            ? 'No'
                                            : customStyles?.[cellID]?.format
                                            ? customStyles?.[cellID]?.format(rowData?.[cellID])
                                            : rowData?.[cellID]}
                                        </div>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={20} className="text-center">
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </Scrollbar> */}

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalDataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        )}
      </Container>
    </>
  );
}

export default UserLeads;
