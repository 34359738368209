import { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { Helmet } from 'react-helmet';

// @mui
import {
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Checkbox,
    Typography,
    MenuItem,
    Grid,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    FormGroup,
    FormControlLabel,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import Swal from 'sweetalert2';
// components
import customToast from '../../../utils/customToast';
import CommonModal from '../../../components/modal/CommonModal';
import Iconify from '../../../components/iconify';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { defaultError, userRouteName, visitorRouteName, adminRouteName, productList } from '../../../utils/constants';
import termsText from '../../../utils/termsText';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const navigateData = {
    [userRouteName]: { loginLink: `/${userRouteName}/login`, registerLink: `/${userRouteName}/register` },
    [visitorRouteName]: { loginLink: `/login`, registerLink: `/register` },
    [adminRouteName]: { loginLink: `/${adminRouteName}/login`, registerLink: `/${adminRouteName}/register` },
};

export default function RegisterForm({ type }) {
    const smUp = useResponsive('up', 'sm');

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const allQueryObj = Object.fromEntries(queryParams.entries());
    const allQueryString =
        Object.keys(allQueryObj).length === 0
            ? ''
            : `?${Object.entries(allQueryObj)
                  ?.map((i) => `${i[0]}=${i[1]}`)
                  ?.join('&')}`;

    const redirectTo = location?.state?.from?.pathname || location?.state?.pathname || null;
    const prevToken = useLocalStorage('sessionInfo')?.xAuth;

    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [userData, setUserData] = useState({
        firstName: { value: '' },
        lastName: { value: '' },
        email: { value: '' },
        countryCode: { value: '91', list: [] },
        phone: { value: '' },
        otp: { value: '' },
        company: { value: '' },
        designation: { value: '' },
        industry: { value: '' },
        profilePass: { value: '', show: false },

        title: { value: '', list: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'] },
        address1: { value: '' },
        address2: { value: '' },
        country: { value: '', list: [] },
        state: { value: '', list: [] },
        city: { value: '', list: [] },
        postalCode: { value: '' },
        typeOfBusiness: {
            value: '',
            list: [
                'Solar Module Manufacturer',
                'Solar Lighting Manufacture',
                'Inverter Manufacturer',
                'Dealers & Distributor ',
                'Component supplier',
                'Mounting Solution Provider',
                'System Integrator',
                'Battery Solution Provider',
                'Battery Manufacturer',
                'Energy Storage Solution',
                'Technology & Innovative Solution',
                'Others',
            ],
        },
        productInt: { value: [], list: Object.keys(productList) },
        subProductInt: { value: [], list: [] },
        agreeTAC: false,
        agreeShare: false,
        profilePassAgain: { value: '', show: false },
        token: '',
    });
    const [menuOption, setMenuOption] = useState({
        phone: false,
        country: false,
        state: false,
        city: false,
        typeOfBusiness: false,
        prod: false,
        subProd: false,
    });
    const [optModalOpen, setOptModalOpen] = useState(false);
    const [termsModal, setTermsModal] = useState(false);

    // const [form1Status, setForm1Status] = useState(1); // {1:'Send OTP', 2:"Verify OTP",3:"Submit Form"}

    const [userData2, setUserData2] = useState({
        name: '',
        phone: '',
        email: location?.state?.email || '',
        company: '',
        designation: '',
        countryCode: { value: '', list: [] },
    });

    // useEffect
    useEffect(() => {
        if (type === userRouteName && prevToken) {
            checkLoggedInUser();
        }
        if (type === userRouteName || type === visitorRouteName) {
            getCountryCode();
            getCountryList();
        }
    }, []);

    useEffect(() => {
        if (userData.agreeTAC === true) {
            handleSubmit1()();
        }
    }, [userData.agreeTAC]);

    // user check auto login
    const checkLoggedInUser = async () => {
        try {
            const res = await apiMiddleWare({ url: '/users/check', type: 'get' });
            if (res?.data?.ok) {
                navigate(redirectTo || `/${type}/showcase/profile`);
            }
        } catch (err) {
            console.error(err);
        }
    };
    // user/register
    const handleSubmit1 =
        (resendOTP = false) =>
        async (e) => {
            e?.preventDefault();
            setIsLoading(true);

            try {
                if (!userData?.agreeTAC) {
                    await validateInfromaReg(); // verify everthing is there before showing terms
                    setTermsModal(true);
                    setIsLoading(false);
                    return true;
                }

                const toastID = toast.loading('Please wait...');

                let res;
                let verifyEmailBody;

                if (!optModalOpen || resendOTP) {
                    let errMsg = '';
                    // if (
                    //   !userData?.agreeTAC
                    // ) {
                    //   errMsg = 'Agree the terms';
                    // }

                    if (userData?.profilePass?.value !== userData?.profilePassAgain?.value) {
                        errMsg = 'Both Password should be same';
                    }

                    if (!validateEmail(userData?.email?.value) || userData?.email?.value === '') {
                        errMsg = 'Email format incorrect';
                    }

                    if (userData?.phone?.value === '') {
                        errMsg = 'Write Phone Number';
                    }

                    if (errMsg !== '') {
                        customToast({
                            type: 'error',
                            msg: errMsg,
                        });
                        setIsLoading(false);
                        closeTerms();
                        return false;
                    }

                    verifyEmailBody = await validateInfromaReg(); // verify everthig is there before sending otp

                    const { email, firstName, phone, countryCode } = userData;
                    const sendData = {
                        email: email.value,
                        firstName: firstName?.value,
                        phone: phone?.value,
                        countryCode: countryCode?.value,
                    };
                    res = await apiMiddleWare({ url: '/users/send-otp', data: sendData, type: 'post' });

                    if (res?.data?.ok) {
                        setOptModalOpen(true);
                        customToast({ updateId: toastID, type: 'update', msg: 'OTP Sent to Email/Phone', updateType: 'success' });
                    }
                } else {
                    const { email, phone, firstName, lastName, otp } = userData;

                    const sendData = {
                        email: email.value,
                        phone: phone.value,
                        firstName: firstName?.value,
                        lastName: lastName?.value,
                        otp: otp?.value,
                    };
                    res = await apiMiddleWare({ url: '/users/verify-otp', data: sendData, type: 'post' });
                    if (!res?.data?.ok) {
                        customToast({
                            updateId: toastID,
                            type: 'update',
                            msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                            updateType: 'error',
                        });
                        setIsLoading(false);
                        return false;
                    }
                    const token = res?.data?.token; // it has email/phone/firstName/lastName

                    verifyEmailBody = await validateInfromaReg();
                    if (userData?.otp?.value === '') {
                        customToast({
                            updateId: toastID,
                            type: 'update',
                            msg: 'OTP is required' || defaultError,
                            updateType: 'error',
                        });
                        setIsLoading(false);
                        return false;
                    }
                    verifyEmailBody.otp = userData?.otp?.value?.trim();
                    verifyEmailBody.source = queryParams.get('utm') || null;

                    const sendData2 = { ...verifyEmailBody, token };
                    res = await apiMiddleWare({ url: '/users/register', data: sendData2, type: 'post' });
                    if (res?.data?.ok) {
                        setOptModalOpen(false);
                        customToast({ updateId: toastID, type: 'update', msg: 'Registration Successful', updateType: 'success' });
                        resetForm1();
                        setIsLoading(false);
                        closeTerms();
                        successRegisterSwal();
                        // navidgateLogin();
                    }
                }

                if (!res?.data?.ok) {
                    customToast({
                        updateId: toastID,
                        type: 'update',
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        updateType: 'error',
                    });
                    closeTerms();
                    setIsLoading(false);
                    return false;
                }

                return true;
            } catch (err) {
                customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
                // resetForm1();
                setIsLoading(false);
                closeTerms();
                return false;
            }

            // navigate('/user/dashboard', { replace: true });
        };

    const closeTerms = (agreeStatus = false) => {
        setTermsModal(false);
        setUserData((prev) => ({ ...prev, agreeTAC: agreeStatus }));
    };

    const validateInfromaReg = async () =>
        new Promise((resolve, reject) => {
            const sendData = {};
            const {
                title,
                firstName,
                lastName,
                email,
                phone,
                countryCode,
                otp,
                designation,
                company,
                address1,
                address2,
                country,
                state,
                city,
                postalCode,
                typeOfBusiness,
                productInt,
                subProductInt,
                profilePass,
                profilePassAgain,
            } = userData;

            // token has firstName, lastName, email, phone already in it
            // if (firstName?.value === '') {reject(new Error(`First Name is required`));return false;}
            // sendData.firstName = firstName?.value?.trim();

            // if (lastName?.value === '') {reject(new Error(`Last Name is required`));return false;}
            // sendData.lastName = lastName?.value?.trim();

            // if (email?.value === '') {reject(new Error(`Email is required`));return false;}
            // sendData.email = email?.value?.trim();

            // if (phone?.value === '') {reject(new Error(`Phone is required`));return false;}
            // sendData.phone = phone?.value?.trim();

            sendData.password = profilePass?.value;

            if (title?.value === '') {
                reject(new Error(`Title is required`));
                return false;
            }
            sendData.title = title?.value;

            // if (countryCode?.value === '') {reject(new Error(`Country Code is required`));return false;}
            sendData.countryCode = countryCode?.value;

            if (designation?.value === '') {
                reject(new Error(`Designation is required`));
                return false;
            }
            sendData.designation = designation?.value?.trim();

            if (company?.value === '') {
                reject(new Error(`Company is required`));
                return false;
            }
            sendData.company = company?.value?.trim();

            // if (phone?.value === '') {reject(new Error(`Phone Number is required`));return false;}
            sendData.address1 = address1?.value?.trim();
            sendData.address2 = address2?.value?.trim();

            if (country?.value === '') {
                reject(new Error(`Country is required`));
                return false;
            }
            sendData.country = country?.value;

            sendData.state = state?.value;
            sendData.city = city?.value;

            sendData.postalCode = postalCode?.value?.trim();

            if (typeOfBusiness?.value === '') {
                reject(new Error(`Type of Businesss is required`));
                return false;
            }
            sendData.typeOfBusiness = typeOfBusiness?.value?.trim();

            if (productInt?.value?.length === 0) {
                reject(new Error(`Select atleast 1 Product Interested`));
                return false;
            }
            sendData.productInterested = productInt?.value;

            if (subProductInt?.value?.length === 0) {
                reject(new Error(`Select atleast 1 Subproduct Interested`));
                return false;
            }
            sendData.subProductInterested = subProductInt?.value;

            resolve(sendData);
            return true;
        });

    function validateEmail($email) {
        // eslint-disable-next-line no-useless-escape
        const emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,})?$/;
        return emailReg.test($email);
    }

    // user/register
    const resetForm1 = () => {
        setUserData((prev) => ({
            ...prev,
            firstName: { ...prev.firstName, value: '' },
            lastName: { ...prev.lastName, value: '' },
            email: { ...prev.email, value: '' },
            phone: { ...prev.phone, value: '' },
            otp: { ...prev.otp, value: '' },
            company: { ...prev.company, value: '' },
            designation: { ...prev.designation, value: '' },
            industry: { ...prev.industry, value: '' },
            profilePass: { ...prev.profilePass, value: '', show: false },
            countryCode: {
                ...prev?.countryCode,
                value: '91',
            },
            title: { ...prev.title, value: '' },
            address1: { ...prev.address1, value: '' },
            address2: { ...prev.address2, value: '' },
            city: { ...prev.city, value: '' },
            state: { ...prev.state, value: '' },
            country: { ...prev.country, value: '' },
            postalCode: { ...prev.postalCode, value: '' },
            typeOfBusiness: { ...prev.typeOfBusiness, value: '' },
            productInt: { ...prev.productInt, value: [] },
            subProductInt: { ...prev.subProductInt, value: [] },
            profilePassAgain: { ...prev.profilePassAgain, value: '', show: false },
            agreeShare: false,
            agreeTAC: false,
            token: '',
        }));
    };

    // user/register
    const handleChange1 = (e) => {
        const { value, name } = e.target;
        // eslint-disable-next-line prefer-const
        let changedValue = value;
        if (name === 'phone') {
            changedValue = changedValue.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
            if (changedValue.length >= 14) changedValue = changedValue.substring(0, 14);
        }
        if (name === 'otp') {
            changedValue = changedValue.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
            if (changedValue.length >= 4) changedValue = changedValue.substring(0, 4);
        }
        if (name === 'country') {
            getStateList(value);
        }
        if (name === 'state') {
            // * extracting the country and state name for the new cities api
            const country = userData?.country?.list?.find((i) => i?.iso2 === userData?.country?.value);
            const state = userData?.state?.list?.find((i) => i?.iso2 === value);
            getCityList(userData?.country?.value, value, country?.name, state?.name);
        }
        setUserData((prev) => ({
            ...prev,
            [name]: {
                ...prev[name],
                value: changedValue,
            },
        }));
    };

    const handleMultiSelect = (e) => {
        const { name, value } = e.target;
        const changedValue = typeof value === 'string' ? value.split(',') : value;

        if (name === 'productInt') {
            const subProIntVal = userData?.subProductInt?.value;
            const newSubProVal = [...subProIntVal];

            const productIntList = userData?.productInt?.list;
            const differenceProdList = productIntList.filter((i) => value?.indexOf(i) === -1);

            subProIntVal.forEach((i) => {
                differenceProdList.forEach((j) => {
                    if (productList?.[j]?.indexOf(i) !== -1) {
                        newSubProVal.splice(newSubProVal?.indexOf(i), 1);
                    }
                });
            });

            setUserData((prev) => ({
                ...prev,
                subProductInt: {
                    ...prev?.subProductInt,
                    value: newSubProVal,
                },
                productInt: {
                    ...prev?.productInt,
                    value: changedValue,
                },
            }));
        } else if (name === 'subProductInt') {
            setUserData((prev) => ({
                ...prev,
                subProductInt: {
                    ...prev?.subProductInt,
                    value: changedValue,
                },
            }));
        }
    };

    async function getCountryCode() {
        setIsLoading(true);
        try {
            const resData = await apiMiddleWare({ url: '/json/country-code-informa', type: 'get' });

            if (!resData || !resData?.data?.ok) {
                setIsLoading(false);
                return false;
            }

            if (type === userRouteName) {
                setUserData((prev) => ({
                    ...prev,
                    countryCode: {
                        ...prev.countryCode,
                        list: resData?.data?.countryCode,
                    },
                }));
            } else if (type === visitorRouteName) {
                setUserData2((prev) => ({
                    ...prev,
                    countryCode: {
                        ...prev.countryCode,
                        list: resData?.data?.countryCode,
                    },
                }));
            }
            setIsLoading(false);
            return true;
        } catch (err) {
            setIsLoading(false);
            return false;
        }
    }

    async function getCountryList() {
        try {
            const resData = await apiMiddleWare({ url: '/json/country-list', type: 'get' });

            if (!resData || !resData?.data?.ok) return new Error('Something Went Wrong');

            setUserData((prev) => ({
                ...prev,
                country: {
                    ...prev.country,
                    list: resData?.data?.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }

    async function getStateList(countryCode) {
        try {
            if (countryCode === 'Other') return true;
            const resData = await apiMiddleWare({ url: `/json/state-list/${countryCode}`, type: 'get' });
            setUserData((prev) => ({
                ...prev,
                state: {
                    ...prev.state,
                    list: resData.data.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }

    async function getCityList(countryCode, stateCode, countryName, stateName) {
        try {
            const resData = await apiMiddleWare({ url: `/json/city-list/${countryCode}/${countryName}/${stateCode}/${stateName}`, type: 'get' });
            setUserData((prev) => ({
                ...prev,
                city: {
                    ...prev.city,
                    list: resData.data.packet,
                },
            }));

            return true;
        } catch (err) {
            return false;
        }
    }

    const navidgateLogin = () => {
        navigate(`/${type}/login${allQueryString}`, { state: location?.state });
    };

    const navidgateLogin2 = () => {
        navigate(`/login`, { state: location?.state });
    };

    const loginAfterReg = async () => {
        const sendData = {
            email: userData2?.email,
        };
        try {
            const res = await apiMiddleWare({ url: '/visitors/login', data: sendData, type: 'post' });
            if (res?.data?.ok === true) {
                secureLocalStorage.setItem('sessionInfo', {
                    roles: [`${res?.data?.role || 'visitor'}`],
                    json: res?.data?.packet,
                    xAuth: res?.data?.token,
                });
                secureLocalStorage.setItem('refreshToken', res?.data?.token);
                window.dispatchEvent(new Event('storage'));

                navigate(redirectTo || '/profile');
                return true;
            }
            console.log(res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg);
            return false;
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    const successRegisterSwal = () => {
        Swal.fire({
            icon: 'success',
            title: 'Thank you for Registering',
            showCloseButton: true,
            confirmButtonText: 'Login Here',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                navidgateLogin();
            }
        });
    };

    const subProductList = Object.keys(productList)?.map((name) => {
        if (userData?.productInt?.value?.indexOf(name) === -1) return null;
        return productList?.[name]?.map((j) => (
            <MenuItem
                key={name + j}
                value={j}
                data-key={name}
                // style={getStyles(name, personName, theme)}
            >
                <Checkbox checked={userData?.subProductInt?.value.indexOf(j) > -1} />
                <ListItemText primary={j} />
            </MenuItem>
        ));
    });

    const handleOpenMenu = (type) => {
        setMenuOption((prev) => ({
            ...prev,
            [type]: true,
        }));
    };

    const handleCloseMenu = (type) => {
        setMenuOption((prev) => ({
            ...prev,
            [type]: false,
        }));
    };

    const CustomMenuClose = useCallback(
        ({ type }) => (
            <ListSubheader className="d-flex justify-content-end" sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
                <Button
                    variant="contained"
                    className="ms-auto"
                    sx={{ marginBottom: '12px', marginTop: '12px' }}
                    type="button"
                    onClick={() => handleCloseMenu(type)}
                >
                    OK
                </Button>
            </ListSubheader>
        ),
        []
    );

    const resendOtp = () => {
        handleSubmit1(true)();
    };

    return (
        <>
            <Helmet>
                <title>Join Informa Markets Renewable Energy | Seller Dashboard | Reiconnect</title>
                <meta
                    name="description"
                    content="Register for Reiconnect's Seller Dashboard at Informa Markets. Gain access to tools and networks that can help you increase your reach in the renewable sector!"
                />
            </Helmet>
            <Typography variant="p" className="fs-6" sx={{ mb: 3 }}>
                Already have an account?{' '}
                <Link to={navigateData[type].loginLink + allQueryString} state={location?.state}>
                    Log In
                </Link>{' '}
                here
            </Typography>
            {type === userRouteName && (
                <>
                    <CommonModal
                        open={optModalOpen}
                        onClose={() => {
                            setOptModalOpen(false);
                            setUserData((prev) => ({
                                ...prev,
                                otp: { value: '' },
                            }));
                            setIsLoading(false);
                        }}
                        title={`Confirm OTP`}
                        onSubmit={handleSubmit1()}
                        body={
                            <>
                                <Grid container spacing={3} className="p-4">
                                    <Grid item xs={12} className="side-by-side ">
                                        <Grid item xs={12} md={3}>
                                            <InputLabel>OTP</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <TextField
                                                // sx={{ width: '50%' }}
                                                className="fullWidth-responsive"
                                                fullWidth
                                                name="otp"
                                                value={userData?.otp?.value}
                                                placeholder={`OTP sent to your Email and Phone Number`}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                                                onChange={handleChange1}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="d-flex justify-content-center ">
                                        <Button variant="contained" onClick={resendOtp}>
                                            Resend OTP
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    />

                    <CommonModal
                        open={termsModal}
                        onClose={() => closeTerms()}
                        title={`Terms & Conditions`}
                        onSubmit={() => {
                            setUserData((prev) => ({ ...prev, agreeTAC: true }));
                            setTermsModal(false);
                        }}
                        submitText={'Agree'}
                        body={
                            <section className="px-md-3">
                                {termsText?.map((i, index1) => (
                                    <div key={index1}>
                                        <header className="fw-bold">
                                            {index1 + 1} : {i?.title?.trim()}
                                        </header>
                                        <ul className="mb-3 ms-3 ms-md-4" style={{ fontSize: '.85rem' }}>
                                            {i?.points?.map((j) => (
                                                <>
                                                    {typeof j === 'string' ? (
                                                        <li>{j.trim()}</li>
                                                    ) : (
                                                        <li>
                                                            <span> {j?.title?.trim()}</span>
                                                            <ul className="ms-3 ms-md-4">
                                                                {j?.points?.map((k, index3) => (
                                                                    <li key={index3}>{k?.trim()}</li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    )}
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </section>
                        }
                    />
                    <form
                        action=""
                        className="h-100 w-100 px-2 mb-2 py-3 form-scroll"
                        style={{ width: 'min(560px,80vw)' }}
                        onSubmit={handleSubmit1()}
                    >
                        {/* <Stack spacing={3}> */}
                        <Grid container spacing={2} className="  pe-2">
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="title"
                                    id="outlined-select-currency"
                                    select
                                    label="Title"
                                    value={userData?.title?.value}
                                    onChange={handleChange1}
                                    required
                                >
                                    <MenuItem key={''} value={''}>
                                        -- Select --
                                    </MenuItem>
                                    {userData?.title?.list?.length > 0 &&
                                        userData?.title?.list?.map((i) => (
                                            <MenuItem key={i} value={i}>
                                                {i}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                    value={userData?.firstName?.value}
                                    onChange={handleChange1}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="lastName"
                                    label="Last Name"
                                    value={userData?.lastName?.value}
                                    onChange={handleChange1}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="countryCode"
                                    id="outlined-select-currency"
                                    select
                                    label="Country Code"
                                    value={userData?.countryCode?.value}
                                    onChange={handleChange1}
                                >
                                    <MenuItem key={''} value={''}>
                                        -- Select --
                                    </MenuItem>
                                    {userData?.countryCode?.list?.length > 0 &&
                                        userData?.countryCode?.list?.map((i) => (
                                            <MenuItem key={`${i?.country}-${i?.code}`} value={i?.code}>
                                                {`${i?.country} (+${i?.code})`}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField fullWidth name="phone" label="Phone" value={userData?.phone?.value} onChange={handleChange1} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Official Email"
                                    value={userData?.email?.value}
                                    onChange={handleChange1}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="designation"
                                    label="Designation"
                                    value={userData?.designation?.value}
                                    onChange={handleChange1}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="company"
                                    label="Company Name"
                                    value={userData?.company?.value}
                                    onChange={handleChange1}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="typeOfBusiness"
                                    id="outlined-select-currency"
                                    select
                                    label="Type Of Business"
                                    value={userData?.typeOfBusiness?.value}
                                    onChange={handleChange1}
                                    required
                                >
                                    <MenuItem key={''} value={''}>
                                        -- Select --
                                    </MenuItem>
                                    {userData?.typeOfBusiness?.list?.length > 0 &&
                                        userData?.typeOfBusiness?.list?.map((i) => (
                                            <MenuItem key={i} value={i}>
                                                {i}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="address1"
                                    label="Address Line 1"
                                    value={userData?.address1?.value}
                                    onChange={handleChange1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="address2"
                                    label="Address Line 2"
                                    value={userData?.address2?.value}
                                    onChange={handleChange1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="postalCode"
                                    label="Postal Code"
                                    value={userData?.postalCode?.value}
                                    onChange={handleChange1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="country"
                                    id="outlined-select-currency"
                                    select
                                    label="Country"
                                    value={userData?.country?.value}
                                    onChange={handleChange1}
                                    required
                                >
                                    <MenuItem key={''} value={''}>
                                        -- Select --
                                    </MenuItem>
                                    {userData?.country?.list &&
                                        userData?.country?.list?.length > 0 &&
                                        userData?.country?.list?.map((i) => (
                                            <MenuItem key={i?.id} value={i?.iso2}>
                                                {i?.name}
                                            </MenuItem>
                                        ))}
                                    <MenuItem key={'Other'} value={'Other'}>
                                        Other
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="state"
                                    id="outlined-select-currency"
                                    select
                                    label="State"
                                    value={userData?.state?.value}
                                    onChange={handleChange1}
                                >
                                    <MenuItem key={''} value={''}>
                                        -- Select --
                                    </MenuItem>
                                    {userData?.state?.list?.length > 0 &&
                                        userData?.state?.list?.map((i) => (
                                            <MenuItem key={i?.id} value={i?.iso2}>
                                                {i?.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="city"
                                    id="outlined-select-currency"
                                    select
                                    label="City"
                                    value={userData?.city?.value}
                                    onChange={handleChange1}
                                >
                                    <MenuItem key={''} value={''}>
                                        -- Select --
                                    </MenuItem>
                                    {userData?.city?.list?.length > 0 &&
                                        userData?.city?.list?.map((i) => (
                                            <MenuItem key={i?.id} value={i?.name}>
                                                {i?.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Product Categories*</InputLabel>
                                    <Select
                                        open={menuOption?.prod}
                                        onOpen={() => handleOpenMenu('prod')}
                                        onClose={() => handleCloseMenu('prod')}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        fullWidth
                                        multiple
                                        name="productInt"
                                        value={userData?.productInt?.value}
                                        onChange={handleMultiSelect}
                                        input={<OutlinedInput label="Products*" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        // MenuProps={MenuProps}
                                        required
                                    >
                                        {userData?.productInt?.list?.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                // style={getStyles(name, personName, theme)}
                                            >
                                                <Checkbox checked={userData?.productInt?.value.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                        <CustomMenuClose type="prod" />
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-name-label">Sub Product Categories*</InputLabel>
                                    <Select
                                        open={menuOption?.subProd}
                                        onOpen={() => handleOpenMenu('subProd')}
                                        onClose={() => handleCloseMenu('subProd')}
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        fullWidth
                                        multiple
                                        name="subProductInt"
                                        value={userData?.subProductInt?.value}
                                        onChange={handleMultiSelect}
                                        input={<OutlinedInput label="Sub Products*" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={{
                                            style: {
                                                maxHeight: 400,
                                                maxWidth: 600,
                                            },
                                        }}
                                        required
                                    >
                                        {subProductList}
                                        {subProductList.length > 0 && <CustomMenuClose type="subProd" />}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="profilePass"
                                    label="Profile Password"
                                    type={userData.profilePass?.show ? 'text' : 'password'}
                                    value={userData?.profilePass?.value}
                                    onChange={handleChange1}
                                    required
                                    // InputProps={{
                                    //   endAdornment: (
                                    //     <InputAdornment position="end">
                                    //       <IconButton
                                    //         onClick={() =>
                                    //           setUserData((prev) => ({
                                    //             ...prev,
                                    //             profilePass: { ...prev.profilePass, show: !prev?.profilePass?.show },
                                    //           }))
                                    //         }
                                    //         edge="end"
                                    //       >
                                    //         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    //       </IconButton>
                                    //     </InputAdornment>
                                    //   ),
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="profilePassAgain"
                                    label="Confirm Profile Password"
                                    type={userData?.profilePassAgain?.show ? 'text' : 'password'}
                                    value={userData?.profilePassAgain?.value}
                                    onChange={handleChange1}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setUserData((prev) => ({
                                                            ...prev,
                                                            profilePassAgain: { ...prev.profilePassAgain, show: !prev?.profilePassAgain?.show },
                                                        }))
                                                    }
                                                    edge="end"
                                                >
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} flexGrow={1}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setUserData((prev) => ({ ...prev, agreeTAC: !prev.agreeTAC }))}
                        checked={userData?.agreeTAC}
                      />
                    }
                    label="I agree to the Terms and Conditions and Privacy Policy."
                  />
                </FormGroup>
              </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={6} flexGrow={1}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setUserData((prev) => ({ ...prev, agreeShare: !prev.agreeShare }))}
                        checked={userData?.agreeShare}
                      />
                    }
                    label="I agree to share my details with seller with whom I connect."
                  />
                </FormGroup>
              </Grid> */}
                        </Grid>

                        {/* </Stack> */}
                        <div className="d-flex flex-column flex-md-row mt-3 mt-md-4 align-items-end gap-2 gap-md-5">
                            {/* <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}> */}
                            {/* <Checkbox name="remember" label="Remember me" /> */}
                            <div className="w-100 w-md-50 ">
                                <Button type="button" className="green-border-btn border-radius-15px w-100 " size="large" onClick={resetForm1}>
                                    Reset Form
                                </Button>
                            </div>
                            {/* </Stack> */}

                            <div className="w-100 w-md-50">
                                <LoadingButton
                                    fullWidth
                                    className="green-btn border-radius-15px"
                                    size="large"
                                    type="submit"
                                    disabled={isLoading}
                                    variant="contained"
                                >
                                    Register
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}
