import PropTypes from 'prop-types';
// @mui
import { AppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components
import Iconify from '../../../../components/iconify';
//
import useResponsive from '../../../../hooks/useResponsive';
// import CommonCircularProgress from '../../../../components/circular-progress/CommonCircularProgress';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 110;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    backgroundColor: '#f2f7ff',
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
    const smUp = useResponsive('up', 'sm');

    return (
        <StyledRoot>
            <StyledToolbar>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        display: { lg: 'none' },
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>

                {/* <Searchbar /> */}
                <Stack direction="row" alignItems="center">
                    <Typography variant="h4" gutterBottom className="black-login my-auto ms-0" sx={{ fontSize: smUp ? '1.25rem' : '.9rem' }}>
                        {window.location.pathname.includes('edit')
                            ? 'My Profile'
                            : window.location.pathname.includes('change-password')
                            ? 'Change Password'
                            : 'Verify Email/Phone'}
                    </Typography>
                </Stack>
                {/* <Box sx={{ flexGrow: 1 }} /> */}

                {/* <CommonCircularProgress /> */}

                {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                    className="mx-auto me-md-0 my-2 my-md-auto"
                >
                    <AccountPopover />
                </Stack> */}
            </StyledToolbar>
        </StyledRoot>
    );
}
