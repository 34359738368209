/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Container, Grid, InputLabel, TextField, Tooltip, Typography } from '@mui/material';
import Carousel2 from 'react-multi-carousel';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';

import Iconify from '../../components/iconify';
import { defaultError, defaultProductImg, S3BucketBaseUrl } from '../../utils/constants';
import { parseVideo, openInNewTab } from '../../utils/helper';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import CommonModal from '../../components/modal/CommonModal';
import useResponsive from '../../hooks/useResponsive';
import useLocalStorage from '../../hooks/useLocalStorage';

function ShowcaseProductSingle() {
  const { showcaseUnique, productID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const smUp = useResponsive('up', 'sm');
  const lgUp = useResponsive('up', 'lg');
  const { showcaseData, HEADER_DESKTOP, HEADER_MOBILE } = useOutletContext();
  const roleData = useLocalStorage('sessionInfo')?.roles;

  const [showcaseID, setShowcaseID] = useState(location?.state?.showcaseID || showcaseData?._id);
  const [showcaseName, setShowcaseName] = useState(showcaseData?.name);
  const [companyRef, setCompanyRef] = useState(location?.state?.companyRef || showcaseData?.companyRef);

  const [isLoading, setIsLoading] = useState(false);
  const [likedData, setLikedData] = useState([]);
  const [callLikedApi, setCallLikedApi] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const [allData, setAllData] = useState({
    id: '',
    title: '',
    description: '',
    hashtags: [],
    url: '',
    unit: '',
    minQuantity: '',
    pricePerUnit: '',
    featureDescription: '',
    currency: {},
    galleryItems: [],
    catalogItems: [],
    availableCountry: [],
    productCategory: [],
    subproductCategory: [],
  });
  const [inquireModal, setInquireModal] = useState({
    open: false,
    data: {
      topic: '',
      query: '',
    },
  });

  useEffect(() => {
    if (Object.keys(showcaseData).length > 0) getProduct();
  }, [showcaseData, productID]);

  useEffect(() => {
    if (Object.keys(showcaseData).length > 0) {
      if (showcaseID && productID && companyRef) {
        if (allProducts?.length === 0) getAllProducts(showcaseID);
        // callAnalyticsProduct(showcaseID, companyRef, productID); // uncomment after analytics done
      }
    }
  }, [productID, showcaseData]);

  useEffect(() => {
    getLikedData();
  }, [callLikedApi]);

  const getProduct = async () => {
    try {
      setIsLoading(true);
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/product/get/${showcaseID}/${productID}` });
      if (!res) {
        // product not found
        backButton();
        setIsLoading(false);
        return false;
      }
      if (!res?.data?.ok) {
        // error in response
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      const dataHere = res?.data?.packet;
      setAllData((prev) => ({
        ...prev,
        id: dataHere?._id,
        title: dataHere?.title,
        description: dataHere?.description,
        // hashtags: dataHere?.hashtags,
        // url: dataHere?.url,
        unit: dataHere?.unit,
        minQuantity: dataHere?.minQuantity,
        pricePerUnit: dataHere?.pricePerUnit,
        featureDescription: dataHere?.featureDescription,
        currency: dataHere?.currency,
        galleryItems: dataHere?.productResourceRef?.galleryItems,
        catalogItems: dataHere?.productResourceRef?.catalogItems,
        availableCountry: dataHere?.availableCountry || [],
        productCategory: dataHere?.productCategory || [],
        subproductCategory: dataHere?.subproductCategory || [],
      }));
      setIsLoading(false);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const goToShowcasePage = () => {
    navigate(`/showcase/${showcaseUnique}`);
  };

  const getShowcaseID = async () => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/view/${showcaseUnique}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        navigate('/404');
        return false;
      }

      setShowcaseID(res?.data?.packet?._id);
      setCompanyRef(res?.data?.packet?.companyRef);
      // getAllProducts(res?.data?.packet?._id);
      // callAnalyticsProduct(res?.data?.packet?._id, res?.data?.packet?.companyRef, productID);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const getAllProducts = async (showcaseId) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/product/list-all/${showcaseId}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      // const otherProducts = res?.data?.packet?.filter((i) => i?._id !== productID);
      const otherProducts = res?.data?.packet;
      const productList = otherProducts?.filter((i) => i?.productID);
      const productSorted = productList?.sort((a, b) => a.priority - b.priority);
      const productSpread = productSorted?.map((i) => i?.productID);
      setAllProducts(productSpread);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  async function callAnalyticsProduct(showcaseID, companyRef, productID) {
    try {
      // if (!roleData?.length > 0 || roleData?.includes('owner')) {
      //   return false;
      // }
      const sendData = {
        showcaseRef: showcaseID,
        companyRef,
        productRef: productID,
      };
      const data = await apiMiddleWare({ type: 'post', url: '/click/product', callType: 'analytics', data: sendData });
      if (!data) {
        return false;
      }
      if (!data?.data?.ok) {
        console.log('ok false', data?.data);
        return false;
      }
      return true;
    } catch (err) {
      console.log('ok false', err);
      // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  }

  const handleImageSelect = (selectedIndex, e) => {
    setImgIndex(selectedIndex);
  };
  const carouselSelect2 = (index) => () => {
    setImgIndex(index);
  };

  function getThumbnailLink(link) {
    const data = parseVideo(link);
    return data?.thumb;
  }

  const backButton = () => {
    navigate(`/showcase/${showcaseUnique}/product`);
  };

  const openProduct = (id) => () => {
    navigate(`/showcase/${showcaseUnique}/product/${id}`, {
      state: { showcaseID, companyRef: location?.state?.companyRef },
    });
  };

  const openDoc = (link) => () => {
    // navigate(`/showcase/${showcaseUnique}/product/${id}`, { state: { showcaseID } });
    openInNewTab()(link);
  };

  const onCloseInquireModal = () => {
    setInquireModal((prev) => ({
      ...prev,
      open: false,
      data: {
        query: '',
        topic: '',
      },
    }));
  };

  const onOpenInquireModal = () => {
    setInquireModal((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const onChangeInquire = (e) => {
    const { name, value } = e.target;
    setInquireModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  const validateInquireModal = async () =>
    new Promise((resolve, reject) => {
      const sendData = {};

      if (inquireModal?.data?.topic === '') {
        reject(new Error(`Topic is required`));
        return false;
      }
      sendData.topic = inquireModal?.data?.topic?.trim();

      if (inquireModal?.data?.query === '') {
        reject(new Error(`Query is required`));
        return false;
      }
      sendData.query = inquireModal?.data?.query?.trim();

      resolve(sendData);
      return true;
    });

  const submitInquire = async () => {
    try {
      const sendData = await validateInquireModal();

      const finalData = { ...sendData, companyRef, showcaseRef: showcaseID, productRef: productID };

      const res = await apiMiddleWare({
        type: 'post',
        url: `/visitors/product-query`,
        data: finalData,
      });

      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }

      customToast({
        msg: 'Query Submitted Successfully',
        type: 'success',
      });
      onCloseInquireModal();
      return true;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const callLikeApi = async ({ productRef, showcaseRef, companyRef }) => {
    try {
      const sendData = {
        productRef,
        showcaseRef,
        companyRef,
      };
      const res = await apiMiddleWare({ type: 'post', url: `/visitors/product-like`, data: sendData });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      setCallLikedApi((prev) => !prev);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const getLikedData = async () => {
    try {
      if (!roleData?.length > 0 || roleData?.includes('owner')) {
        return false;
      }
      const sendData = {
        showcaseRef: showcaseData?._id,
        companyRef: showcaseData?.companyRef,
      };
      const res = await apiMiddleWare({
        type: 'get',
        url: `/visitors/product-like`,
        // data: sendData
      });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      setLikedData(res?.data?.package);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const catalogContent = allData?.catalogItems?.map((i, index) => (
    <div key={index} className="col-6 col-md-4 col-lg-4 py-2" onClick={openDoc(`${S3BucketBaseUrl}${i?.source}`)}>
      <div className="doc__box">
        <div className="icon__box p-3 fs-6">
          {i?.extension === 'pdf' ? (
            // <Iconify icon="ph:file-pdf-light" width="10" />
            <img src="/assets/icons/pdf-icon-sm.png" alt="PDF-ICON" className="doc_icon_size" />
          ) : i?.extension === 'xls' || i?.extension === 'xlsx' ? (
            // <Iconify icon="ph:file-xls-light" width="10" />
            <img src="/assets/icons/xls-icon-sm.png" alt="XLS-ICON" className="doc_icon_size" />
          ) : (
            // 'doc', 'docx'
            // <Iconify icon="ph:file-doc-light" width="10" />
            <img src="/assets/icons/doc-icon-sm.png" alt="DOC-ICON" className="doc_icon_size" />
          )}
        </div>
        <div className={`title__box p-2 ${smUp ? 'w-150px' : 'w-250px'}`}>
          <Tooltip
            title={i?.title}
            placement={'bottom-start'}
            className="cursor-pointer"
            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
          >
            <div className="ellipse-text text-center text-md-start">{i?.title}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  ));

  const goBack = () => {
    navigate(-1);
  };
  // console.log(allData);

  return isLoading ? (
    <>Loading...</>
  ) : (
    <>
      <CommonModal
        open={inquireModal?.open}
        onClose={onCloseInquireModal}
        title={`Product Inquiry`}
        onSubmit={submitInquire}
        body={
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Topic</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    // fullWidth
                    name="topic"
                    value={inquireModal?.data?.topic}
                    placeholder="Type Topic of Query Here"
                    onChange={onChangeInquire}
                    inputProps={{ maxLength: 100 }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Query</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    // sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    fullWidth
                    name="query"
                    placeholder="Type Descriptive Query Here"
                    value={inquireModal?.data?.query}
                    onChange={onChangeInquire}
                    multiline
                    rows={5}
                    // inputProps={{ maxLength: 30 }}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <Container
        maxWidth="xl"
        style={{ marginTop: lgUp ? HEADER_DESKTOP : HEADER_MOBILE }}
        className="px-4 px-md-5"
        sx={{
          pb: 2,
        }}
      >
        {/* <div>
          <div className="display-5 cursor-pointer" onClick={backButton}>
            <Iconify icon="typcn:arrow-back" width="10" />
          </div>
        </div> */}
        {/* <div className="my-3 d-flex justify-content-between "></div> */}
        <div className="row ">
          <div className="col-lg-5 px-2">
            <div className=" sticky-top " style={{ top: lgUp ? HEADER_DESKTOP : HEADER_MOBILE }}>
              <div className=" mb-3 d-flex justify-content-between align-items-center  ">
                <div className=" me-3">
                  <Tooltip title={'Go Back '} placement="bottom-start" className="cursor-pointer ">
                    <Iconify icon="ep:back" width={25} sx={{ color: 'red' }} onClick={goBack} />
                  </Tooltip>
                </div>
                <Typography variant="h4" className="w-75 mx-auto text-center">
                  <Tooltip title={allData?.title} placement="bottom" className="cursor-pointer ">
                    <div className="ellipse-text w-100">{allData?.title || '-'}</div>
                  </Tooltip>
                </Typography>

                {/* <div className="ms-auto my-auto d-flex   ">
                  <div
                    className=" d-flex my-auto cursor-pointer"
                    onClick={() => {
                      if (!roleData?.length > 0 || roleData?.includes('owner')) {
                        navigate(`/login`, {
                          state: { from: location },
                        });
                      } else {
                        callLikeApi({
                          productRef: allData?.id,
                          showcaseRef: showcaseID,
                          companyRef,
                        });
                      }
                    }}
                  >
                    {likedData?.includes(allData?.id) ? (
                      <Iconify icon="mdi:cards-heart" width={25} sx={{ color: 'red' }} />
                    ) : (
                      <Iconify icon="mdi:cards-heart-outline" width={25} style={{ color: 'black' }} />
                    )}
                  </div>
                </div> */}
              </div>
              <div className="mb-3">
                {allData?.galleryItems?.length > 0 && (
                  <>
                    <Carousel
                      activeIndex={imgIndex}
                      onSelect={handleImageSelect}
                      pause={'hover'}
                      interval={3000}
                      controls={false}
                      indicators={false}
                      style={{ maxWidth: 'auto' }}
                    >
                      {allData?.galleryItems?.map((i, index) => (
                        <Carousel.Item key={index}>
                          <div className="product__img">
                            <img
                              className=""
                              src={i?.type === 'image' ? `${S3BucketBaseUrl}${i?.source}` : getThumbnailLink(i?.source)}
                              alt={i?.title}
                            />
                          </div>
                          <Carousel.Caption>
                            <h3>{i?.title}</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <Carousel2
                      autoPlay
                      autoPlaySpeed={3000}
                      // focusOnSelect
                      // showDots
                      containerClass="container-with-dots mt-2 single-product"
                      renderButtonGroupOutside
                      responsive={responsive}
                      infinite
                      style={{ maxWidth: '400px' }}
                    >
                      {allData?.galleryItems?.map((i, index) => (
                        <div
                          className="product__img "
                          key={index}
                          style={{ maxHeight: smUp ? '80px' : '70px' }}
                          onClick={carouselSelect2(index)}
                        >
                          <img
                            className=""
                            style={{ objectFit: 'cover' }}
                            src={i?.type === 'image' ? `${S3BucketBaseUrl}${i?.source}` : getThumbnailLink(i?.source)}
                            alt={i?.title}
                          />
                        </div>
                      ))}
                    </Carousel2>
                  </>
                )}
                {!smUp && (
                  <Button
                    variant="primary"
                    type="button"
                    className="w-100 green-btn"
                    onClick={() => {
                      if (!roleData?.length > 0 || roleData?.includes('owner')) {
                        navigate(`/login`, {
                          state: { from: location },
                        });
                      } else {
                        onOpenInquireModal();
                      }
                    }}
                  >
                    Inquire Now
                  </Button>
                )}

                {smUp && (
                  <Button
                    variant="primary"
                    type="button"
                    className="w-100 green-btn"
                    onClick={() => {
                      if (!roleData?.length > 0 || roleData?.includes('owner')) {
                        navigate(`/login`, {
                          state: { from: location },
                        });
                      } else {
                        onOpenInquireModal();
                      }
                    }}
                  >
                    Inquire Now
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-7 px-2 px-md-4">
            <div className="mb-3 d-flex fw-bold">
              <span onClick={goToShowcasePage} className="cursor-pointer  green-color">
                {showcaseName}
              </span>
              <span className="mx-2 mx-md-3 d-flex align-items-center "> / </span>
              <span className="cursor-pointer  green-color">{allData?.title}</span>
            </div>
            {allData?.hashtags?.length > 0 && (
              <div className="mb-3 ">
                {/* <Typography variant="h6">Hashtags</Typography> */}
                <div className="d-flex flex-wrap">
                  {allData?.hashtags?.map((i, index) => (
                    <span key={index} className="hashtag-box-informa">
                      {i?.trim()}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {allData?.description && (
              <div>
                {/* <Typography variant="h6">Description</Typography> */}
                <div className=" mb-3 font-body-custom">
                  <div dangerouslySetInnerHTML={{ __html: allData?.description }} />
                </div>
              </div>
            )}

            <div className="border-custom h-auto bg-white row mx-0 mb-3 pt-3">
              <div className="col-6 display-name-value">
                <div>Currency</div>
                <Tooltip title={allData?.currency?.name} placement="bottom-start" className="cursor-pointer">
                  <div className="ellipse-text">
                    {allData?.currency?.name || '-'} {allData?.currency?.symbol ? `(${allData?.currency?.symbol})` : ''}{' '}
                  </div>
                </Tooltip>
              </div>
              <div className="col-6 display-name-value">
                <div>Price Per Piece</div>

                <Tooltip
                  title={`${allData?.pricePerUnit} ${allData?.currency?.code} / 1 Piece`}
                  placement={'bottom-start'}
                  className="cursor-pointer"
                >
                  <div className="ellipse-text">
                    {allData?.pricePerUnit} {allData?.currency?.code} / 1 Piece
                  </div>
                </Tooltip>
              </div>
              <div className="col-6 display-name-value">
                <div>Unit</div>
                <Tooltip title={allData?.unit?.toUpperCase()} placement="bottom-start" className="cursor-pointer">
                  <div className="ellipse-text">{allData?.unit?.toUpperCase()}</div>
                </Tooltip>
              </div>
              <div className="col-6 display-name-value ">
                <div>Minimum Quantity </div>
                <Tooltip title={allData?.minQuantity} placement={'bottom-start'} className="cursor-pointer">
                  <div className="ellipse-text">{allData?.minQuantity}</div>
                </Tooltip>
              </div>
            </div>

            {allData?.availableCountry?.length > 0 && (
              <div className="my-2">
                <Typography variant="h5" className=" mt-4 font-title-custom">
                  Available In Country
                </Typography>
                <div className="d-flex flex-wrap">
                  {allData?.availableCountry?.map((i, index) => (
                    <span key={index} className="hashtag-box-informa">
                      {i?.trim()}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {allData?.productCategory?.length > 0 && (
              <div className="my-2">
                <Typography variant="h5" className=" mt-4 font-title-custom">
                  Product Category
                </Typography>
                <div className="d-flex flex-wrap">
                  {allData?.productCategory?.map((i, index) => (
                    <span key={index} className="hashtag-box-informa">
                      {i?.trim()}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {allData?.subproductCategory?.length > 0 && (
              <div className="my-2">
                <Typography variant="h5" className=" mt-4 font-title-custom">
                  Subproduct Category
                </Typography>
                <div className="d-flex flex-wrap">
                  {allData?.subproductCategory?.map((i, index) => (
                    <span key={index} className="hashtag-box-informa">
                      {i?.trim()}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {allData?.featureDescription && (
              <div className="my-2">
                <Typography variant="h5" className=" mt-4 font-title-custom">
                  Feature Description
                </Typography>
                <div className=" mb-3 font-body-custom">
                  <div dangerouslySetInnerHTML={{ __html: allData?.featureDescription }} />
                </div>
              </div>
            )}

            {allData?.catalogItems?.length > 0 && (
              <div className="my-2">
                <Typography variant="h5" className=" mt-4 font-title-custom">
                  Catalogue
                </Typography>

                <div className="row ">
                  {smUp ? (
                    allData?.resourceData?.documentItems?.length > 8 ? (
                      <div className="container-see-more mb-3">
                        <input id="ch" type="checkbox" />
                        <label htmlFor="ch" />
                        <div className="see-more-content row h-200px">{catalogContent}</div>
                      </div>
                    ) : (
                      catalogContent
                    )
                  ) : allData?.resourceData?.documentItems?.length > 4 ? (
                    <div className="container-see-more mb-3">
                      <input id="ch" type="checkbox" />
                      <label htmlFor="ch" style={{ marginRight: '12px' }} />
                      <div className="see-more-content row h-420px">{catalogContent}</div>
                    </div>
                  ) : (
                    catalogContent
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {allProducts?.length > 0 && (
          <>
            {/* <div>
              <Typography variant="h5" className=" fs-5 mt-4">
                Other Products
              </Typography>
              <div className="row ">
                {allProducts
                  ?.filter((i) => i?._id !== productID)
                  ?.map((i, index) => (
                    <div key={index} className="col-6 col-sm-4 col-md-2 mb-3 py-2 product_max_ht">
                      <div
                        className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white cursor-pointer"
                        onClick={openProduct(i?._id)}
                      >
                        <div className="display-3  position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                 

                          {i?.productResourceRef?.galleryItems[0] ? (
                            <img src={`${S3BucketBaseUrl}${i?.productResourceRef?.galleryItems[0]?.source}`} alt="" />
                          ) : (
                            <div
                              className="p-4 p-md-3 d-flex justify-content-center align-items-center"
                              style={{ height: '100%' }}
                            >
                              <Iconify icon="material-symbols:hide-image-outline-rounded" width="10" />
                            </div>
                          )}
                          <span
                            className=" position-absolute d-flex cursor-pointer scale-like"
                            style={{ left: '8px', top: '8px' }}
                            onClick={() => {
                              if (!roleData?.length > 0 || roleData?.includes('owner')) {
                                navigate(`/login`, {
                                  state: { from: location },
                                });
                              } else {
                                callLikeApi({
                                  productRef: i?._id,
                                  showcaseRef: i?.showcaseRef,
                                  companyRef: i?.companyRef,
                                });
                              }
                            }}
                          >
                            {likedData?.includes(i?._id) ? (
                              <Iconify icon="mdi:cards-heart" width={25} sx={{ color: 'red' }} />
                            ) : (
                              <Iconify
                                icon="mdi:cards-heart-outline"
                                width={25}
                                style={{
                                  mixBlendMode: 'difference',
                                  // color: i?.productResourceRef?.galleryItems[0] ? 'white' : 'black',
                                }}
                              />
                            )}
                          </span>
                        </div>
                        <div className="title__box p-2 bg-light font-body-custom " style={{ height: '40px' }}>
                          <span className={smUp ? 'w-200px' : 'w-150px'}>
                            <Tooltip
                              title={i?.title}
                              placement={'bottom-start'}
                              className="cursor-pointer"
                              // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                            >
                              <div className="ellipse-text">{i?.title}</div>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}

            {allProducts?.filter((i) => i?._id !== productID)?.length > 0 && (
              <div>
                <Typography variant="h5" className=" fs-5 mt-4">
                  Other Products
                </Typography>
                <Carousel2
                  autoPlay
                  autoPlaySpeed={4000}
                  showDots
                  containerClass=" other-product"
                  responsive={responsive2}
                  infinite
                >
                  {allProducts
                    ?.filter((i) => i?._id !== productID)
                    ?.map((i, index) => (
                      <div key={index} className="w-100 h-100 mb-3 py-4 product_max_ht">
                        <div
                          className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white cursor-pointer"
                          onClick={openProduct(i?._id)}
                        >
                          <div className="display-3  position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                            {i?.thumbnail ? (
                              <img
                                src={`${S3BucketBaseUrl}${i?.thumbnail}`}
                                alt=""
                                className="aspect-ratio-1 background-white"
                              />
                            ) : (
                              <img
                                src={`${S3BucketBaseUrl}${defaultProductImg}`}
                                alt=""
                                className="aspect-ratio-1 background-white"
                              />
                            )}
                            {/* <span
                              className=" position-absolute d-flex cursor-pointer scale-like"
                              style={{ left: '8px', top: '8px' }}
                              onClick={() => {
                                if (!roleData?.length > 0 || roleData?.includes('owner')) {
                                  navigate(`/login`, {
                                    state: { from: location },
                                  });
                                } else {
                                  callLikeApi({
                                    productRef: i?._id,
                                    showcaseRef: i?.showcaseRef,
                                    companyRef: i?.companyRef,
                                  });
                                }
                              }}
                            >
                              {likedData?.includes(i?._id) ? (
                                <Iconify icon="mdi:cards-heart" width={25} sx={{ color: 'red' }} />
                              ) : (
                                <Iconify
                                  icon="mdi:cards-heart-outline"
                                  width={25}
                                  style={{
                                    mixBlendMode: 'difference',
                                    // color: i?.productResourceRef?.galleryItems[0] ? 'white' : 'black',
                                  }}
                                />
                              )}
                            </span> */}
                          </div>
                          <div className="title__box p-2 bg-light font-body-custom " style={{ height: '40px' }}>
                            <span className={smUp ? 'w-200px' : 'w-100'}>
                              <Tooltip
                                title={i?.title}
                                placement={'bottom-start'}
                                className="cursor-pointer"
                                // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                              >
                                <div className="ellipse-text">{i?.title}</div>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Carousel2>
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default ShowcaseProductSingle;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 120,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    paritialVisibilityGutter: 100,
  },

  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
};

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    paritialVisibilityGutter: 120,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    paritialVisibilityGutter: 100,
  },

  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
};
