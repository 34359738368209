/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Box, Container, styled, Tooltip, Typography } from '@mui/material';
// import Carousel from 'react-multi-carousel';
import { Masonry } from '@mui/lab';
import Paper from '@mui/material/Paper';
import ReactPlayer from 'react-player';

// import Iconify from '../../components/iconify';
import { defaultError, S3BucketBaseUrl } from '../../utils/constants';
import { parseVideo, openInNewTab } from '../../utils/helper';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import CommonModal from '../../components/modal/CommonModal';
import useResponsive from '../../hooks/useResponsive';
import useLocalStorage from '../../hooks/useLocalStorage';

function VisitorDashboard() {
  const navigate = useNavigate();
  const { showcaseUnique } = useParams();
  const lgUp = useResponsive('up', 'lg');
  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');

  const roleData = useLocalStorage('sessionInfo')?.roles;

  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState({
    showcaseData: {},
    resourceData: [],
  });
  const [modalData, setModalData] = useState({
    open: false,
    data: { source: '', type: '' },
  });
  const [videoModalData, setVideoModalData] = useState({
    open: false,
    data: { source: '', type: '' },
  });

  const { showcaseData, onOpenNav, StyledRoot, StyledToolbar, HEADER_DESKTOP, HEADER_MOBILE } = useOutletContext();

  useEffect(() => {
    if (Object.keys(showcaseData).length > 0) setDataShowcase();
  }, [showcaseData]);

  useEffect(() => {
    if (smUp) {
      navigate(`/showcase/${showcaseUnique}`);
    }
  }, [smUp]);

  const setDataShowcase = async () => {
    try {
      setIsLoading(true);
      const allData2 = showcaseData;
      if (!allData2) {
        setIsLoading(false);
        return false;
      }
      if (allData2?.banner?.type === 'video') {
        let videoSource = getIframeEmbed(allData2?.banner?.source);
        const autoplayIndex = videoSource.indexOf('autoplay=');
        videoSource = `${videoSource.substring(0, autoplayIndex + 9)}1${videoSource.substring(
          autoplayIndex + 10
        )}&disablekb=1&controls=0&mute=1&playsinline=1`;
        // modestbranding=1&showinfo=1&controls=1
        // don't forget to change the showcaseProduct too
        setAllData((prev) => ({
          ...prev,
          showcaseData: {
            ...allData2,
            banner: { ...allData2?.banner, source: videoSource },
          },
        }));
      } else {
        setAllData((prev) => ({
          ...prev,
          showcaseData: allData2,
        }));
      }
      if (getResource(allData2?._id)) {
        setIsLoading(false);
      }
      // setIsLoading(false);

      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const getResource = async (showcaseID) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/resource/list-all/${showcaseID}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }

      const docList = res?.data?.packet?.documentItems?.filter((i) => i?.docID);
      const docSorted = docList?.sort((a, b) => a.priority - b.priority);
      const docSpread = docSorted?.map((i) => i?.docID);

      const galleryList = res?.data?.packet?.galleryItems?.filter((i) => i?.galleryID);
      const gallerySorted = galleryList?.sort((a, b) => a.priority - b.priority);
      const gallerySpread = gallerySorted?.map((i) => i?.galleryID);

      setAllData((prev) => ({
        ...prev,
        resourceData: {
          ...prev.resourceData,
          galleryItems: gallerySpread,
          documentItems: docSpread,
        },
      }));

      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  async function callAnalyticsRes(resourceID, type) {
    try {
      // if (!roleData?.length > 0 || roleData?.includes('owner')) {
      //   return false;
      // }
      const sendData = {
        showcaseRef: allData?.showcaseData?._id,
        companyRef: allData?.showcaseData?.companyRef,
        resourceRef: resourceID,
        type,
      };
      const data = await apiMiddleWare({ type: 'post', url: '/click/resource', callType: 'analytics', data: sendData });
      if (!data) {
        return false;
      }
      if (!data?.data?.ok) {
        console.log('ok false', data?.data);
        return false;
      }
      return true;
    } catch (err) {
      console.log('ok false', err);
      // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  }

  function getThumbnailLink(link) {
    const data = parseVideo(link);
    return data?.thumb;
  }

  const openModal = ({ source, type }) => {
    setModalData((prev) => ({
      ...prev,
      open: true,
      data: {
        source,
        type,
      },
    }));
  };

  const onCloseModal = () => {
    setModalData((prev) => ({
      ...prev,
      open: false,
      data: {
        source: '',
        type: '',
      },
    }));
  };

  const openVideoModal = ({ source, type = '' }) => {
    setVideoModalData((prev) => ({
      ...prev,
      open: true,
      data: {
        source,
        type,
      },
    }));
  };

  const onCloseVideoModal = () => {
    setVideoModalData((prev) => ({
      ...prev,
      open: false,
      data: {
        source: '',
        type: '',
      },
    }));
  };

  const getIframeEmbed = (link) => {
    const allData = parseVideo(link);
    return allData?.iframe;
  };

  const documentContent = allData?.resourceData?.documentItems?.map((i, index) => (
    <div key={index} className="col-6 col-md-4 col-lg-3 py-2">
      <div
        className="doc__box"
        onClick={() => {
          openInNewTab(`${S3BucketBaseUrl}${i?.source}`)();
          // callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
        }}
      >
        <div className={`icon__box p-3 fs-6`}>
          {i?.extension === 'pdf' ? (
            // <Iconify icon="ph:file-pdf-light" width="10" />
            <img src="/assets/icons/pdf-icon-sm.png" alt="PDF-ICON" className="doc_icon_size" />
          ) : i?.extension === 'xls' || i?.extension === 'xlsx' ? (
            // <Iconify icon="ph:file-xls-light" width="10" />
            <img src="/assets/icons/xls-icon-sm.png" alt="XLS-ICON" className="doc_icon_size" />
          ) : (
            // 'doc', 'docx'
            // <Iconify icon="ph:file-doc-light" width="10" />
            <img src="/assets/icons/doc-icon-sm.png" alt="DOC-ICON" className="doc_icon_size" />
          )}
        </div>
        <div className={`title__box p-2 ${smUp ? 'w-150px' : 'w-250px'}`}>
          <Tooltip
            title={i?.title}
            placement={'bottom-start'}
            className="cursor-pointer"
            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
          >
            <div className="ellipse-text text-center text-md-start">{i?.title}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  ));

  return isLoading ? (
    <>Loading...</>
  ) : Object.keys(allData?.showcaseData).length !== 0 ? (
    <>
      <CommonModal
        open={modalData?.open}
        onClose={onCloseModal}
        title={`View${modalData?.data?.type === 'image' ? ' Image' : ''}`}
        body={
          <>
            <div className="modal_view__img">
              <img src={modalData?.data?.source || ''} alt="" />
            </div>
          </>
        }
      />

      <CommonModal
        open={videoModalData?.open}
        onClose={onCloseVideoModal}
        title={videoModalData?.data?.type}
        size={'sm'}
        body={
          <div className="modal_view__img ">
            <ReactPlayer url={videoModalData?.data?.source || ''} playing controls loop />
          </div>
        }
      />

      <div
        style={{ marginTop: lgUp ? '0px' : HEADER_MOBILE }}
        // maxWidth="xl" sx={{ px: smUp ? 4 : 2 , pb: 2 }}
      >
        {/* <div className="img__block">
          <div className="banner__img">
            {allData?.showcaseData?.banner?.type === 'image' ? (
              <img src={S3BucketBaseUrl + allData?.showcaseData?.banner?.source} alt="" />
            ) : (
              <iframe
                title="video-banner"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={allData?.showcaseData?.banner?.source || ''}
              />
            )}
          </div>
          <div className="logo__img">
            <img src={S3BucketBaseUrl + allData?.showcaseData?.logo} alt="" />
          </div>
        </div> */}
        <div className="img__block ">
          <div className="banner__img banner__img2">
            {allData?.showcaseData?.banner?.type === 'image' ? (
              <img src={S3BucketBaseUrl + allData?.showcaseData?.banner?.source} alt="" />
            ) : (
              // <iframe
              //   title="video-banner"
              //   allow="autoplay; encrypted-media"
              //   sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation allow-autoplay"
              //   src={allData?.showcaseData?.banner?.source || ''}
              // />
              <ReactPlayer url={allData?.showcaseData?.banner?.source || ''} muted playing loop height={'300px'} />
            )}
          </div>
          <div className="logo__img d-flex">
            <img
              src={S3BucketBaseUrl + allData?.showcaseData?.logo}
              alt=""
              className="aspect-ratio-1 background-white"
            />
          </div>
        </div>

        <Container
          maxWidth="xl"
          className="px-4 px-md-5"
          sx={{
            pb: 2,
          }}
        >
          <div className="showcase__title d-flex justify-content-between ">
            <Typography variant="string" sx={{ my: 2, mb: 1.5 }} className="font-main-title-custom">
              {allData?.showcaseData?.name || ''}
            </Typography>
          </div>

          <HashtagBox>
            {allData?.showcaseData?.hashtags?.length > 0 &&
              allData?.showcaseData?.hashtags?.map((i, index) => (
                <span className="hashtag-box-informa" key={index}>
                  {i}
                </span>
              ))}
          </HashtagBox>

          <div className="px-3 font-body-custom">
            <div dangerouslySetInnerHTML={{ __html: allData?.showcaseData?.description || '' }} />
          </div>

          {allData?.resourceData?.documentItems?.length > 0 && (
            <div className="my-2">
              <Typography variant="h5" className="text-black mt-4 font-title-custom">
                Catalouge
              </Typography>
              <div>
                <div className="row font-body-custom">
                  {smUp ? (
                    allData?.resourceData?.documentItems?.length > 8 ? (
                      <div className="container-see-more ">
                        <input id="ch" type="checkbox" />
                        <label htmlFor="ch" style={{ margin: '0 12px 0 ' }} />
                        <div className="see-more-content row h-200px">{documentContent}</div>
                      </div>
                    ) : (
                      documentContent
                    )
                  ) : allData?.resourceData?.documentItems?.length > 4 ? (
                    <div className="container-see-more ">
                      <input id="ch" type="checkbox" />
                      <label htmlFor="ch" style={{ margin: '0 12px 0 ' }} />
                      <div className="see-more-content row h-415px">{documentContent}</div>
                    </div>
                  ) : (
                    documentContent
                  )}
                </div>
                {/* <Carousel
                  // autoPlay
                  autoPlaySpeed={3000}
                  // renderButtonGroupOutside
                  // renderDotsOutside
                  // partialVisible
                  // arrows={false}

                  showDots
                  containerClass="container-with-dots"
                  responsive={responsive}
                  infinite
                >
                  {allData?.resourceData?.documentItems?.map((i, index) => (
                    <div key={index} className=" me-3 pt-3 pb-4">
                      <div
                        className="doc__box"
                        onClick={() => {
                          openInNewTab(`${S3BucketBaseUrl}${i?.source}`)();
                          callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
                        }}
                      >
                        <div className="icon__box p-3 fs-5">
                          {i?.extension === 'pdf' ? (
                            // <Iconify icon="ph:file-pdf-light" width="10" />
                            <img src="/assets/icons/pdf-icon-sm.png" alt="PDF-ICON" />
                          ) : i?.extension === 'xls' || i?.extension === 'xlsx' ? (
                            // <Iconify icon="ph:file-xls-light" width="10" />
                            <img src="/assets/icons/xls-icon-sm.png" alt="XLS-ICON" />
                          ) : (
                            // 'doc', 'docx'
                            // <Iconify icon="ph:file-doc-light" width="10" />
                            <img src="/assets/icons/doc-icon-sm.png" alt="DOC-ICON" />
                          )}
                        </div>
                        <div className="title__box p-2 w-150px">
                          <Tooltip
                            title={i?.title}
                            placement={'bottom-start'}
                            className="cursor-pointer"
                            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                          >
                            <div className="ellipse-text">{i?.title}</div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel> */}
              </div>
            </div>
          )}

          {allData?.resourceData?.galleryItems?.length > 0 && (
            <div className="my-2">
              <Typography variant="h5" className="text-black mt-4 font-title-custom">
                Gallery
              </Typography>
              <div>
                {/* <Carousel
                autoPlay
                autoPlaySpeed={2000}
                showDots
                // containerClass="container-with-dots"
                responsive={responsive}
                infinite
              >
                {allData?.resourceData?.galleryItems?.map((i, index) => (
                  <div key={index} className="p-1-5">
                    <div
                      className="img_vid__box"
                      onClick={
                        i?.type === 'image'
                          ? () => {
                              openModal({ source: `${S3BucketBaseUrl}${i?.source}`, type: i?.type });
                              callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
                            }
                          : () => {
                              openInNewTab(i?.source)();
                              callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
                            }
                      }
                    >
                      {i?.type === 'image' ? (
                        <img src={`${S3BucketBaseUrl}${i?.source}`} alt="" />
                      ) : (
                        <>
                          <img src={getThumbnailLink(i?.source)} className="video" alt="" />
                          <img src="/assets/play_arrow.png" alt="play" className="playBtn" />
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </Carousel> */}

                <Box sx={{ width: '100%', minHeight: 393 }} className="pt-3 font-body-custom">
                  <Masonry columns={mdUp ? 4 : smUp ? 3 : 2} spacing={2} className="m-0">
                    {allData?.resourceData?.galleryItems.map((i, index) => (
                      <div key={index}>
                        <Label className="font-body-custom">{i?.title}</Label>
                        <div
                          className="img_vid__box2"
                          onClick={
                            i?.type === 'image'
                              ? () => {
                                  openModal({ source: `${S3BucketBaseUrl}${i?.source}`, type: i?.type });
                                  // callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
                                }
                              : () => {
                                  // openInNewTab(i?.source)();
                                  openVideoModal({ source: i?.source, type: i?.title });
                                  // callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
                                }
                          }
                        >
                          {i?.type === 'image' ? (
                            <img src={`${S3BucketBaseUrl}${i?.source}`} className="image" alt="" />
                          ) : (
                            <>
                              <img src={getThumbnailLink(i?.source)} className="video" alt="" />
                              <img src="/assets/play_arrow.png" alt="play" className="playBtn" />
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </Box>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  ) : (
    <>
      Showcase Not Found
      {/* <button
        onClick={() => {
          navigate('/showcase/kavan-test/home');
        }}
      >
        test \
      </button> */}
    </>
  );
}

export default VisitorDashboard;

const HashtagBox = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  // width: 70%;
  margin-bottom: 1.5rem;
  // margin-left: auto;
`;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 10,
  },
};

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  borderRadius: '12px',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: 'white',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: '0.5px solid white',
}));
