import Cookies from 'js-cookie';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { userRouteName, visitorRouteName, adminRouteName } from '../utils/constants';

function useClearUser() {
  const navigate = useNavigate();
  const sesInfo = secureLocalStorage.getItem('sessionInfo');
  secureLocalStorage.clear();
  Cookies.remove('x-auth');
  if (sesInfo.roles[0] === userRouteName) {
    navigate(`/${userRouteName}/login`, { replace: true });
  } else if (sesInfo.roles[0] === visitorRouteName) {
    navigate(`/${visitorRouteName}/login`, { replace: true });
  } else if (sesInfo.roles[0] === adminRouteName) {
    navigate(`/${adminRouteName}/login`, { replace: true });
  }
}

export default useClearUser;
