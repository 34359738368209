import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Iconify from '../components/iconify/Iconify';


const autoCloseTime = 3000;
const theme = 'dark';
customToast.propTypes = {
  type: PropTypes.oneOfType([ 'success', 'error', 'info', 'update']),
  msg : PropTypes.string, 
  customProp : PropTypes.object
};

function customToast({ type, msg, customProp, updateId, updateType }) {
  if (type === 'success') {
    toast.success(msg, {
      autoClose: autoCloseTime,
      closeOnClick: true,
      closeButton: <Iconify icon={'material-symbols:close-rounded'} sx={{ color: 'green' }} />,
      theme,
      ...customProp,
    });
  } else if (type === 'error') {
    toast.dismiss();
    toast.error(msg, { autoClose: autoCloseTime, theme, ...customProp });
  } else if (type === 'info') {
    toast.info(msg, { autoClose: autoCloseTime, theme, ...customProp });
  } else if (type === 'update') {
    toast.update(updateId, {
      render: msg,
      type: updateType,
      isLoading: false,
      autoClose: autoCloseTime,
      closeOnClick: true,
      closeButton: <Iconify icon={'material-symbols:close-rounded'} sx={{ color: 'white' }} />,
      theme,
      ...customProp,
    });
  }
}

export default customToast;
