import PropTypes from 'prop-types';
import React from 'react';
import { InputLabel, Stack, Typography } from '@mui/material';

export default function CustomInputLabel({ label, helperText, labelProps }) {
    return (
        <Stack direction={'column'} justifyContent={"start"}>
            <InputLabel className='text-start' {...labelProps}>{label}</InputLabel>
            <Typography className='text-start' variant="caption" sx={{ color: 'grey.600' }}>
                {helperText}
            </Typography>
        </Stack>
    );
};
CustomInputLabel.propTypes = {
    label: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    labelProps: PropTypes.object,
};
