/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography, Grid, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import customToast from '../../../utils/customToast';
import Iconify from '../../../components/iconify';
import apiMiddleWare from '../../../utils/useApiMiddleWare';

import { defaultError, userRouteName, visitorRouteName, adminRouteName, showcaseRouteName } from '../../../utils/constants';
import useLocalStorage from '../../../hooks/useLocalStorage';
import CommonModal from '../../../components/modal/CommonModal';
import { openInNewTab } from '../../../utils/helper';

// ----------------------------------------------------------------------

const navigateData = {
    [userRouteName]: { loginLink: `/${userRouteName}/login`, registerLink: `/${userRouteName}/register` },
    [visitorRouteName]: { loginLink: `/login`, registerLink: `/register` },
    [adminRouteName]: { loginLink: `/${adminRouteName}/login`, registerLink: `/${adminRouteName}/register` },
};

LoginForm.propTypes = {
    type: PropTypes.string,
};

// * login type
const LOGIN_TYPE = {
    PASSWORD: 'password',
    OTP: 'otp',
};

export default function LoginForm({ type }) {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const allQueryObj = Object.fromEntries(queryParams.entries());
    const allQueryString =
        Object.keys(allQueryObj).length === 0
            ? ''
            : `?${Object.entries(allQueryObj)
                  ?.map((i) => `${i[0]}=${i[1]}`)
                  ?.join('&')}`;

    const redirectTo = location?.state?.from?.pathname || location?.state?.pathname || null;
    const prevToken = useLocalStorage('sessionInfo')?.xAuth;
    const refreshToken = useLocalStorage('refreshToken');

    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({
        email: { value: '' },
        password: { value: '' },
        newPass: { value: '', value2: '' },
        forgetPass: 0,
        otp: { value: '' },
    });
    const [userData2, setUserData2] = useState({
        email: '',
        otp: '',
        otpTrue: false,
        token: prevToken || '',
        rememberMe: true,
        otpModal: false,
    });

    // * for login with otp
    const [loginType, setLoginType] = useState(LOGIN_TYPE.PASSWORD); // ['password', 'otp']
    const emailRef = useRef(); // for login email input
    const otpRef = useRef(); // for otp input
    const [isLoginOTPLoading, setIsLoginOTPLoading] = useState(false);
    const [otpSendStatus, setOtpSendStatus] = useState(false); // false - send otp & true - login with otp

    useEffect(() => {
        if (prevToken && location.pathname.includes('/user/')) {
            checkLoggedInUser();
        }
        // else if (prevToken ){
        //   checkLoggedIn()
        // }
    }, []);

    // * OTP login handler
    const handleLoginWithOTP = async (e) => {
        try {
            e.preventDefault();
            setIsLoginOTPLoading(true);
            const id = toast.loading('Please wait...');

            // * send otp
            if (!otpSendStatus) {
                const res = await apiMiddleWare({
                    url: '/users/send-login-otp',
                    data: {
                        email: emailRef.current.value,
                    },
                    type: 'post',
                });
                if (res?.data?.ok === true) {
                    setOtpSendStatus(true);
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: res?.data?.msg,
                        updateType: 'success',
                    });
                    return true;
                }
            }
            // * verify otp
            const res = await apiMiddleWare({
                url: '/users/login',
                data: {
                    email: emailRef.current.value,
                    otp: otpRef.current.value,
                },
                type: 'post',
            });
            if (res?.data?.ok === true) {
                setOtpSendStatus(true);
                const { status, url, token } = res?.data?.isRepOutside;
                localStorage.setItem('isRepOutside', JSON.stringify({ status: Boolean(status), url, token }));

                if (!!res?.data?.isRepOnly?.status) {
                    window.open(`${url}?token=${token}`, '_blank');
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: 'Login Successful for Representative',
                        updateType: 'success',
                    });
                    return true;
                }

                customToast({ updateId: id, type: 'update', msg: 'Login Successful', updateType: 'success' });
                secureLocalStorage.setItem('sessionInfo', {
                    roles: [`${res?.data?.role}`],
                    json: { ...res?.data?.userData, showcaseAccepted: res?.data?.showcaseAccepted },
                    xAuth: res?.data?.token,
                });
                navigateDashboard({
                    userData: res?.data?.userData,
                    firstTimeLogin: res?.data?.firstTimeLogin,
                });
            }

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setIsLoginOTPLoading(false);
        }
    };

    const checkLoggedInUser = async () => {
        try {
            const res = await apiMiddleWare({ url: '/users/check', type: 'get' });
            if (res?.data?.ok) {
                navigate(redirectTo || `/${type}/showcase/profile`);
            }
        } catch (err) {
            console.error(err);
        }
    };

    // user (owner dashboard)
    const navigateDashboard = (dataObj) => {
        navigate(redirectTo || `/${type}/showcase/profile`, { replace: true, state: { ...dataObj } });
    };

    // user/login
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const id = toast.loading('Please wait...');
        try {
            const { email, password, role: type } = userData;
            const sendData = { email: email?.value, password: password?.value };
            const res = await apiMiddleWare({ url: '/users/login', data: sendData, type: 'post' });
            if (res?.data?.ok === true) {
                const { status, url, token } = res?.data?.isRepOutside;
                localStorage.setItem('isRepOutside', JSON.stringify({ status: Boolean(status), url, token }));

                if (!!res?.data?.isRepOnly?.status) {
                    // openInNewTab(res?.data?.isRepOnly?.redirectURL)();
                    window.open(`${url}?token=${token}`, '_blank');
                    customToast({
                        updateId: id,
                        type: 'update',
                        msg: 'Login Successful for Representative',
                        updateType: 'success',
                    });
                    return true;
                }

                customToast({ updateId: id, type: 'update', msg: 'Login Successful', updateType: 'success' });
                // Cookies.set('x-auth', res?.data?.token);
                secureLocalStorage.setItem('sessionInfo', {
                    roles: [`${res?.data?.role}`],
                    json: { ...res?.data?.userData, showcaseAccepted: res?.data?.showcaseAccepted },
                    xAuth: res?.data?.token,
                });
                navigateDashboard({ userData: res?.data?.userData, firstTimeLogin: res?.data?.firstTimeLogin });
            } else {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
                    updateType: 'error',
                });
            }

            return true;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            resetForm1();
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    // user/login
    const resetForm1 = () => {
        setUserData((prev) => ({
            ...prev,
            email: { value: '' },
            password: { value: '' },
            forgetPass: 0,
            newPass: { value: '', value2: '' },
            otp: { value: '' },
        }));
        setShowPassword(false);
        setLoginType(LOGIN_TYPE.PASSWORD);
        setOtpSendStatus(false);
        setIsLoginOTPLoading(false);
    };

    // user/login
    const handleForgotPass = () => {
        setUserData((prev) => ({
            ...prev,
            forgetPass: 1,
        }));
    };

    const handleSendOTPForget = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const id = toast.loading('Please wait...');
        try {
            const { email } = userData;
            const sendData = { email: email?.value };
            const res = await apiMiddleWare({ url: '/users/send-otp-forget-pass', data: sendData, type: 'post' });
            if (res?.data?.ok === true) {
                setUserData((prev) => ({
                    ...prev,
                    forgetPass: 2,
                }));
                customToast({ updateId: id, type: 'update', msg: 'OTP Sent', updateType: 'success' });
            } else {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
                    updateType: 'error',
                });
            }
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            resetForm1();
            setIsLoading(false);
            return false;
        }
    };

    const handleVerifyOTPForget = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const id = toast.loading('Please wait...');
        try {
            const { email, newPass, otp } = userData;
            if (newPass?.value !== newPass?.value2) {
                customToast({ updateId: id, type: 'update', msg: 'Both Passwords are not same', updateType: 'error' });
                setIsLoading(false);
                return false;
            }
            const sendData = { email: email?.value, otp: otp?.value, newPass: newPass?.value };
            const res = await apiMiddleWare({ url: '/users/verify-otp-forget-pass', data: sendData, type: 'post' });
            if (res?.data?.ok === true) {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: 'Password reset done successfully',
                    updateType: 'success',
                });
                resetForm1();
            } else {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
                    updateType: 'error',
                });
            }
            setIsLoading(false);
            return true;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            resetForm1();
            setIsLoading(false);
            return false;
        }
    };

    // user/login
    const handleChange1 = (e) => {
        const { value, name } = e.target;
        setUserData((prev) => ({
            ...prev,
            [name]: {
                ...prev[name],
                value,
            },
        }));
    };

    // handle change visitor
    const handleChange2 = (e) => {
        const { value, name } = e.target;
        let newValue = value;
        if (name === 'otp') {
            newValue = newValue.replace(/[ a-zA-Z]/, '');
        } else if (name === 'rememberMe') {
            // console.log(e.target.checked);
            newValue = e.target.checked;
        }
        setUserData2((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };
    // /login (visitor)
    const resetForm2 = () => {
        setUserData2((prev) => ({
            ...prev,
            email: '',
            otp: '',
            otpTrue: false,
            prevToken: null,
        }));
        setShowPassword(false);
    };

    // submit for /login
    const handleSubmit2 = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const toastID = toast.loading('Please wait...');
        try {
            const { email, otp, token } = userData2;
            const sendData = { email, otp, token: token || refreshToken };
            const res = await apiMiddleWare({ url: '/visitors/login', data: sendData, type: 'post' });
            if (res?.data?.ok === true) {
                if (res?.data?.otpRequired === true) {
                    setUserData2((prev) => ({
                        ...prev,
                        otpModal: true,
                    }));
                    customToast({
                        updateId: toastID,
                        type: 'update',
                        msg: 'Logout From Other Device?',
                        updateType: 'info',
                    });
                    setIsLoading(false);
                    return true;
                }
                if (res?.data?.otpSent === true) {
                    setUserData2((prev) => ({
                        ...prev,
                        otpTrue: true,
                    }));
                    customToast({ updateId: toastID, type: 'update', msg: 'OTP Sent', updateType: 'success' });
                    setIsLoading(false);
                    return true;
                }
                customToast({ updateId: toastID, type: 'update', msg: 'Login Successful', updateType: 'success' });
                // Cookies.set('x-auth', res?.data?.token);
                secureLocalStorage.setItem('sessionInfo', {
                    roles: [`${res?.data?.role || 'visitor'}`],
                    json: res?.data?.packet,
                    xAuth: res?.data?.token,
                });
                if (userData2?.rememberMe === true) {
                    secureLocalStorage.setItem('refreshToken', res?.data?.token);
                } else {
                    secureLocalStorage.removeItem('refreshToken');
                }
                window.dispatchEvent(new Event('storage'));
                navigate(redirectTo || '/profile');
            } else {
                if (res === 'Email is not registered!') {
                    // Swal.fire({
                    //   title: 'You Want To Register?',
                    //   showCancelButton: true,
                    //   confirmButtonText: 'Yes',
                    //   customClass: { container: 'zIndex-10000 ' },
                    // }).then((result) => {
                    //   if (result.isConfirmed) {
                    navigate(`/register${allQueryString}`, {
                        replace: true,
                        state: {
                            email: userData2.email,
                            pathname: location.state?.from?.pathname || location.state?.pathname,
                        },
                    });
                    //   } else {
                    //     // Swal.fire('Changes are not saved', '', 'info');
                    //   }
                    // });
                }
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
                    updateType: 'error',
                });
            }
            setIsLoading(false);
            return true;
        } catch (err) {
            console.log(err?.message);
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            resetForm1();
            setIsLoading(false);
            return false;
        }
    };

    const sendOtp2 = async () => {
        setIsLoading(true);
        const toastID = toast.loading('Please wait...');
        try {
            const { email, otp, token } = userData2;
            const sendData = { email, sendOtp: true };
            const res = await apiMiddleWare({ url: '/visitors/login', data: sendData, type: 'post' });
            if (res?.data?.ok === true) {
                // customToast({ updateId: toastID, type: 'update', msg: 'OTP Sent', updateType: 'success' });
                // setUserData2((prev) => ({ ...prev, otpModal: false }));
                if (res?.data?.otpSent === true) {
                    setUserData2((prev) => ({
                        ...prev,
                        otpTrue: true,
                        otpModal: false,
                    }));
                    customToast({ updateId: toastID, type: 'update', msg: 'OTP Sent', updateType: 'success' });
                }
            } else {
                customToast({
                    updateId: toastID,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
                    updateType: 'error',
                });
            }
            setIsLoading(false);
        } catch (err) {
            console.log(err?.message);
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Join Informa Markets Renewable Energy | Seller Dashboard | Reiconnect</title>
                <meta
                    name="description"
                    content="Register for Reiconnect's Seller Dashboard at Informa Markets. Gain access to tools and networks that can help you increase your reach in the renewable sector!"
                />
            </Helmet>
            <CommonModal
                open={userData2?.otpModal}
                size={'sm'}
                body={
                    <>
                        <div>You are currently Logged in, in other device</div>
                        {/* <div> Do you want to login in this device and signout your account from other device ? </div> */}
                    </>
                }
                title={<>Logout from other device</>}
                customFooter={
                    <>
                        <Button sx={{ mr: 2 }} type="button" variant="contained" color="primary" onClick={sendOtp2}>
                            Send OTP
                        </Button>

                        <Button
                            type="button"
                            variant="outlined"
                            color="error"
                            onClick={() => setUserData2((prev) => ({ ...prev, otpModal: false }))}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    </>
                }
            />
            <Typography variant="body2" sx={{ mb: 3 }}>
                Don’t have an account? {''}
                <Link to={navigateData[type].registerLink + allQueryString} state={location?.state?.from || location?.state}>
                    Register
                </Link>
            </Typography>
            {type === userRouteName &&
                (loginType === LOGIN_TYPE.PASSWORD ? (
                    <form
                        action=""
                        onSubmit={
                            userData?.forgetPass === 0 ? handleSubmit1 : userData?.forgetPass === 1 ? handleSendOTPForget : handleVerifyOTPForget
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item sm={12} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email address"
                                    value={userData.email.value}
                                    onChange={handleChange1}
                                    required
                                />
                            </Grid>
                            {userData?.forgetPass === 0 && (
                                <Grid item sm={12} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={userData.password.value}
                                        onChange={handleChange1}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            )}
                            {userData?.forgetPass === 2 && (
                                <Grid item sm={12} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="otp"
                                        label="Enter OTP sent to Email"
                                        value={userData?.otp?.value}
                                        onChange={handleChange1}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                                        required
                                    />
                                </Grid>
                            )}
                            {userData?.forgetPass === 2 && (
                                <Grid item sm={12} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="newPass"
                                        label="New Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={userData.newPass.value}
                                        onChange={handleChange1}
                                        required
                                        // InputProps={{
                                        //   endAdornment: (
                                        //     <InputAdornment position="end">
                                        //       <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        //         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        //       </IconButton>
                                        //     </InputAdornment>
                                        //   ),
                                        // }}
                                    />
                                </Grid>
                            )}{' '}
                            {userData?.forgetPass === 2 && (
                                <Grid item sm={12} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="newPass"
                                        label="Confirm Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={userData.newPass.value2}
                                        onChange={(e) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                newPass: { ...prev?.newPass, value2: e.target.value },
                                            }))
                                        }
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {/* </Stack> */}
                        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
                            {/* <Checkbox name="remember" label="Remember me" /> */}
                            <Button
                                type="button"
                                className=" px-2 text-decoration-auto cursor-pointer me-2"
                                onClick={() => setLoginType(LOGIN_TYPE.OTP)}
                            >
                                Login with OTP
                            </Button>
                            <Button type="button" className=" px-2 text-decoration-auto cursor-pointer me-2" onClick={handleForgotPass}>
                                Forgot Password
                            </Button>
                            <Button type="button" className="px-2 text-decoration-auto cursor-pointer" onClick={resetForm1}>
                                Clear Form
                            </Button>
                        </Stack>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            className="green-btn border-radius-15px"
                            disabled={isLoading}
                            variant="contained"
                        >
                            {userData?.forgetPass === 0
                                ? 'Login'
                                : userData?.forgetPass === 1
                                ? 'Send OTP'
                                : userData?.forgetPass === 2
                                ? 'verify OTP'
                                : ''}
                        </LoadingButton>
                    </form>
                ) : (
                    <form action="" onSubmit={handleLoginWithOTP}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    placeholder="Enter your email"
                                    InputLabelProps={{ shrink: true }}
                                    label="Email address"
                                    inputRef={emailRef}
                                    required
                                />
                            </Grid>
                            {otpSendStatus && (
                                <Grid item sm={12} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="otp"
                                        placeholder="Enter the OTP sent to Email"
                                        InputLabelProps={{ shrink: true }}
                                        label="OTP"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                                        required
                                        inputRef={otpRef}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
                            <Button
                                type="button"
                                className=" px-2 text-decoration-auto cursor-pointer me-2"
                                onClick={() => {
                                    setLoginType(LOGIN_TYPE.PASSWORD);
                                    resetForm1();
                                }}
                            >
                                Login with Password
                            </Button>
                            <Button
                                type="button"
                                className=" px-2 text-decoration-auto cursor-pointer me-2"
                                onClick={() => {
                                    setLoginType(LOGIN_TYPE.PASSWORD);
                                    handleForgotPass();
                                }}
                            >
                                Forgot Password
                            </Button>
                            <Button type="button" className="px-2 text-decoration-auto cursor-pointer" onClick={resetForm1}>
                                Clear Form
                            </Button>
                        </Stack>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            className="green-btn border-radius-15px"
                            disabled={isLoginOTPLoading}
                            variant="contained"
                        >
                            {otpSendStatus ? 'Verify OTP' : 'Send OTP'}
                        </LoadingButton>
                    </form>
                ))}
            {type === visitorRouteName && (
                <>
                    <form action="" onSubmit={handleSubmit2}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} flexGrow={1}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email address"
                                    value={userData2?.email}
                                    onChange={handleChange2}
                                    disabled={userData2?.otpTrue}
                                    required
                                />
                            </Grid>
                            {userData2?.otpTrue && (
                                <Grid item sm={12} flexGrow={1}>
                                    <TextField
                                        fullWidth
                                        name="otp"
                                        label="Enter OTP sent to Email"
                                        value={userData2?.otp}
                                        onChange={handleChange2}
                                        disabled={!userData2?.otpTrue}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                                        required
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {/* </Stack> */}
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                            {/* <Checkbox name="remember" label="Remember me" /> */}
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox name="rememberMe" onChange={handleChange2} checked={userData2?.rememberMe} />}
                                    label="Remember Me"
                                />
                            </FormGroup>
                            <Button type="button" onClick={resetForm2}>
                                Reset Form
                            </Button>
                        </Stack>
                        <LoadingButton fullWidth size="large" type="submit" disabled={isLoading} variant="contained">
                            Login
                        </LoadingButton>
                    </form>
                </>
            )}
            {type === adminRouteName && <></>}
        </>
    );
}
