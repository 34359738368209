import { CHANGE_USER } from './user.types';

const INITIAL_STATE = {
  name: 'kavan',
  mobile: 1234512345,
  email: 'kavan@gmai.com',
};
const reducer = (state = INITIAL_STATE, action) => {
  // console.log('reducer page ====', action, state);
  switch (action.type) {
    case CHANGE_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default reducer;
