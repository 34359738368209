/* eslint-disable react/jsx-no-bind */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box, Button, Container, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Progresscontext } from '../../../components/circular-progress/ProgressContext';
import ImageUpload from '../../../components/image-upload/ImageUpload';
import Loader from '../../../components/loader';
import CustomInputLabel from '../../../components/label/CustomInputLabel';
import MergeImage from '../../../components/merge-image/MergeImage';
import WarningModal from '../../../components/modal/WarningModal';

import useResponsive from '../../../hooks/useResponsive';

import { S3BucketBaseUrl, SHOWCASE_HEADER_NOTE, ckEditorConfig, defaultError, informaGreen, userRouteName } from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import { canvasToImg, getExtension, parseVideo, uploadImageToS3 } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';

function UserProfile() {
    const navigate = useNavigate();
    const smUp = useResponsive('up', 'sm');
    const isLg = useResponsive('up', 'lg');

    const { setCallGuideStatus } = useContext(Progresscontext);

    const { isOpen, setIsOpen, setCurrentStep } = useTour();

    const location = useLocation();

    const timeout = useRef();
    const refreshClickRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [callApi, setCallApi] = useState(false);

    // const [identifier, setIdentifier] = useState({
    //   value: '',
    //   error: false,
    // });
    const customCanvasRef = useRef(null);

    const [profileData, setProfileData] = useState({
        showcaseName: '',
        showcaseDesc: '',
        showcaseHashtag: { text: '', split: [] },
        industry: { value: '', list: industryOptions },
        showcaseLogo: {
            value: '',
            name: '',
        },
        showcaseBanner: {
            value: '',
            name: '',
            type: 'image', // ["video", "image"]
            extension: 'png', // ["jpg", "jpeg", "png", "youtube", "vimeo"]

            selectType: '', // [ 'custom', 'select' ]
            templateSelected: '', // value of the image source
            templateX: 0,
            templateY: 0,
            templateData: [],
            refreshPreview: false,
        },
        showcaseId: '',
    });

    // * waring modal state
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [missingFields, setMissingFields] = useState([]);

    // * warning modal functions
    // * close handler
    const handleClose = () => {
        setShowWarningModal(false);
    };
    const handleProceed = () => {
        navigate(`/${userRouteName}/showcase/resource`);
    };

    // useEffect
    useEffect(() => {
        if (!!location?.state?.firstTimeLogin) {
            openWelcomeModal({ firstName: location?.state?.userData?.firstName || 'User' });
        }
    }, []);

    useEffect(() => {
        getAllShowcase();
    }, [callApi]);

    // useEffect(() => {
    //   identifierDebounce();
    // }, [identifier?.value]);

    // functions
    async function getAllShowcase() {
        try {
            setIsLoading(true);
            const data = await apiMiddleWare({ type: 'get', url: '/company/profile' });
            if (!data) {
                setIsLoading(false);
                return false;
            }
            if (data?.data?.ok) {
                const singleData = data?.data?.packet;
                // secureLocalStorage.setItem('showcaseID', singleData._id);
                // window.dispatchEvent(new Event('storage'));

                // setModeType('edit');
                // setIdentifier((prev) => ({
                //   ...prev,
                //   value: singleData?.identifier,
                // }));
                setProfileData((prev) => ({
                    ...prev,
                    showcaseName: singleData?.name,
                    showcaseDesc: singleData?.description || '',
                    showcaseHashtag: { text: singleData?.hashtags?.join(','), split: singleData?.hashtags || [] },
                    showcaseId: singleData?._id,
                    industry: {
                        ...prev.industry,
                        value: singleData?.industry,
                    },
                    showcaseLogo: {
                        ...prev.showcaseLogo,
                        name: singleData?.logo || '',
                        value: singleData?.logo ? S3BucketBaseUrl + singleData?.logo : '',
                    },
                    showcaseBanner: {
                        ...prev.showcaseBanner,
                        name: singleData?.banner?.type === 'image' ? singleData?.banner?.source || '' : '',
                        value:
                            singleData?.banner?.type === 'image'
                                ? singleData?.banner?.source
                                    ? S3BucketBaseUrl + singleData?.banner?.source
                                    : ''
                                : singleData?.banner?.source || '',
                        type: singleData?.banner?.type || 'image',
                        extension: singleData?.banner?.extension || 'png',

                        selectType: singleData?.banner?.type ? 'custom' : '',
                        templateSelected: '',
                        templateX: 0,
                        templateY: 0,
                        templateData: [],
                    },
                }));
                setIsLoading(false);
                return true;
            }
            customToast({
                msg: data?.response?.data?.errors[0]?.message || data?.data?.errors[0]?.msg || defaultError,
                type: 'error',
            });
            setIsLoading(false);
            return false;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            setIsLoading(false);
            return false;
        }
    }

    async function getBannerTemplate() {
        try {
            const data = await apiMiddleWare({ type: 'get', url: '/company/banner-template' });
            if (!data) {
                return false;
            }
            if (data?.data?.ok) {
                const singleData = data?.data?.packet;
                setProfileData((prev) => ({
                    ...prev,
                    showcaseBanner: {
                        ...prev.showcaseBanner,
                        templateData: singleData,
                    },
                }));
                return true;
            }
            customToast({
                msg: data?.response?.data?.errors[0]?.message || data?.data?.errors[0]?.msg || defaultError,
                type: 'error',
            });
            return false;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    }

    const getSetBase64 = async () => {
        try {
            const base64img = await canvasToImg(customCanvasRef);
            getBannerHere(base64img);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    function getLogoHere(data) {
        if (!!data) {
            setProfileData((prev) => ({
                ...prev,
                showcaseLogo: {
                    ...prev.showcaseLogo,
                    value: data,
                },
            }));
        }
    }

    function getBannerHere(data) {
        if (!!data) {
            setProfileData((prev) => ({
                ...prev,
                showcaseBanner: {
                    ...prev.showcaseBanner,
                    value: data,
                },
            }));
        }
    }

    function clearImg(type) {
        if (type === 'logo') {
            setProfileData((prev) => ({
                ...prev,
                showcaseLogo: {
                    ...prev.showcaseLogo,
                    value: '',
                },
            }));
        } else if (type === 'banner') {
            setProfileData((prev) => ({
                ...prev,
                showcaseBanner: {
                    ...prev.showcaseBanner,
                    value: '',
                },
            }));
        }
    }

    function handleOnChange(e) {
        const { name, value } = e.target;
        if (name === 'showcaseHashtag') {
            setProfileData((prev) => ({
                ...prev,
                showcaseHashtag: {
                    ...prev.showcaseHashtag,
                    text: value,
                },
            }));
        } else if (name === 'industry') {
            setProfileData((prev) => ({
                ...prev,
                industry: {
                    ...prev.industry,
                    value,
                },
            }));
        } else {
            setProfileData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }

    async function submitForm(e) {
        try {
            e.preventDefault();
            await validateForm();
            
            const missingFields = [];
            if (profileData?.showcaseName === '') missingFields.push('Company Name');
            if (profileData?.industry?.value === '') missingFields.push('Industry');
            if (profileData?.showcaseDesc === '') missingFields.push('Company Overview');
            if (profileData?.showcaseLogo?.value === '') missingFields.push('Company Logo');
            if (profileData?.showcaseBanner?.value === '') missingFields.push('Company Banner');
            if (missingFields.length > 0) {
                setMissingFields(missingFields);
                setShowWarningModal(true);
                return false;
            }

            const id = toast.loading('Please wait...');
            const sendData = {
                name: profileData?.showcaseName,
                description: profileData?.showcaseDesc,
                hashtags: profileData?.showcaseHashtag?.text?.split(',')?.filter((i) => i) || [],
                industry: profileData?.industry?.value,
                // logo: profileData?.showcaseLogo?.value,
                // banner: profileData?.showcaseBanner?.value,
                banner: {
                    type: profileData?.showcaseBanner?.type,
                    extension: profileData?.showcaseBanner?.extension,
                },
            };

            if (profileData?.showcaseLogo?.value.indexOf(S3BucketBaseUrl) === -1) {
                const { logo } = await uploadImageToS3({
                    sendData: {
                        logo: profileData?.showcaseLogo?.name?.split('/')?.pop() || '',
                        logoType: getExtension(profileData?.showcaseLogo?.value),
                    },
                    value: profileData?.showcaseLogo?.value,
                });
                sendData.logo = logo;
            }

            if (profileData?.showcaseBanner?.type === 'image' && profileData?.showcaseBanner?.value.indexOf(S3BucketBaseUrl) === -1) {
                const { banner } = await uploadImageToS3({
                    sendData: {
                        banner: profileData?.showcaseBanner?.name?.split('/')?.pop() || '',
                        bannerType: getExtension(profileData?.showcaseBanner?.value),
                    },
                    value: profileData?.showcaseBanner?.value,
                });
                sendData.banner.source = banner;
            } else if (profileData?.showcaseBanner?.type === 'image') {
                sendData.banner.source = profileData?.showcaseBanner?.name;
            } else {
                sendData.banner.source = profileData?.showcaseBanner?.value;
            }

            const res = await apiMiddleWare({ url: '/company/profile', type: 'patch', data: sendData });
            if (res?.data?.ok) {
                // setProfileData((prev) => ({
                //   ...prev,
                //   showcaseId: res?.data?.packet?.showcaseID,
                // }));

                sendData._id = res?.data?.packet?.showcaseID;
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: 'Company Details successfully updated',
                    updateType: 'success',
                });

                setCallGuideStatus((prev) => !prev);
            } else {
                customToast({
                    updateId: id,
                    type: 'update',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                    updateType: 'error',
                });
                return false;
            }

            const { name: nextType } = e.nativeEvent.submitter;

            if (nextType === 'next') {
                navigate(`/${userRouteName}/showcase/resource`);
            } else if (nextType === 'share') {
                navigate(`/${userRouteName}/showcase/share`);
            }

            // setTimeout(() => {
            //   setCallApi((prev) => !prev);
            // }, [2000]);

            return true;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    }

    function validateForm() {
        return new Promise((resolve, reject) => {
            // if (identifier?.error) {
            //   reject(new Error(`Showcase Identifier not valid`));
            //   return false;
            // }
            // if (identifier?.value === '') {
            //   reject(new Error(`Showcase Identifier is requried`));
            //   return false;
            // }

            if (profileData?.showcaseName === '') {
                reject(new Error(`Company Name is required`));
                return false;
            }
            // if (profileData?.industry?.value === '') {
            //   reject(new Error(`Industry is required`));
            //   return false;
            // }
            if (profileData?.showcaseDesc === '') {
                reject(new Error(`Company Overview is required`));
                return false;
            }
            // if(profileData?.showcaseHashtag === ''){
            //   customToast({ type: 'error', msg: `Showcase Hashtag is required` });
            // reject(new Error(`Showcase Description is required`));
            // return false;
            // }
            if (profileData?.showcaseLogo?.value === '') {
                reject(new Error(`Company Logo is required`));
                return false;
            }
            if (profileData?.showcaseBanner?.value === '') {
                reject(new Error(`Company Banner is required`));
                return false;
            }
            resolve();
            return true;
        });
    }

    function videoOnBlur() {
        const currentLink = profileData?.showcaseBanner?.value;
        if (currentLink) {
            const allData = parseVideo(currentLink);
            if (allData?.provider !== 'youtube' && allData?.provider !== 'vimeo') {
                customToast({ type: 'error', msg: `URL is not valid` });

                setProfileData((prev) => ({
                    ...prev,
                    showcaseBanner: { ...prev?.showcaseBanner, value: '' },
                }));
            } else {
                setProfileData((prev) => ({
                    ...prev,
                    showcaseBanner: { ...prev?.showcaseBanner, value: allData?.rawLink, extension: allData?.provider },
                }));
            }
        }
    }

    const handleBannerSelectType = (e) => {
        const { value } = e.target;
        setProfileData((prev) => ({
            ...prev,
            showcaseBanner: {
                ...prev.showcaseBanner,
                selectType: value,
                type: 'image',
                extension: 'png',
                value: '',
            },
        }));
        if (value === 'select' && profileData?.showcaseBanner?.templateData?.length === 0) getBannerTemplate();
    };

    const refreshPreview = async () => {
        try {
            if (!profileData?.showcaseLogo?.value) {
                customToast({ type: 'error', msg: 'Upload Company Logo to check preview' });
                return false;
            }
            if (!profileData?.showcaseBanner?.templateSelected) {
                customToast({ type: 'error', msg: 'Select Template to check preview' });
                return false;
            }

            setProfileData((prev) => ({
                ...prev,
                showcaseBanner: {
                    ...prev.showcaseBanner,
                    refreshPreview: !prev.showcaseBanner.refreshPreview,
                },
            }));

            await getSetBase64();
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const openWelcomeModal = ({ firstName }) => {
        Swal.fire({
            title: `<span style="color:${informaGreen}">Congratulation ${firstName?.charAt(0).toUpperCase()}${firstName
                ?.substring(1)
                .toLowerCase()}!</span> Your basic sellers registration is complete`,
            // text: 'Amplify your reach and connect with potential customers like never before.',
            html: smUp
                ? `
                <div class='row mx-3 justify-content-center'>
                    <div class="col-md-4 mb-3"><img src="/assets/images/welcomeModal/visibility.png" class='w-100 fit-cover' alt="" /></div>
                    <div class="col-md-4 mb-3"><img src="/assets/images/welcomeModal/enquire.png" class='w-100 fit-cover' alt="" /></div>
                    <div class="col-md-4 mb-3"><img src="/assets/images/welcomeModal/generate.png" class='w-100 fit-cover' alt="" /></div>
                    <div class="col-md-4 mb-3"><img src="/assets/images/welcomeModal/analytics.png" class='w-100 fit-cover' alt="" /></div>
                    <div class="col-md-4 mb-3"><img src="/assets/images/welcomeModal/lead.png" class='w-100 fit-cover' alt="" /></div>
                </div>`
                : `<div>Amplify your reach and connect with potential customers like never before.</div>`,
            width: smUp ? 1000 : 300,
            // icon: 'success',
            confirmButtonText: 'Get Started',
            confirmButtonColor: informaGreen,
            background: '#fff url(/assets/backgroundImg/welcome_bg.png) ',
            customClass: { container: 'zIndex-10000 welcome-bg-cls' },
        }).then((result) => {
            if (result.isConfirmed) {
                openGuide();
            }
        });
    };

    const openGuide = () => {
        setCurrentStep(0);
        setIsOpen(true);
    };

    return (
        <>
            {/* warning modal */}
            <WarningModal
                sectionName="Product Portfolio"
                missingFields={missingFields}
                open={showWarningModal}
                handleClose={handleClose}
                handleProceed={handleProceed}
            />
            <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="h-100 w-100">
                {/* <Typography variant="h3" sx={{ mb: 5 }}>
                    Showcase Profile
                    </Typography> */}
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box>
                        {/* note */}
                        <Typography variant="h6" className="mb-3">
                            Note: {SHOWCASE_HEADER_NOTE.profile}
                        </Typography>
                        <form onSubmit={submitForm}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} className="side-by-side">
                                    <Grid item xs={12} md={3}>
                                        <CustomInputLabel label="Company Name" labelProps={{ required: true }} helperText="Your Business Name" />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            sx={{ width: '50%' }}
                                            className="fullWidth-responsive"
                                            // fullWidth
                                            name="showcaseName"
                                            value={profileData?.showcaseName}
                                            onChange={handleOnChange}
                                            inputProps={{ maxLength: 50 }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="side-by-side">
                                    <Grid item xs={12} md={3}>
                                        <CustomInputLabel label="Industry" labelProps={{ required: true }} helperText="What you specialize in" />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            // fullWidth
                                            sx={{ width: '50%', textAlign: 'left', backgroundColor: 'white' }}
                                            className="fullWidth-responsive"
                                            name="industry"
                                            id="outlined-select-currency"
                                            select
                                            // label="Search Time"
                                            value={profileData?.industry?.value}
                                            onChange={handleOnChange}
                                            // required
                                        >
                                            <MenuItem value={''}>--Select--</MenuItem>
                                            {profileData?.industry?.list?.map((i) => (
                                                <MenuItem value={i} key={i}>
                                                    {i.charAt(0).toUpperCase() + i.substring(1)}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} className="side-by-side">
                                <Grid item xs={12} md={3}>
                                <InputLabel>Banner Type</InputLabel>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                <TextField
                                    // fullWidth
                                    sx={{ width: '50%', textAlign: 'left', backgroundColor: 'white' }}
                                    className="fullWidth-responsive"
                                    name="type"
                                    id="outlined-select-currency"
                                    select
                                    // label="Search Time"
                                    value={profileData?.showcaseBanner?.type}
                                    onChange={(e) =>
                                    setProfileData((prev) => ({
                                        ...prev,
                                        showcaseBanner: {
                                        ...prev?.showcaseBanner,
                                        value: '',
                                        type: e.target.value,
                                        extension: e.target.value === 'image' ? 'png' : '',
                                        name: e.target.value === 'image' ? prev?.showcaseBanner?.name : '',
                                        },
                                    }))
                                    }
                                    // required
                                >
                                    {['image', 'video'].map((i) => (
                                    <MenuItem value={i} key={i}>
                                        {i.charAt(0).toUpperCase() + i.substring(1)}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                            </Grid> */}
                                <Grid item xs={12} className="side-by-side ">
                                    <Grid item xs={12} md={3}>
                                        <CustomInputLabel
                                            label="Company Overview"
                                            labelProps={{ required: true }}
                                            helperText="Brief intro to your company"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            style={{ width: '100%' }}
                                            // TO SEE ALL THE CONFIGS - ClassicEditor.builtinPlugins.map((plugin) => plugin.pluginName)
                                            config={ckEditorConfig}
                                            data={profileData?.showcaseDesc}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // console.log({ event, editor, data });
                                                setProfileData((prev) => ({
                                                    ...prev,
                                                    showcaseDesc: data,
                                                }));
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} className="side-by-side">
                                    <Grid item xs={12} md={3} sx={{ flexDirection: 'column' }}>
                                        <InputLabel>Keywords</InputLabel>
                                        <span className="note-text">Include comma(,) between each word</span>
                                    </Grid>
                                    <Grid item xs={12} md={9} sx={{ flexDirection: 'column' }}>
                                        <TextField
                                        // sx={{ width: '70%' }}
                                        fullWidth
                                        className="fullWidth-responsive"
                                        name="showcaseHashtag"
                                        value={profileData?.showcaseHashtag?.text}
                                        onChange={handleOnChange}
                                        // required
                                        />
                                        {profileData?.showcaseHashtag?.text?.split(',').filter((i) => i)?.length > 0 && (
                                        <div className="d-flex flex-wrap" style={{ marginTop: '10px' }}>
                                            {profileData?.showcaseHashtag?.text?.split(',')?.map((i, index) => (
                                            <span key={`${i}-${index}`} className="hashtag-box">
                                                {i}
                                            </span>
                                            ))}
                                        </div>
                                        )}
                                    </Grid>
                                    </Grid> */}

                                <Grid item xs={12} className="side-by-side">
                                    <Grid item xs={12} md={3}>
                                        <CustomInputLabel label="Company Logo" labelProps={{ required: true }} helperText="our Brand Logo" />
                                    </Grid>
                                    <Grid item xs={12} md={9} style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                                        {!!profileData?.showcaseLogo?.value && (
                                            <img
                                                src={profileData?.showcaseLogo?.value}
                                                alt="Logo"
                                                height={150}
                                                // onError={handleImgErrorLogo}
                                                style={{ marginRight: '2rem' }}
                                            />
                                        )}
                                        <div className="my-3 my-md-2">
                                            <ImageUpload
                                                title="Upload Logo"
                                                onClose={(data) => getLogoHere(data)}
                                                previousImgSrc={profileData?.showcaseLogo?.value}
                                                // propAspect={1}
                                                propHeight={400}
                                                propWidth={400}
                                                propSize={2}
                                            />
                                            {!!profileData?.showcaseLogo?.value && (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="error"
                                                    sx={{ ml: 2 }}
                                                    onClick={() => clearImg('logo')}
                                                >
                                                    Clear Image
                                                </Button>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="side-by-side ">
                                    <Grid item xs={12} md={3}>
                                        <CustomInputLabel
                                            label="Company Banner Type"
                                            labelProps={{ required: true }}
                                            helperText="Template or Upload?"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <RadioGroup
                                            row
                                            defaultValue="select"
                                            name="radio-buttons-group"
                                            value={profileData?.showcaseBanner?.selectType}
                                            onChange={handleBannerSelectType}
                                        >
                                            <FormControlLabel value="select" control={<Radio />} label="Select From Template" />
                                            <FormControlLabel value="custom" control={<Radio />} label="Custom Upload" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>

                                {profileData?.showcaseBanner?.selectType === 'select' && (
                                    <Grid item xs={12} className="side-by-side ">
                                        <Grid item xs={12} md={3}>
                                            <InputLabel required>Banner Template</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} md={9} className="flex-column">
                                            <RadioGroup
                                                row
                                                className="row"
                                                value={profileData?.showcaseBanner?.templateSelected}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const data = profileData?.showcaseBanner?.templateData.filter((i) => i.link === value);
                                                    setProfileData((prev) => ({
                                                        ...prev,
                                                        showcaseBanner: {
                                                            ...prev.showcaseBanner,
                                                            templateSelected: value,
                                                            templateX: data?.[0]?.xAxis,
                                                            templateY: data?.[0]?.yAxis,
                                                        },
                                                    }));
                                                }}
                                            >
                                                {profileData?.showcaseBanner?.templateData?.length > 0 &&
                                                    profileData?.showcaseBanner?.templateData?.map((option, index) => (
                                                        <div className="col-md-6 mb-2 mb-md-3" key={index}>
                                                            <FormControlLabel
                                                                value={option.link}
                                                                control={<Radio />}
                                                                label={
                                                                    <Box>
                                                                        <img src={`${S3BucketBaseUrl}${option.link}`} alt="" key={index} />
                                                                    </Box>
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                            </RadioGroup>
                                            <div>
                                                <Button
                                                    variant={'contained'}
                                                    onClick={refreshPreview}
                                                    type="button"
                                                    ref={refreshClickRef}
                                                    disabled={isLoading}
                                                >
                                                    Generate Preview
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}

                                {profileData?.showcaseBanner?.selectType === 'select' &&
                                    profileData?.showcaseBanner?.templateSelected &&
                                    profileData?.showcaseLogo?.value && (
                                        <Grid item xs={12} className="side-by-side ">
                                            <Grid item xs={12} md={3}>
                                                <InputLabel required>Preview Banner Template</InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={9} className="flex-column">
                                                <MergeImage
                                                    ref={customCanvasRef}
                                                    logoX={Number(profileData?.showcaseBanner?.templateX)}
                                                    logoY={Number(profileData?.showcaseBanner?.templateY)}
                                                    bannersrc={S3BucketBaseUrl + profileData?.showcaseBanner?.templateSelected}
                                                    logosrc={profileData?.showcaseLogo?.value}
                                                    refresh={profileData?.showcaseBanner?.refreshPreview}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                {profileData?.showcaseBanner?.selectType === 'custom' && (
                                    <>
                                        <Grid item xs={12} className="side-by-side">
                                            <Grid item xs={12} md={3}>
                                                <CustomInputLabel
                                                    label="Banner Media Type"
                                                    labelProps={{ required: true }}
                                                    helperText="Image or Video Banner?"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <TextField
                                                    // fullWidth
                                                    sx={{ width: '50%', textAlign: 'left', backgroundColor: 'white' }}
                                                    className="fullWidth-responsive"
                                                    name="type"
                                                    id="outlined-select-currency"
                                                    select
                                                    // label="Search Time"
                                                    value={profileData?.showcaseBanner?.type}
                                                    onChange={(e) =>
                                                        setProfileData((prev) => ({
                                                            ...prev,
                                                            showcaseBanner: {
                                                                ...prev?.showcaseBanner,
                                                                value: '',
                                                                type: e.target.value,
                                                                extension: e.target.value === 'image' ? 'png' : '',
                                                                name: e.target.value === 'image' ? prev?.showcaseBanner?.name : '',
                                                            },
                                                        }))
                                                    }
                                                    // required
                                                >
                                                    {['image', 'video'].map((i) => (
                                                        <MenuItem value={i} key={i}>
                                                            {i.charAt(0).toUpperCase() + i.substring(1)}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="side-by-side">
                                            <Grid item xs={12} md={3}>
                                                <CustomInputLabel
                                                    label="Company Banner"
                                                    labelProps={{ required: true }}
                                                    helperText="Upload/Add your banner"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={9} style={{ alignItems: 'center', flexGrid: 'flex', flexWrap: 'wrap' }}>
                                                {profileData?.showcaseBanner?.type === 'image' ? (
                                                    <>
                                                        {!!profileData?.showcaseBanner?.value && (
                                                            <img
                                                                src={profileData?.showcaseBanner?.value}
                                                                alt="Banner"
                                                                height={150}
                                                                // onError={handleImgErrorBanner}
                                                                className="fit-contain"
                                                                style={{ marginRight: '2rem' }}
                                                            />
                                                        )}
                                                        <div className="my-3 my-md-2">
                                                            <ImageUpload
                                                                title="Upload Banner"
                                                                onClose={(data) => getBannerHere(data)}
                                                                previousImgSrc={profileData?.showcaseBanner?.value}
                                                                propAspect={4 / 1}
                                                                propWidth={1920}
                                                                propHeight={480}
                                                                propSize={5}
                                                            />
                                                            {!!profileData?.showcaseBanner?.value && (
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="error"
                                                                    sx={{ ml: 2 }}
                                                                    onClick={() => clearImg('banner')}
                                                                >
                                                                    Clear Image
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    profileData?.showcaseBanner?.type === 'video' && (
                                                        <TextField
                                                            // sx={{ width: '70%' }}
                                                            placeholder="Please add the video URL of the Banner"
                                                            fullWidth
                                                            className="fullWidth-responsive"
                                                            name="banner"
                                                            value={profileData?.showcaseBanner?.value}
                                                            onChange={(e) =>
                                                                setProfileData((prev) => ({
                                                                    ...prev,
                                                                    showcaseBanner: { ...prev?.showcaseBanner, value: e.target.value },
                                                                }))
                                                            }
                                                            onBlur={videoOnBlur}
                                                            // required
                                                        />
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                <Box
                                    sx={{
                                        position: 'fixed',
                                        bottom: 0,
                                        right: 0,
                                        left: isLg ? 250 : 0,
                                        bgcolor: 'white',
                                        p: 1,
                                        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
                                        gap: 2,
                                    }}
                                >
                                    <Button
                                        type="save"
                                        name="next"
                                        variant="contained"
                                        onClick={() => refreshClickRef.current && refreshClickRef.current.click()}
                                    >
                                        Save & Next
                                    </Button>
                                    {/* <div className="">
                                    <Button type="save" name="share" variant="contained">
                                        Save & Share
                                    </Button>
                                    </div> */}
                                </Box>
                            </Grid>
                        </form>
                    </Box>
                )}
            </Container>
        </>
    );
}

export default UserProfile;

const industryOptions = ['Renewable Energy', 'Energy Storage Solutions', 'Battery Technology'];
