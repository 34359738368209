import { useState } from 'react';
import ReactPlayer from 'react-player';
// @mui
import { Box, Button, ListItemText } from '@mui/material';
// components
import Iconify from '../iconify/Iconify';
import { StyledNavItem, StyledNavItemIcon } from './styles';
// utils
import { informaGreen } from '../../utils/constants';
import CommonModal from '../modal/CommonModal';
import { openInNewTab } from '../../utils/helper';

const helpVideoURL = 'https://vimeo.com/868233439';

export default function HelpNavButton() {
    const [helpCenterModal, setHelpCenterModal] = useState({ open: false });

    const openHelpCenter = () => {
        setHelpCenterModal((prev) => ({ ...prev, open: true }));
    };

    const closeHelpCenter = () => {
        setHelpCenterModal((prev) => ({ ...prev, open: false }));
    };

    return (
        <>
            {/* help center modal */}
            <CommonModal
                open={helpCenterModal?.open}
                onClose={closeHelpCenter}
                title={'Help Video'}
                customFooter={
                    <div className="d-flex justify-content-center w-100">
                        <Button onClick={openInNewTab(helpVideoURL)} variant="contained">
                            <Iconify icon="majesticons:open" className="me-3" />
                            Open Video
                        </Button>
                    </div>
                }
                body={
                    <div className="d-flex justify-content-center align-items-center">
                        <ReactPlayer
                            url={helpVideoURL}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        controls: 1,
                                        autoplay: 1,
                                    },
                                },
                            }}
                        />
                    </div>
                }
            />
            {/* help center btn */}
            <Box onClick={openHelpCenter}>
                <StyledNavItem
                    sx={{
                        color: '#161521',
                        margin: '0 0.5rem',
                        '&.active': {
                            color: 'black',
                            bgcolor: informaGreen,
                            fontWeight: 'fontWeightBold',
                        },
                        '&:hover': {
                            color: '#0E924D',
                        },
                    }}
                >
                    <StyledNavItemIcon>
                        <Iconify icon="mdi:video-outline" width={25} />
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={'Help Video'} />
                </StyledNavItem>
            </Box>
        </>
    );
}
