/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-body-style */
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';

import Iconify from '../components/iconify/Iconify';
import { S3BucketBaseUrl, defaultError } from './constants';
import customToast from './customToast';
import apiMiddleWare from './useApiMiddleWare';

// get extension from base64 image
export const getExtension = (base64Data) => {
  if (base64Data.indexOf(S3BucketBaseUrl) !== -1) {
    return base64Data.split('.').pop();
  }
  return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64')).trim();
};

// to upload 64base image to s3bucket we need to convert it to blob first
export const getBlob = async (fileUri) => {
  try {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();
    return imageBody;
  } catch (err) {
    return new Error(err);
  }
};

// video link editor
export function parseVideo(str) {
  const data = {
    provider: false,
    id: false,
    iframe: false,
    embed: false,
    rawLink: false,
    thumb: false,
  };
  const url = validURL(str);
  if (!url) {
    // $('#videoUrl').val('')
    // failureAlert('URL is not valid')
    customToast({ type: 'error', msg: `URL is not valid` });
    return data;
  }
  url.match(
    // eslint-disable-next-line no-useless-escape
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );
  if (RegExp.$3.indexOf('youtu') > -1 && RegExp.$6) {
    data.id = RegExp.$6;
    if (RegExp.$2 === 'player.') data.type = true;
    data.provider = 'youtube';
    data.rawLink = `https://www.youtube.com/watch?v=${data.id}`;
    data.thumb = `http://i3.ytimg.com/vi/${data.id}/hqdefault.jpg`;
    data.iframe = `https://www.youtube.com/embed/${data.id}?playlist=${data.id}&loop=1&autoplay=0&amp&fs=0&playsinline=1&mute=1&modestbranding=1`;
  } else if (RegExp.$3.indexOf('vimeo') > -1 && RegExp.$6) {
    data.id = RegExp.$6;
    data.rawLink = `https://vimeo.com/${data.id}`;
    data.thumb = `https://vumbnail.com/${data.id}_medium.jpg`;
    if (RegExp.$2 === 'player.') data.type = true;
    data.provider = 'vimeo';
    data.iframe = `https://player.vimeo.com/video/${data.id}?autoplay=0&loop=1&autopause=0&muted=1`;
  } else {
    data.provider = 'others';
    data.iframe = url;
    data.rawLink = url;
    data.thumb = url;
  }
  return data;
}

function validURL(url) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  if (pattern.test(url)) {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      return url;
    }
    return `https://${url}`;
  }
  return false;
}

export const openInNewTab = (url) => (url2) => {
  // console.log('url ==', url, '\nurl2 == ', url2);
  console.log('url', url, 'url2', url2);
  window.open(url || url2, '_blank', 'noopener,noreferrer');
};

export const copyText = (text = '', msg = '') => {
  navigator.clipboard.writeText(text);
  customToast({ type: 'success', msg });
};

export const timeZeroFormat = ({ date, hour }) =>
  moment(new Date(date))
    .utcOffset(0)
    .add({ hour: hour || '24' })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

export const useQuery = () => new URLSearchParams(useLocation().search);

export const customRenderValue = (selected) => {
  if (selected.length === 0) {
    return (
      <span>
        <Iconify icon="ic:round-filter-alt" />
        {'  '}--Select--
      </span>
    );
  }
  if (selected.length > 1) {
    return (
      <span>
        <Iconify icon="ic:round-filter-alt" />
        {'  '}
        {selected.length} Selected
      </span>
    );
  }
  return (
    <>
      <Iconify icon="ic:round-filter-alt" />
      {'  '}
      {selected.join(', ')}
    </>
  );
};

// Get Random Number between , min Included , max Excluded
export function randomNumber(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

// UPLOAD Image to S3
export async function uploadImageToS3({ sendData, value, headers, body: parentBody }) {
  try {
    const res = await apiMiddleWare({ url: '/company/get-s3-url', type: 'post', data: sendData });

    if (!res || !res?.data?.ok) {
      return new Error(res?.data?.errors[0]?.message || defaultError);
    }

    const dataHere = res?.data?.packet;

    const returnData = {};

    let url;
    const body = parentBody || (await getBlob(value));
    let checkLink;
    let dataName;
    let status;

    if (dataHere?.logo) {
      url = dataHere?.logo?.uploadURL;
      checkLink = dataHere?.logo?.source;
      dataName = 'logo';
    }

    if (dataHere?.banner) {
      url = dataHere?.banner?.uploadURL;
      checkLink = dataHere?.banner?.source;
      dataName = 'banner';
    }

    if (dataHere?.resource) {
      url = dataHere?.resource?.uploadURL;
      checkLink = dataHere?.resource?.source;
      dataName = 'resource';
    }

    if (dataHere?.rep) {
      url = dataHere?.rep?.uploadURL;
      checkLink = dataHere?.rep?.source;
      dataName = 'repPhoto';
    }

    if (dataHere?.thumbnail) {
      url = dataHere?.thumbnail?.uploadURL;
      checkLink = dataHere?.thumbnail?.source;
      dataName = 'thumbnail';
    }

    if (dataHere?.product) {
      url = dataHere?.product?.uploadURL;
      checkLink = dataHere?.product?.source;
      dataName = 'product';
    }

    status = await uploadImageAndCheck({ url, body, checkLink, headers });

    if (status) returnData[dataName] = checkLink;
    else {
      status = await uploadImageAndCheck({ url, body, checkLink, headers });
      if (status) returnData[dataName] = checkLink;
      else {
        status = await uploadImageAndCheck({ url, body, checkLink, headers });
        if (status) returnData[dataName] = checkLink;
      }
    }

    console.log('\n\n status', status);
    console.log('\n\n returnData', returnData);
    return returnData;
  } catch (err) {
    console.log(err);
    return false;
  }
}

// Image check if it is really there or not
export const uploadImageAndCheck = async ({ url, body, checkLink, headers = {} }) => {
  try {
    const res1 = await fetch(url, { method: 'PUT', body, headers });
    if (!res1) return false;

    const res2 = await fetch(`${S3BucketBaseUrl}${checkLink}`);
    if (res2.status === 200) return true;
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const handleDownload = async ({ fileName = 'data', dataOrder = [], dataObj = {}, data = [] }) => {
  if (data.length === 0) throw new Error('Data not found');
  if (dataOrder.length === 0) throw new Error('Data Order cannot be 0 length');
  const labelArr = dataOrder?.map((i) => dataObj[i].label);

  const dataArr = data.map((item, index1) => {
    return dataOrder.map((order, index2) => {
      if (index2 === 0) return index1 + 1;
      const valueLocation = dataObj[order].valueLocation;
      const value = valueLocation.split('.').reduce((obj, key) => {
        if (obj && key in obj) {
          return obj[key];
        }
        return undefined;
      }, item);
      const formatedValue = 'format' in dataObj[order] ? dataObj[order].format(value) : value;
      return formatedValue !== undefined ? formatedValue : '';
    });
  });

  const result = [labelArr, ...dataArr];

  try {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(
      result || []
      // [ ['Name', 'Age'], ['John', 25],['Jane', 30], ] ideal data structure
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert workbook to a binary XLSX file
    const xlsxBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const xlsxFile = new Blob([xlsxBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a temporary URL for the file
    const url = URL.createObjectURL(xlsxFile);

    // Create a download link element
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName.split(' ').join('-')}.xlsx`;

    // Trigger the download
    link.click();

    // Cleanup the temporary URL
    URL.revokeObjectURL(url);
    return true;
  } catch (err) {
    customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
    return false;
  }
};

export const canvasToImg = async (canvasRef) => {
  try {
    if (!canvasRef) return false;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const imageData = canvas.toDataURL('image/png'); // Change the format as needed (e.g., 'image/jpeg')
    return imageData;
  } catch (err) {
    console.log(err);
    return false;
  }
};
