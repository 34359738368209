import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// import secureLocalStorage from 'react-secure-storage';
import { AppBar, styled, Toolbar } from '@mui/material';

import { defaultError } from '../../utils/constants';
// import { parseVideo, openInNewTab } from '../../utils/helper';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import usePageVisibility from '../../hooks/usePageVisibility';
import useLocalStorage from '../../hooks/useLocalStorage';

const NAV_WIDTH = 250;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  backgroundColor: '#f2f7ff',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
  [theme.breakpoints.down('lg')]: {
    top: '50px',
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(1, 5),
  },
}));

function ShowcaseContextVisitor(props) {
  const navigate = useNavigate();
  const { showcaseUnique } = useParams();
  const pageVisible = usePageVisibility();
  const [showcaseData, setShowcaseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(0);
  // const roleData = secureLocalStorage.getItem('sessionInfo')?.roles;
  const [roleData] = useState(useLocalStorage('sessionInfo')?.roles);

  useState(() => {
    // console.log('roleData changed', roleData);
  }, [useLocalStorage('sessionInfo')]);

  useEffect(() => {
    checkPage();
  }, [showcaseUnique]);

  useEffect(() => {
    let interval;

    if (pageVisible && showcaseData?._id) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else if (!pageVisible) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [pageVisible, showcaseData]);

  useEffect(() => {
    if (time % 60000 === 0 && time !== 0) {
      // console.log('1 min API hit');
      // will call after every 1 minute
      // if (roleData?.length === 0 || (roleData?.length > 0 && !roleData?.includes('owner')))
      //   commonTimeFuncAPI(showcaseData?._id, showcaseData?.companyRef);  // uncomment after analytics done
    }
  }, [time]);

  const checkPage = async () => {
    try {
      setIsLoading(true);
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/view/${showcaseUnique}` });
      if (!res) {
        setIsLoading(false);
        navigate('/404');
        return false;
      }
      if (!res?.data?.ok) {
        // error in response
        setIsLoading(false);
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        navigate('/404');
        return false;
      }

      const allData = res?.data?.packet;
      setShowcaseData(allData);

      // const roleData = useLocalStorage('sessionInfo').roles;

      // callAnalyticsAPI(allData?._id, allData?.companyRef); // uncomment after analytics don

      setIsLoading(false);

      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);

      return false;
    }
  };

  async function callAnalyticsAPI(showcaseID, companyRef) {
    try {
      if (!roleData?.length > 0 || roleData?.includes('owner')) {
        return false;
      }
      const sendData = { showcaseRef: showcaseID, companyRef };
      const data = await apiMiddleWare({
        type: 'post',
        url: '/click/showcase',
        callType: 'analytics',
        data: sendData,
      });
      if (!data) {
        return false;
      }
      if (!data?.data?.ok) {
        console.log('ok false', data?.data);
        return false;
      }
      return true;
    } catch (err) {
      console.log('ok false', err);
      // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  }

  const commonTimeFuncAPI = async (showcaseID, companyRef) => {
    try {
      if (!showcaseID || !companyRef) return false;
      const sendData = { showcaseRef: showcaseID, companyRef };
      const data = await apiMiddleWare({
        type: 'post',
        callType: 'analytics',
        url: '/attendance/time-spent',
        data: sendData,
      });
      if (!data) {
        return false;
      }
      if (data?.data?.ok) {
        const allPacket = data?.data?.packet;
        if (!allPacket) {
          return false;
        }
        return true;
      }

      return false;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  return isLoading ? (
    <>Loading...</>
  ) : (
    <Outlet
      context={{ showcaseData, onOpenNav: props?.onOpenNav, StyledRoot, StyledToolbar, HEADER_DESKTOP, HEADER_MOBILE }}
    />
  );
}

export default ShowcaseContextVisitor;
