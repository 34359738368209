/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  // AppBar,
  Container,
  Grid,
  // IconButton,
  InputLabel,
  // styled,
  TextField,
  // Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Button } from 'react-bootstrap';
import { useCalendlyEventListener } from 'react-calendly';

import Iconify from '../../components/iconify';
import { S3BucketBaseUrl, defaultError } from '../../utils/constants';
import { copyText, openInNewTab, randomNumber } from '../../utils/helper';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import CommonModal from '../../components/modal/CommonModal';
import useResponsive from '../../hooks/useResponsive';
// import ContactCard from '../../../public/assets/icons/contact-card.svg';
import SvgColor from '../../components/svg-color';
import useLocalStorage from '../../hooks/useLocalStorage';
// import src from 'react-select/dist/declarations/src';

function VisitorContact(props) {
  const { showcaseUnique } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const lgUp = useResponsive('up', 'lg');
  const smUp = useResponsive('up', 'sm');
  const roleData = useLocalStorage('sessionInfo')?.roles;

  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState({
    showcase: {},
    contact: {
      contactList: [],
      calendlyLink: '',
      website: '',
      email: '',
      phone: '',
      address: '',
      socialMedia: {
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        pinterest: '',
        youtube: '',
      },
    },
  });
  const [inquireModal, setInquireModal] = useState({
    open: false,
    data: {
      topic: '',
      query: '',
    },
  });

  const { showcaseData, onOpenNav, StyledRoot, StyledToolbar, HEADER_DESKTOP, HEADER_MOBILE } = useOutletContext();
  // console.log('props in contact dashboard', props, onOpenNav);

  useEffect(() => {
    if (smUp) {
      navigate(`/showcase/${showcaseUnique}`);
    }
  }, [smUp]);

  useEffect(() => {
    if (Object.keys(showcaseData).length > 0) setDataShowcase();
  }, [showcaseData]);

  function isCalendlyEvent(e) {
    return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
  }

  useEffect(() => {
    function handleMessageCall(e) {
      if (isCalendlyEvent(e)) {
        /* Example to get the name of the event */
        console.log('Event data.event:', e.data.event);

        /* Example to get the payload of the event */
        console.log('Event details data.payload:', e.data.payload);
      }
    }
    window.addEventListener('message', handleMessageCall);

    return () => {
      window.removeEventListener('message', handleMessageCall);
    };
  }, []);
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: (e) => console.log('onDateAndTimeSelected', e),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  const setDataShowcase = async () => {
    try {
      setIsLoading(true);
      const allData2 = showcaseData;
      if (!allData2) {
        setIsLoading(false);
        return false;
      }

      if (getRep(allData2?._id)) {
        setIsLoading(false);
      }

      setAllData((prev) => ({
        ...prev,
        showcase: allData2,
        contact: {
          ...prev.contact,
          address: allData2?.address,
          calendlyLink: allData2?.calendlyLink,
          email: allData2?.email,
          phone: allData2?.phone,
          website: allData2?.website,
          socialMedia: {
            ...prev.contact.socialMedia,
            facebook: allData2?.socialMedia?.facebook,
            instagram: allData2?.socialMedia?.instagram,
            linkedin: allData2?.socialMedia?.linkedin,
            pinterest: allData2?.socialMedia?.pinterest,
            twitter: allData2?.socialMedia?.twitter,
            youtube: allData2?.socialMedia?.youtube,
          },
        },
      }));
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);

      return false;
    }
  };

  const getRep = async (showcaseID) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/rep/get/${showcaseID}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }

      const repList = res?.data?.packet?.filter((i) => i?.repID);
      const repSorted = repList?.sort((a, b) => a.priority - b.priority);
      const repSpread = repSorted?.map((i) => {
        const thisData = i?.repID;
        let image;
        const base = `/assets/icons/avatar/`;

        if (thisData?.photo) {
          image = S3BucketBaseUrl + thisData?.photo;
        } else {
          if (thisData?.gender === 'Male') {
            image = `${base}M-${randomNumber(1, 4)}.svg`;
          } else if (thisData?.gender === 'Female') {
            image = `${base}F-${randomNumber(1, 4)}.svg`;
          } else {
            image = `${base}M-1.svg`;
          }
        }
        i.repID.photo = image;

        return i?.repID;
      });

      setAllData((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          contactList: repSpread,
        },
      }));
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const onCloseInquireModal = () => {
    setInquireModal((prev) => ({
      ...prev,
      open: false,
      data: {
        query: '',
        topic: '',
      },
    }));
  };

  const onOpenInquireModal = () => {
    setInquireModal((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const onChangeInquire = (e) => {
    const { name, value } = e.target;
    setInquireModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  const validateInquireModal = async () =>
    new Promise((resolve, reject) => {
      const sendData = {};

      if (inquireModal?.data?.topic === '') {
        reject(new Error(`Topic is required`));
        return false;
      }
      sendData.topic = inquireModal?.data?.topic?.trim();

      if (inquireModal?.data?.query === '') {
        reject(new Error(`Query is required`));
        return false;
      }
      sendData.query = inquireModal?.data?.query?.trim();

      resolve(sendData);
      return true;
    });

  const submitInquire = async () => {
    try {
      // setIsLoading(true);

      const sendData = await validateInquireModal();

      const finalData = { ...sendData, companyRef: allData?.showcase?.companyRef, showcaseRef: allData?.showcase?._id };

      const res = await apiMiddleWare({
        type: 'post',
        url: `/visitors/showcase-query`,
        data: finalData,
      });

      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          type: 'error',
        });
        // setIsLoading(false);
        return false;
      }

      customToast({
        msg: 'Query Submitted Successfully',
        type: 'success',
      });
      onCloseInquireModal();
      // setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      // setIsLoading(false);
      return false;
    }
  };

  const analyticsSocial = async (name) => {
    // 'facebook', 'instagram', 'linkedin', 'twitter', 'pinterest', 'youtube'
    try {
      // if (!roleData?.length > 0 || roleData?.includes('owner')) {
      //   return false;
      // }
      const sendData = {
        companyRef: allData?.showcase?.companyRef,
        showcaseRef: allData?.showcase?._id,
        socialMedia: name,
      };
      const data = await apiMiddleWare({ type: 'post', url: '/click/social', callType: 'analytics', data: sendData });
      if (!data) {
        return false;
      }
      if (!data?.data?.ok) {
        console.log('ok false', data?.data);
        return false;
      }
      return true;
    } catch (err) {
      console.log('ok false', err);
      // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  return isLoading ? (
    <>Loading...</>
  ) : Object.keys(allData?.showcase).length !== 0 ? (
    <>
      <CommonModal
        open={inquireModal?.open}
        onClose={onCloseInquireModal}
        title={`Showcase Inquiry`}
        onSubmit={submitInquire}
        body={
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Topic</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    // fullWidth
                    name="topic"
                    value={inquireModal?.data?.topic}
                    placeholder="Type Topic of Query Here"
                    onChange={onChangeInquire}
                    inputProps={{ maxLength: 100 }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Query</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    // sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    fullWidth
                    name="query"
                    placeholder="Type Descriptive Query Here"
                    value={inquireModal?.data?.query}
                    onChange={onChangeInquire}
                    multiline
                    rows={5}
                    // inputProps={{ maxLength: 30 }}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />

      <Container
        maxWidth="xl"
        className="px-4 px-md-5"
        sx={{
          pb: 2,
          m: 0,
          // height: '100vh', overflow: 'auto'
        }}
      >
        <StyledRoot
          sx={{
            '(max-width: 1080px)': {
              top: '0',
            },
          }}
        >
          <StyledToolbar>
            <div className="d-flex justify-content-center justify-content-md-between flex-column flex-md-row align-items-start flex-wrap w-100">
              <div className="showcase__title mt-2 d-flex justify-content-center text-black ms-auto me-auto ms-md-0">
                {/* <IconButton
                  onClick={onOpenNav}
                  sx={{
                    mr: 1,
                    color: 'text.primary',
                    display: { lg: 'none' },
                  }}
                >
                  <Iconify icon="eva:menu-2-fill" />
                </IconButton> */}
                {/* <Typography
                  variant="string"
                  className="mb-1 mb-lg-3 w-315px ellipse-text"
                  sx={{ fontSize: '30px', fontWeight: '600' }}
                >
                  {allData?.showcase?.name || ''}
                </Typography> */}
                <Typography variant="string" className=" mb-1 mb-lg-3 w-315px ellipse-text font-main-title-custom">
                  {allData?.showcase?.name || ''}
                </Typography>
              </div>
              <div className="d-flex w-auto w-sm-100 flex-wrap my-2 my-lg-auto ms-auto me-auto me-md-0  justify-content-lg-end ">
                {allData?.contact?.calendlyLink && (
                  <div className="d-flex my-auto ms-0 ms-md-auto me-3 ">
                    <Button
                      variant="primary"
                      className="bg-text-black"
                      onClick={openInNewTab(allData?.contact?.calendlyLink)}
                    >
                      Schedule Meeting
                    </Button>
                  </div>
                )}
                <div className="my-auto me-md-auto ">
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                      if (!roleData?.length > 0 || roleData?.includes('owner')) {
                        navigate(`/login`, {
                          state: { from: location },
                        });
                      } else {
                        onOpenInquireModal();
                      }
                    }}
                  >
                    Inquire Now
                  </Button>
                  {/* <PopupButton
                url="https://calendly.com/bharaths_24"
                rootElement={document.getElementById('root')}
                text="Schedule"
              /> */}
                </div>
              </div>
            </div>
          </StyledToolbar>
        </StyledRoot>
        {/* </div> */}

        <div className="mb-4 " style={{ marginTop: lgUp ? HEADER_DESKTOP : smUp ? '140px' : '163px' }}>
          {lgUp && (
            <div className="d-flex mb-2">
              {/* <Typography variant="h5" className="text-black fs-5 mt-3">
              Company Details
            </Typography> */}
              <div className="ms-auto mt-3">
                <Tooltip
                  title={'Click on the fields to copy them'}
                  placement="left-start"
                  className="cursor-pointer my-auto fs-2"
                >
                  <Iconify icon="material-symbols:info-outline-rounded" width="10" />
                </Tooltip>
              </div>
            </div>
          )}
          <div className=" row mx-0 border-custom bg-white p-2 py-3">
            <div className="col-lg-6 display-name-value">
              <div>Website Link</div>
              <Tooltip
                title={allData?.contact?.website}
                placement="bottom-start"
                className="cursor-pointer"
                onClick={() => allData?.contact?.website && copyText(allData?.contact?.website, 'Website Link Copied')}
              >
                <div className="ellipse-text">{allData?.contact?.website || '-'}</div>
              </Tooltip>
            </div>
            <div className="col-lg-6 display-name-value text-start text-lg-end">
              <div>Email</div>
              <Tooltip
                title={allData?.contact?.email}
                placement={lgUp ? 'bottom-end' : 'bottom-start'}
                className="cursor-pointer"
                onClick={
                  () => allData?.contact?.email && openInNewTab(`mailto:${allData?.contact?.email}`)()
                  // copyText(allData?.contact?.email, 'Email Copied')
                }
              >
                <div className="ellipse-text">{allData?.contact?.email || '-'}</div>
              </Tooltip>
            </div>

            <div className="col-lg-6 display-name-value">
              <div>Address</div>

              <Tooltip
                title={allData?.contact?.address}
                placement="bottom-start"
                className="cursor-pointer"
                onClick={() => allData?.contact?.address && copyText(allData?.contact?.address, 'Address Copied')}
              >
                <div className="ellipse-text">{allData?.contact?.address || '-'}</div>
              </Tooltip>
            </div>
            <div className="col-lg-6 display-name-value text-start text-lg-end">
              <div>Phone Number</div>
              <Tooltip
                title={allData?.contact?.phone}
                placement={lgUp ? 'bottom-end' : 'bottom-start'}
                className="cursor-pointer"
                onClick={
                  () => allData?.contact?.phone && openInNewTab(`tel:${allData?.contact?.email}`)()
                  // copyText(allData?.contact?.phone, 'Phone Number Copied')
                }
              >
                <div className="ellipse-text">{allData?.contact?.phone || '-'}</div>
              </Tooltip>
            </div>

            {allData?.contact?.calendlyLink && (
              <div className="col-lg-12 display-name-value">
                <div>Calendly Link</div>
                <Tooltip
                  title={allData?.contact?.calendlyLink}
                  placement="bottom-start"
                  className="cursor-pointer"
                  onClick={() =>
                    allData?.contact?.calendlyLink && copyText(allData?.contact?.calendlyLink, 'Calendly Link Copied')
                  }
                >
                  <div className="ellipse-text">{allData?.contact?.calendlyLink || '-'}</div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        {(allData?.contact?.socialMedia?.facebook ||
          allData?.contact?.socialMedia?.instagram ||
          allData?.contact?.socialMedia?.linkedin ||
          allData?.contact?.socialMedia?.twitter ||
          allData?.contact?.socialMedia?.pinterest ||
          allData?.contact?.socialMedia?.youtube) && (
          <div className=" ">
            {/* <Typography variant="h5" className="text-black fs-5 mt-4">
              Social Media
            </Typography> */}
            <div className="display-6  d-flex flex-wrap justify-content-center">
              {allData?.contact?.socialMedia?.facebook && (
                <>
                  <div
                    className="cursor-pointer mx-3 my-3 social_media_icon_sz"
                    onClick={() => {
                      // analyticsSocial('facebook'); // uncomment after analytics done
                      openInNewTab(allData?.contact?.socialMedia?.facebook)();
                    }}
                  >
                    {/* <Iconify icon="logos:facebook" width="10" /> */}
                    <img src="/assets/icons/social_media/facebook-icon.png" alt="" />
                  </div>
                </>
              )}

              {allData?.contact?.socialMedia?.instagram && (
                <>
                  <div
                    className="cursor-pointer mx-3 my-3 social_media_icon_sz"
                    onClick={() => {
                      // analyticsSocial('instagram');// uncomment after analytics done
                      openInNewTab(allData?.contact?.socialMedia?.instagram)();
                    }}
                  >
                    {/* <Iconify icon="logos:instagram-icon" width="10" /> */}
                    <img src="/assets/icons/social_media/instagram-icon.png" alt="" />
                  </div>
                </>
              )}

              {allData?.contact?.socialMedia?.linkedin && (
                <>
                  <div
                    className="cursor-pointer mx-3 my-3 social_media_icon_sz"
                    onClick={() => {
                      // analyticsSocial('linkedin');// uncomment after analytics done
                      openInNewTab(allData?.contact?.socialMedia?.linkedin)();
                    }}
                  >
                    {/* <Iconify icon="logos:linkedin-icon" width="10" /> */}
                    <img src="/assets/icons/social_media/linkedin-icon.png" alt="" />
                  </div>
                </>
              )}

              {allData?.contact?.socialMedia?.twitter && (
                <>
                  <div
                    className="cursor-pointer mx-3 my-3 social_media_icon_sz"
                    onClick={() => {
                      // analyticsSocial('twitter');// uncomment after analytics done
                      openInNewTab(allData?.contact?.socialMedia?.twitter)();
                    }}
                  >
                    {/* <Iconify icon="logos:twitter" width="10" /> */}
                    <img src="/assets/icons/social_media/twitter-icon.png" alt="" />
                  </div>
                </>
              )}

              {allData?.contact?.socialMedia?.pinterest && (
                <>
                  <div
                    className="cursor-pointer mx-3 my-3 social_media_icon_sz"
                    onClick={() => {
                      // analyticsSocial('pinterest');// uncomment after analytics done
                      openInNewTab(allData?.contact?.socialMedia?.pinterest)();
                    }}
                  >
                    {/* <Iconify icon="logos:pinterest" width="10" /> */}

                    <img src="/assets/icons/social_media/pinterest-icon.png" alt="" />
                  </div>
                </>
              )}

              {allData?.contact?.socialMedia?.youtube && (
                <>
                  <div
                    className="cursor-pointer mx-3 my-3  social_media_icon_sz"
                    onClick={() => {
                      // analyticsSocial('youtube');// uncomment after analytics done
                      openInNewTab(allData?.contact?.socialMedia?.youtube)();
                    }}
                  >
                    {/* <Iconify icon="logos:youtube-icon" width="8" /> */}
                    <img src="/assets/icons/social_media/youtube-icon.png" alt="" />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {allData?.contact?.contactList?.length > 0 && (
          <div className="mb-4  mt-3 mt-md-0">
            {/* <img src="/assets/icons/contact-card.svg" alt="Your SVG" /> */}
            {/* <div>{icon('contact_card')}</div> */}
            <div className="d-flex mb-2">
              <Typography variant="h5" className="text-black mt-4 mb-3 font-title-custom">
                Team
              </Typography>
              {lgUp && (
                <div className="ms-auto mt-3">
                  <Tooltip
                    title={'Click on the fields to copy them'}
                    placement="left-start"
                    className="cursor-pointer my-auto fs-2"
                  >
                    <Iconify icon="material-symbols:info-outline-rounded" width="10" />
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="row">
              {/* {allData?.contact?.contactList?.map((i, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-3">
                  <div
                    className="row mx-0 p-2 py-3 font-body-custom"
                    style={{
                      backgroundImage: `url('/assets/icons/contact_card.svg')`,
                      borderRadius: '10px',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      height: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <div className="col-md-12  ">
                      <Tooltip
                        title={i?.name}
                        placement="bottom-start"
                        className="cursor-pointer"
                        onClick={() => i?.name && copyText(i?.name, 'Name Copied')}
                      >
                        <div className="ellipse-text text-white fs-5 fw-bold ">{i?.name || '-'}</div>
                      </Tooltip>
                    </div>
                    <div className="col-md-12 mb-2">
                      <Tooltip
                        title={i?.designation}
                        placement={'bottom-start'}
                        className="cursor-pointer"
                        onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                      >
                        <div className="ellipse-text text-white fw-lighter">{i?.designation || '-'}</div>
                      </Tooltip>
                    </div>
                    <div className="col-md-12  mt-4 mb-2">
                      <Tooltip
                        title={i?.email}
                        placement={'bottom-start'}
                        className="cursor-pointer"
                        onClick={() => i?.email && copyText(i?.email, 'Email Copied')}
                      >
                        <div className="ellipse-text  text-white ">{i?.email || '-'}</div>
                      </Tooltip>
                    </div>
                    <div className="col-md-12  ">
                      <Tooltip
                        title={i?.phone}
                        placement="bottom-start"
                        className="cursor-pointer"
                        onClick={() => i?.phone && copyText(i?.phone, 'Phone Number Copied')}
                      >
                        <div className="ellipse-text text-white">{i?.phone || '-'}</div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))} */}
              {allData?.contact?.contactList?.length === 0 ? (
                      <div className="text-center fs-5 fw-lighter opacity-5">No support team added</div>
                    ) : (
                      allData?.contact?.contactList?.map((i) => (
                        <div className=" col-12 col-lg-4 col-lg-2 mb-2" key={i?._id}>
                          <div className=" border border_radius_8px p-3 custom-shadow2">
                            <div className="d-flex justify-content-center mb-3 ">
                              <img
                                src={i?.photo}
                                alt=""
                                className="border_radius_100 round-border h-100px overflow-hidden aspect-ratio-1 background-white fit-contain"
                              />
                            </div>
                            <div>
                              <Tooltip title={i?.name} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-bold fs-6">
                                  {i?.name || ''}
                                </div>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title={i?.designation} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                                  {i?.designation || ''}
                                </div>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title={i?.email} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                                  {i?.email || ''}
                                </div>
                              </Tooltip>
                            </div>

                            <div className="d-flex justify-content-center">
                              <Button
                                type="button"
                                // disabled={!addProduct2?.data?.contactList?.value?.includes(i?._id)}
                                // onClick={sendInvite(i)}
                                variant="contained"
                                className="mt-3 box-shadow-none w-100 green-btn"
                              >
                                Chat
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
            </div>
          </div>
        )}
        {/* </div> */}
      </Container>
    </>
  ) : (
    <>Showcase Not Found</>
  );
}

export default VisitorContact;

const icon = (name) => <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 10, height: 10 }} />;
