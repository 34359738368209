import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// functionality things
import { Button, Container, InputLabel, Slider, TextField } from '@mui/material';

import { canvasToImg } from '../utils/helper';

import MergeImage from '../components/merge-image/MergeImage';
import useResponsive from '../hooks/useResponsive';

export default function MergeImageTest() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const lgUp = useResponsive('up', 'lg');

  const [positionLogo, setPositionLogo] = useState({ logoX: 1503, logoY: 290, scale: 1 });
  const customCanvasRef = useRef(null);

  const [previewImg, setPreviewImg] = useState('');
  const [userData, setUserData] = useState({
    logo: 'https://projects-samaaro.s3.ap-south-1.amazonaws.com/showcase-informa/64786f8d6ffc3b2b24db8970/logo/hkjJ-Iitul.png',
    // logo : 'https://projects-samaaro.s3.ap-south-1.amazonaws.com/showcase-informa/645c8d909d63e75029bf41f3/logo/b98D40JlTe.png' ,
    banner:
      'https://projects-samaaro.s3.ap-south-1.amazonaws.com/showcase-informa/__static/banner/informa-banner-1.png',
    refresh: false,
  });

  const getBase64 = async () => {
    const base64img = await canvasToImg(customCanvasRef);
    console.log({ base64img });
    setPreviewImg(base64img);
  };

  const generateDownload = async () => {
    const base64img = await canvasToImg(customCanvasRef);
    downloadImageFromBase64(base64img, 'test.png');
  };

  function downloadImageFromBase64(base64String, fileName) {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = base64String;
    link.download = fileName;

    // Simulate a click on the anchor element
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    link.dispatchEvent(clickEvent);
  }

  const handlePosition = (e, newVal) => {
    const { name, value } = e.target;
    setPositionLogo((prev) => ({
      ...prev,
      [name]: newVal ? Number(value) : Number(newVal),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const generateMerge = () => {
    setUserData((prev) => ({
      ...prev,
      refresh: !prev.refresh,
    }));
  };

  const displayWidth = smUp ? (mdUp ? (lgUp ? 1100 : 700) : 500) : 330;
  const displayRatio = 4 / 1;
  return (
    <>
      <Helmet>
        <title> Informa Marketplace </title>
      </Helmet>
      <Container className="my-4">
        <div>
          <div className="d-flex flex-col flex-md-row">
            <TextField
              sx={{ width: '50%' }}
              className="fullWidth-responsive"
              placeholder="Banner source"
              // fullWidth
              name="banner"
              value={userData?.banner}
              label="Banner Source"
              onChange={handleChange}
            />

            <TextField
              sx={{ width: '50%' }}
              className="fullWidth-responsive"
              placeholder="Logo source"
              // fullWidth
              name="logo"
              value={userData?.logo}
              label="Logo Source"
              onChange={handleChange}
            />

            <Button type="button" variant="contained" className="flex-grow-1" onClick={generateMerge}>
              Generate
            </Button>
          </div>

          <div className="d-flex">
            <Slider
              getAriaLabel={() => 'X axis'}
              value={positionLogo.logoX}
              name="logoX"
              onChange={handlePosition}
              valueLabelDisplay="auto"
              max={2500}
            />
            <input type="number" name="logoX" value={positionLogo.logoX} onChange={handlePosition} placeholder="X" />
          </div>
          <div className="d-flex">
            <Slider
              getAriaLabel={() => 'Y axis'}
              value={positionLogo.logoY}
              name="logoY"
              onChange={handlePosition}
              valueLabelDisplay="auto"
              max={600}
            />
            <input type="number" name="logoY" value={positionLogo.logoY} onChange={handlePosition} placeholder="Y" />
          </div>
          <div className="d-flex">
            <Slider
              getAriaLabel={() => 'Scale Logo'}
              value={positionLogo.scale}
              name="scale"
              onChange={handlePosition}
              valueLabelDisplay="auto"
              min={1}
              step={0.1}
              max={5}
            />
            <input
              type="number"
              name="scale"
              min="1"
              value={positionLogo.scale}
              onChange={handlePosition}
              placeholder="Increase size of logo"
            />
          </div>

          <div style={{ width: `${displayWidth}px`, height: `${displayWidth / displayRatio}px` }}>
            <MergeImage
              ref={customCanvasRef}
              logoX={positionLogo.logoX}
              logoY={positionLogo.logoY}
              scaleLogo={positionLogo.scale}
              bannersrc={userData?.banner}
              logosrc={userData?.logo}
              refresh={userData?.refresh}
              test
            />
          </div>
          <div>
            <img src={previewImg} alt="" />
          </div>
          {previewImg && <div>Preview ^^^</div>}
          <Button type="button" onClick={getBase64} variant="contained">
            Generate Preview
          </Button>
          <Button type="button" onClick={generateDownload} variant="contained">
            Generate And Download
          </Button>
        </div>
      </Container>
    </>
  );
}
