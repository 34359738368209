import { useEffect } from 'react';
import PropTypes from 'prop-types';

useDebounceEffect.propTypes = {
  fn: PropTypes.object,
  waitTime: PropTypes.number,
  deps: PropTypes.array,
};

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
