const terms = [
  {
    title: 'Definitions',
    points: [
      `In these Terms and Conditions, the following definitions shall apply:
      “Membership Package” means the membership tier and associated entitlements as chosen by you on the Order Form;
      “Catalogue/Marketing Material” means the marketing information (images and description) provided by you relating to your Products and/or Services;
      “Fees” means the annual fees payable in respect of your Membership Package;
      “Supplier”, “our”, “we”, or “us” means the person or persons named as the supplier of the Membership Package as more particularly described in the Order Form;
      “Intellectual Property” means patents, rights to inventions, copyright and related rights, trade marks and service marks, trade names and domain names, goodwill and the right to sue for passing off or unfair competition, rights in designs, trade dress, database rights, rights to preserve the confidentiality of information (including know-how and trade secrets) and any other intellectual property rights, including all applications for (and rights to apply for and be granted), renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of the world;
      “Leads” means the contact details of people/companies interested in you and your Products and/or Services;
      “Supplier Dashboard” means information specifically relating to your account, which can be accessed by your logging-on to the Site;
      “Materials” means any content, materials and/or information on the Site excluding Member Content;
      “Member”, “you” or “your” means the person, firm, company or other body stated as such on the Order Form;
      “Member Content” means any content, materials and/or information on the Site which is contributed by you or on your behalf (whether by uploading directly to the Site or otherwise);
      “Membership” means membership to the Site via your subscription to a Membership Package;
      “Membership Benefits” means the benefits applicable to your particular Membership Package as detailed on the Order Form;
      “Membership Period” means the Initial Term or the current Renewal Period (as applicable);
      “Order Form” means the order form completed by you as part of your application for Membership;
      “Product Categories” means the general categorization of different types of products/equipment as detailed on the Site;
      “Products and/or Services” means your products and/or services, images and details of which may be uploaded to the Site;
      “Site” means the website identified with the url: https://reiconnect.online/ which may be amended or replaced at the Supplier’s sole discretion without notice to a Member; 
      "Terms" means these REI Connect subscriptions Terms & Conditions;
      “Upgrade Payment” means the Fees payable by you for any upgrade of your Membership Package.`,
    ],
  },

  {
    title: 'Membership Applications and Payment',
    points: [
      `All applications to subscribe to the Site must be made via the Order Form, and you must choose the Membership Package to which you would like to subscribe, provide the details requested on the Order Form and pay the Fees in accordance with the payment terms in the Order Form. You shall co-operate with the Supplier to provide us with any reasonable information which we require about your application.`,
      `Upon our receipt of the Order Form and the Fees, we will contact you to inform you of your Membership's account activation date (“Account Activation Date”).`,
      `You undertake that (i) all details you provide to us relating to your Membership will be correct, and (ii) the credit/debit card you use to pay the Fees (if any) is your own. We reserve the right to validate any credit/debit card used prior to providing you with your Membership Benefits. Access to your Membership Benefits will not be provided until we are in receipt of the Fees in full, in cleared funds.`,
      `It is within our absolute discretion (without any requirement to provide an explanation) to refuse any Membership application. Acceptance of any payment made by you does not mean that the application is successful, unless and until your Membership Period has started. Without prejudice to clause 13.1, if we decide to reject your Membership application or terminate your Membership, we will notify you in writing of our decision.`,
      `Payment of the Fees by the date(s) stipulated in these Terms is of the essence to your Membership and continued use of the Site. If any amounts payable under these Terms are not paid to us by their due date for payment (whether Fees or otherwise), then we may charge interest on such sum from the due date of payment at the annual rate of 4% above the base lending rate from time to time of HSBC Limited Mumbai, accruing on a daily basis and being compounded quarterly until payment is made, whether before or after judgment.`,
      `If you wish to use a company name on the Site which is different to that noted on your Order Form, you must submit notice of this change to the Supplier at least 7 days prior to the date of the company name change on the Site, together with the following: (i) documentation signed by a certified accountant or the company secretary (where you are a registered limited liability company) to prove that only your company name has changed; or (ii) other documentation to show that the new company name belongs to a wholly-owned subsidiary of the company on the Order Form.`,
      `Each Membership Package available via the Site has the different Membership Benefits described on the Order Form.`,
      `Your Membership Benefits will be limited solely to the benefits contained in the Membership Package to which you have subscribed.`,
    ],
  },

  {
    title: 'Term and Renewal',
    points: [
      'Your initial Membership term shall be for a period of 12 months from the Account Activation Date (the “Initial Term”). Any renewal Membership term shall be for a period of 12 months (the “Renewal Period”) from the date that the Initial Term or the preceding Renewal Period expired (the “Renewal Date”).',
      {
        title:
          'We will send you a renewal notice (a “Renewal Notice”) prior to the expiry of the Initial Term or any Renewal Period. Each Renewal Notice will:',
        points: [
          'notify you of the amount of renewal Fees payable in respect of your Membership Package;',
          'ask whether you wish to upgrade your Membership Package; and',
          'alert you as to whether or not there are any changes to the Terms that will be applicable during the Renewal Period (“Terms Changes”), which such changes shall be effective from the Renewal Date.',
        ],
      },
      'Continued use of the Membership Benefits after the Renewal Date will constitute acceptance by you of any Terms Changes and you will be deemed to have renewed your existing Membership Package for the Renewal Period. All renewal Fees are payable in full in advance and (without prejudice to any other available rights or remedies) we reserve the right to suspend your access to the Site on or after the Renewal Date until the renewal Fees are paid.',
    ],
  },

  {
    title: 'Upgrades',
    points: [
      'You may apply to upgrade your Membership Package at any time upon written notice to us. If we accept your application to upgrade your Membership Package, and upon our receipt of the Upgrade Payment, the initial date of your Membership will be revised to the date on which you successfully upgrade your Membership Package, and it shall continue for a period of 12 months thereon.',
    ],
  },

  {
    title: 'Usernames and Passwords',
    points: [
      'Upon acceptance by us of your Membership application, we will issue you (and your employees, if applicable) with a username and password. Usernames and passwords remain the property of the Supplier at all times and may not be sold, assigned or transferred to any third party without our permission. Your username and password are personal to you. You hereby acknowledge that you will not permit others to use your username or password and you will be and remain liable for the acts of any person using your username and password.',
    ],
  },

  {
    title: 'Member Content',
    points: [
      'All Member Content submitted via the Site must comply with these Terms. We reserve the right, at our sole discretion, to remove any Member Content we deem inappropriate or uncompliant with these Terms.',
      'Any Member Content you supply or upload to the Site will be considered non-confidential and non-proprietary, and we have the right to use, copy, distribute and disclose to third parties any such Member Content for any purpose. We also have the right to disclose your identity to any third party who is claiming that any Member Content supplied by you for posting on the Site or uploaded by you to the Site constitutes (i) violation of their Intellectual Property Rights, and/or (ii) an infringement of their right to privacy.',
      'To the extent that we become aware that any Member Content infringes the Intellectual Property rights of any third party, we reserve the right to remove such Member Content without prior notice to you.',
      'To the fullest extent permitted by law, we will not be responsible, or liable to any third party, for the content or accuracy of any Member Content supplied by you for posting on the Site or uploaded by you to the Site.',
      'You are solely responsible for checking the accuracy of any Member Content supplied by you for posting on the Site or uploaded by you to the Site and we will not be liable for any inaccuracies in any such Member Content.',
      'You hereby grant us a perpetual, irrevocable, royalty-free, non-exclusive, worldwide, sub-licensable, transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, play, make available to the public and exercise all copyright and publicity rights with respect to your Member Content and/or to incorporate your Member Content in other works in any media now known or later developed for the full term of any rights that may exist in your Member Content (including, without limitation, in the Site).',
      'You hereby waive any moral rights in any Member Content supplied by you for posting on the Site or uploaded by you to the Site to the fullest extent permitted by law.',
      'You hereby grant us, for the Membership Period, a royalty-free, non-exclusive, worldwide license to use your trade marks and logos on the Site and in marketing materials relating to the Site.',
      'You warrant, represent and undertake that you have obtained all necessary and relevant local and national approvals and licenses to advertise your Member Content.',
      {
        title: 'You further warrant, represent and undertake that your Member Content:',
        points: [
          `is your own original work and that you have the right to make it available to us for all the purposes specified above and that it does not breach or infringe anyone else's rights (including, without limitation, the Intellectual Property rights of a third party);`,
          'is not in any way defamatory, libellous, obscene, menacing, threatening, offensive, abusive or fraudulent; and',
          'is not in any way illegal, will not and does not contravene any relevant local, national or international law or incite or encourage the contravention of any such law.',
        ],
      },
      'You agree that you are solely responsible and liable for any costs, damages, expenses or any other liability arising from any Member Content supplied by you for posting on the Site or uploaded by you to the Site.',
      'We reserve the right, at our sole discretion and without notice or liability, to edit or remove postings to any message boards or other interactive areas on the Site and delete or use electronic methods to block or filter any Member Content at our discretion, but we do not have an obligation to do so.',
      'You shall not make libellous postings or any postings which are illegal or infringe the Intellectual Property rights of any third party. The Supplier shall not be responsible for monitoring Member Content for compliance with law.',
    ],
  },

  {
    title: 'Indemnity',
    points: [
      {
        title:
          'You agree and undertake to indemnify and keep us fully and effectively indemnified on demand from and against all costs, claims, losses, damages, legal fees and other expenses that may be incurred by Informa as a result of or in connection with any:',
        points: [
          'use by you of the Site, the Materials and/or any Member Content;',
          'breach by you of these Terms;',
          'third party claim regarding the inaccuracy of Member Content or the infringement of third party Intellectual Property rights;',
          'third party claim regarding your access, storage or any type of use of third party personal data or information on the Site; and/or',
          'adverse consequences, including, without limitation, physical or mental impairment, injury or death suffered by any user of the Site as a result of following recommendations contained in Member Content supplied by you.',
        ],
      },
    ],
  },

  {
    title: 'Personal Information and Monitoring Rights',
    points: [
      'Any personal information you provide to us will be processed in accordance with our Privacy policy and these Terms. Without limitation, you authorise us to use, store or otherwise process any personal information which relates to you and/or your employees (including, but not limited to, names and addresses), to the extent reasonably necessary for us, our partners, successors (including the purchaser of the whole or part of our business), associates, sub-contractors or other third parties to operate the Site and provide you with the Membership Benefits. If you and/or your employees use the Site then we may collect information about your or their behaviour, and if you and/or your employees send us personal correspondence such as e-mails or letters then we may collect this information into a file specific to you.',
      'We have the right, but shall not have the obligation, to monitor use of the Site in order to verify levels of usage, compliance with these Terms and/or any operating rules established by us and/or to satisfy any law, regulation or authorised government requests.',
      'Any personal information you disclose to any third party on the Site in any form shall be at your own judgment, including via any chat, audio, video or other interactive function. Further, there may be certain areas of the Site to which your access is conditional on your personal information being provided to third parties that have sponsored or are managing such areas. The use that any third parties make of your personal information is outside of our control and, to the fullest extent permitted by applicable law, we do not accept any liability in this regard.',
    ],
  },
  {
    title: 'Site Access',
    points: [
      'You agree that all usernames and passwords used to access the Site are confidential and personal to you and your employees (as applicable). You shall ensure that neither you nor your employees disclose any usernames or passwords to any third party. You must notify the Supplier immediately of any unauthorised use of any usernames or passwords, or any other breach of security regarding the Site that comes to your attention.',
      {
        title: 'You may use the Site solely for your internal business purposes. Without limitation, you must not:',
        points: [
          'download, store, reproduce, transmit, display, copy, distribute, exploit or use the Site and/or any Materials for your own commercial gain;',
          'resell, sub-license, rent, lease, transfer or attempt to assign the rights in the Site and/or any Materials (in whole or in part) to any other person;',
          'use the Site and/or the Materials in any manner other than in compliance with applicable laws and these Terms;',
          'infringe our intellectual property rights or those of any third party in relation to your use of the Site;',
          'knowingly transmit, send or upload any data that contains viruses, Trojan horses, worms or any other harmful programs or similar computer code;',
          'use the Site in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;',
          'interfere with, manipulate, damage or disrupt the Site.',
        ],
      },
      'We cannot guarantee that the Site, including any chat, audio, video conferencing or other interactive function, will operate continuously or without interruption and we do not accept any liability for its temporary unavailability. You must not attempt to interfere with the proper working of the Site (for example, by attempting to circumvent security or tamper with, hack into or otherwise disrupt any computer system, server, website, router or any other Internet connected device).',
      {
        title: 'We reserve the right at any time, and without notice to you, to:',
        points: [
          'make changes or corrections and to alter, suspend or discontinue any aspect of the Materials and/or the Site;',
          'vary the technical specification of the Site;',
          'temporarily suspend your access to the Site for the purposes of maintenance or upgrade; and',
          'withdraw any Member Content or any of the Materials (or any part thereof).',
        ],
      },
    ],
  },

  {
    title: 'Intellectual Property',
    points: [
      'The contents of the Site and the Materials are protected by international copyright laws, database rights and other intellectual property rights. The owner of these rights is Informa, our affiliates or other third party licensors.',
      'All product and company names and logos contained within the Site and/or the Materials are the trademarks, service marks or trading names of their respective owners, including us. All of our rights which are not specifically granted to you by these Terms are reserved to us.',
    ],
  },

  {
    title: 'Disclaimers',
    points: [
      'We do not endorse any Materials published on the Site. Any Materials supplied by third parties are published in good faith but we do not (to the fullest extent permitted by applicable law) accept responsibility for the accuracy or otherwise of those Materials.',
      'Description or reference to any third party, product or publication on the Site does not imply endorsement of that third party, product or publication.',
      'We do not guarantee or warrant that any Materials available for downloading from the Site will be free from infections, viruses and/or other code that has contaminating or destructive properties. You are responsible for implementing sufficient procedures and virus checks to satisfy your particular requirements for the accuracy of data input and output.',
      'We are under no obligation to oversee, monitor or moderate any interactive service we provide on the Site and shall have no responsibility or liability relating to your use of any interactive service, whether the service is moderated or not.',
    ],
  },

  {
    title: 'Exclusions and Limitations',
    points: [
      'We exclude all express or implied terms, conditions, warranties, representations or endorsements whatsoever with regard to the Materials and the Site. All Materials are provided on an “as is” basis and you assume total responsibility and risk for your use of the Site and/or the Materials.',
      'A link to any other website does not mean that we endorse or accept any responsibility for the content, or the use of, such a website and we shall not be liable for any loss or damage caused or alleged to be caused by or in connection with use of or reliance on any content, goods or services available on or through any other website or resource.',
      'We accept no liability for any indirect or consequential loss or damage, or for any loss of data, profit, revenue or business (whether direct or indirect) in each case, however caused (inclusive of loss caused by or arising from any computer systems, hardware and software), even if foreseeable. In circumstances where you suffer loss or damage arising out of or in connection with the viewing, use or performance of the Site and/or any Materials, we accept no liability for this loss or damage whether due to inaccuracy, error, omission or any other cause and whether on our part or on the part of any other person or entity.',
      'If we are liable to you for any reason, subject to clause 12.5, our liability for any costs, claims, losses, damages, legal fees and other expenses will be limited to the amount of the Fees paid by you in relation to the then current Membership Period.',
      'The exclusions and limitations in this clause 12 do not affect your non-excludable statutory rights and only apply to the fullest extent permitted by applicable law. Nothing in these Terms excludes or limits our liability for (i) death or personal injury caused by our negligence, or (ii) fraud or fraudulent misrepresentation.',
      'Subject to clause 12.5, you release us, our officers, directors, employees, authors and Site content providers from any liability relating to your use of the Site and any Materials. You waive any claims for sustained losses or damages from or in connection with the use of the Site or any Materials to the fullest extent permitted by law.',
      'You acknowledge that, in light of the Fees, the above provisions of this clause are no more than is reasonable to protect the Supplier as the provider of the Membership.',
    ],
  },

  {
    title: 'Termination',
    points: [
      'Your Membership and access to the Membership Benefits will continue until the end of the Membership Period, but, without limitation, the Supplier may terminate or suspend your Membership and/or your Membership Benefits at any time if you are found in breach of any of these Terms, or you are convicted of any criminal offence or otherwise so conduct yourself as to bring yourself, the Site or the Supplier into disrepute, or if become insolvent, or in any jurisdiction, enter into or is subject to any form of winding up, administration, receivership, liquidation, bankruptcy, arrangement with creditors generally or any other insolvency procedure, or you suffer the enforcement of security or legal process or repossession, and upon termination of this contract for whatever reason, you shall not be entitled to any refund of any Fees in the event of such termination.',
      'For clarity, the Supplier may terminate your Membership at any time without notice to you if payment of Fees is not made by you in accordance with this contract and/or the Order Form.',
      'Termination shall not affect or prejudice the accrued rights of either you or Informa as at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination.',
    ],
  },

  {
    title: 'General',
    points: [
      'You may not assign, transfer or sub-license any of your rights under these Terms and/or the Order Form.',
      `To the fullest extent permitted by applicable law, the Supplier shall not liable for any delay or failure to perform any obligation owed to you in connection with your Membership if such delay or failure is due to circumstances beyond the Supplier's reasonable control.`,
      'No waiver by us of any breach of these Terms shall be considered as a waiver of any subsequent breach of these Terms.',
      'The invalidity, illegality or unenforceability of any provision of these Terms in whole or in part shall not prejudice the effectiveness of the rest of these Terms or the remainder of any part of a provision affected.',
      'These Terms, the Order Form and our privacy policy form the entire agreement between you and the Supplier. You acknowledge that you have not subscribed to your Membership in reliance upon any statement, warranty or representation made by the Supplier or any other person and you irrevocably and unconditionally waive any rights to claim damages and/or to rescind your Membership by reason of any misrepresentation (other than a fraudulent misrepresentation) that is not contained in these Terms or the Order Form.',
      'These Terms do not confer any rights on any person or party under the Contracts (Rights of Third Parties) Ordinance to enforce or enjoy the benefit of any provision of these Terms, other than you and us.',
      'In the event of any conflict between these Terms and the Order Form, these Terms shall prevail to the extent of any such inconsistency.',
      'These Terms shall be governed by and construed in all respects in accordance with the laws of India and you submit to the non-exclusive jurisdiction of the Indian courts for all purposes relating to these Terms or the Site.',
      'Notwithstanding any provisions in these Terms, the Supplier reserve the right to issue supplementary regulations or instructions in addition to these Terms and the Order Form, to the extent necessary to ensure smooth management of the Site. Any additional written regulations or instructions to be deemed to form part of these Terms. By continuing to use the Site, you agree to be bound by such additional written regulations or instructions.',
      'We may, in our sole discretion and at any time, without notice to you, add, amend or remove any Materials from the Site, or alter the presentation, substance, or functionality of the Site.',
    ],
  },
];

export default terms;
