import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TourProvider from './components/reactour/TourProvider';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import './styles/global.style.css';
import './styles/global.style.scss';

import 'react-circular-progressbar/dist/styles.css';

import { StyledChart } from './components/chart';
import Iconify from './components/iconify/Iconify';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
    return (
        <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <TourProvider>
                <Router />
            </TourProvider>
            <ToastContainer
                position="top-right"
                className={'toast-position'}
                autoClose={3000}
                closeButton={<Iconify icon={'material-symbols:close-rounded'} sx={{ color: 'red' }} />}
                // hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </ThemeProvider>
    );
}
