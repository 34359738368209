import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';

import { API_BASE, API_VER, S3BucketBaseUrl, defaultError } from '../../utils/constants';
import customToast from '../../utils/customToast';

// MergeImage.propTypes = {
//   logoX: PropTypes.number,
//   logoY: PropTypes.number,
//   scaleLogo: PropTypes.number,
//   // canvasRef: PropTypes.shape({
//   //   current: PropTypes.any,
//   // }),
//   bannersrc: PropTypes.string,
//   logosrc: PropTypes.string,
//   refresh: PropTypes.bool,
// };
// MergeImage.defaultProps = {
//   scaleLogo: 1,
// };

const MergeImage = forwardRef(({ logoX, logoY, scaleLogo, bannersrc, logosrc, refresh, test = false }, ref) => {
  const logoMaxWidth = 250;
  const bannerWidth = 1920;
  const bannerHeight = 480;

  useEffect(() => {
    doThings();
  }, [ref.current, refresh, logoX, logoY, scaleLogo]);

  const doThings = async () => {
    try {
      const logoData = test ? logosrc : await fetchImageFromBackend({ src: logosrc });
      const bannerData = test ? bannersrc : await fetchImageFromBackend({ src: bannersrc });

      if (ref.current && logoData && bannerData) {
        const canvas = ref.current;
        const ctx = canvas.getContext('2d');

        canvas.width = bannerWidth;
        canvas.height = bannerHeight;
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const bannerImg = new Image();
        const logoImg = new Image();

        bannerImg.onload = () => {
          const imageAspectRatio = bannerImg.width / bannerImg.height;
          const canvasAspectRatio = canvasWidth / canvasHeight;

          let renderWidth;
          let renderHeight;
          let xOffset = 0;
          let yOffset = 0;

          if (imageAspectRatio > canvasAspectRatio) {
            renderWidth = canvasWidth;
            renderHeight = canvasWidth / imageAspectRatio;
            yOffset = (canvasHeight - renderHeight) / 2;
          } else {
            renderWidth = canvasHeight * imageAspectRatio;
            renderHeight = canvasHeight;
            xOffset = (canvasWidth - renderWidth) / 2;
          }

          ctx.drawImage(bannerImg, xOffset, yOffset, renderWidth, renderHeight);

          logoImg.onload = () => {
            const logoAspectRatio = logoImg.width / logoImg.height;

            const scaleFactor = scaleLogo || 1;

            const maxLogoWidth = Math.min(logoImg.width, logoMaxWidth);
            const maxLogoHeight = maxLogoWidth / logoAspectRatio;

            const destWidth = maxLogoWidth * scaleFactor;
            const destHeight = maxLogoHeight * scaleFactor;

            const logoXCenter = logoX - destWidth / 2;
            const logoYCenter = logoY - destHeight / 2;

            ctx.drawImage(logoImg, logoXCenter, logoYCenter, destWidth, destHeight);
          };

          logoImg.src = logoData;
          logoImg.crossOrigin = 'anonymous';
        };

        bannerImg.src = bannerData;
        bannerImg.crossOrigin = 'anonymous';
      }
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
    }
  };

  const fetchImageFromBackend = async ({ src }) => {
    try {
      if (src.indexOf(S3BucketBaseUrl) === -1) return src;

      const data = await fetch(`${API_BASE}${API_VER}/json/proxy-image?url=${encodeURIComponent(src)}`, {
        responseType: 'arraybuffer',
      });
      if (!data) {
        return false;
      }

      const buffer = await data.arrayBuffer();
      const base64 = arrayBufferToBase64(buffer);
      const contentType = data.headers.get('content-type');
      const imageData = `data:${contentType};base64,${base64}`;

      return imageData;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i += 1) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = window.btoa(binary);
    return base64;
  };

  const removeImage = (ctx) => {
    ctx.clearRect(0, 0, bannerWidth, bannerHeight);
  };

  return <canvas ref={ref} style={{ height: '100%', width: '100%' }} />;
});

export default MergeImage;
