import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography } from '@mui/material';
// mock
import account from '../../../_mock/account';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import SvgColor from '../../../components/svg-color';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { visitorRouteName, showcaseRouteName, homePageVisitor, informaGreen } from '../../../utils/constants';

//
// import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const accountData = useLocalStorage('sessionInfo')?.json;
    const account = {
        displayName:
            accountData?.firstName && accountData?.lastName
                ? `${accountData?.firstName} ${accountData?.lastName}`
                : `${accountData?.profile?.fullName}`,
        email: accountData?.email,
        role: accountData?.role,
        photoURL: '/assets/images/avatars/avatar_default.jpg',
    };

    const { showcaseUnique: showcaseName } = useParams();

    const navConfig = [
        {
            title: 'Search Showcase',
            path: `/search/showcase`,
            icon: icon('ic_user'),
        },
        {
            title: 'Search Profile',
            path: `/search/u`,
            icon: icon('ic_cart'),
        },
    ];

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                <Link to={`${homePageVisitor}`} component={RouterLink} sx={{ display: 'contents' }}>
                    <Logo disabledLink />
                </Link>
            </Box>

            <Box sx={{ mb: 5, mx: 2.5 }}>
                <Link underline="none">
                    <StyledAccount>
                        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
                        {InitialIcon({
                            initials: (() => {
                                try {
                                    if (account.displayName && typeof account.displayName === 'string' && account.displayName.trim().length > 0) {
                                        return account.displayName
                                            .split(' ')
                                            .map((i) => i[0].toUpperCase())
                                            .join('');
                                    }
                                    return 'AC';
                                } catch (error) {
                                    return 'AC';
                                }
                            })(),
                        })}
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                {account.displayName}
                            </Typography>

                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {account.role}
                            </Typography>
                        </Box>
                    </StyledAccount>
                </Link>
            </Box>

            <NavSection data={navConfig} />

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    // anchor={'bottom'}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

InitialIcon.propTypes = {
    initials: PropTypes.string,
};

function InitialIcon({ initials }) {
    return (
        <div
            style={{
                // backgroundColor: '#dedede',
                // color: 'black',
                backgroundColor: informaGreen,
                color: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                borderRadius: 30,
                width: 40,
                height: 40,
            }}
        >
            <span style={{ fontSize: 18 }}>{initials}</span>
        </div>
    );
}
