import React from 'react';
import { InputLabel } from '@mui/material';
import Iconify from '../iconify/Iconify';
import useResponsive from '../../hooks/useResponsive';

export default function CommonAddButton({
  onClickParent,
  text,
  buttonType = true,
  customClass = '',
  fontClass = 'fs-5',
  buttonClass = '',
}) {
  const smUp = useResponsive('up', 'sm');

  return (
    <div
      className={`d-flex p-0 flex-column justify-content-center align-items-center border-custom custom-shadow bg-white min-h-add-btn ${customClass}`}
    >
      <button
        className={`button-cp button--wayra w-100 h-100 m-0 button--text-upper button--inverted ${buttonClass}`}
        type="button"
        onClick={onClickParent}
      >
        <div className="position-relative w-100 h-100 justify-content-center align-items-center d-flex flex-column">
          {buttonType && <Iconify icon="ant-design:plus-outlined" width={40} className="display-3 color-inherit" />}
          <InputLabel className={`color-inherit cursor-pointer ${fontClass}`}>{text}</InputLabel>
        </div>
      </button>
    </div>
  );
}
