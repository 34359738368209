import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, ListItemText } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
// mock
import account from '../../../_mock/account';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import SvgColor from '../../../components/svg-color';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { visitorRouteName, showcaseRouteName, userRouteName, homePageVisitor, defaultError, informaGreen } from '../../../utils/constants';
import { StyledNavItem, StyledNavItemIcon } from '../../../components/nav-section/styles';
import customToast from '../../../utils/customToast';
import apiMiddleWare from '../../../utils/useApiMiddleWare';

//
// import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'lg');
    const smUp = useResponsive('up', 'sm');

    const sessionInfo = useLocalStorage('sessionInfo');
    const roleData = sessionInfo?.roles;
    const accountData = sessionInfo?.json;
    const account = {
        displayName:
            accountData?.firstName && accountData?.lastName
                ? `${accountData?.firstName} ${accountData?.lastName}`
                : `${accountData?.profile?.fullName}`,
        email: accountData?.email,
        role: accountData?.role,
        photoURL: '/assets/images/avatars/avatar_default.jpg',
    };

    const { showcaseUnique: showcaseName } = useParams();

    const navConfig = [
        {
            title: 'Company Overview',
            path: `/${showcaseRouteName}/${showcaseName}/home`,
            // icon: icon('ic_user'),
            icon: icon('overview'),
        },
        {
            title: 'Products',
            path: `/${showcaseRouteName}/${showcaseName}/product`,
            icon: icon('ic_cart'),
        },
        {
            title: 'Contact Us',
            path: `/${showcaseRouteName}/${showcaseName}/contact`,
            icon: icon('ic_user'),
        },
    ];

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleClose = async (value = null) => {
        try {
            if (value === 'logout') {
                const res = await apiMiddleWare({
                    url: `/${roleData?.includes('visitor') ? 'visitors' : 'users'}/logout`,
                    type: 'post',
                });
                if (!res?.data?.ok) {
                    customToast({
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        type: 'error',
                    });
                    return;
                }

                secureLocalStorage.setItem('sessionInfo', {
                    roles: [],
                    json: {},
                    xAuth: '',
                });
                window.dispatchEvent(new Event('storage'));

                navigate(roleData?.includes('visitor') ? '/login' : '/user/login');
            } else if (value) {
                navigate(value);
            }
            // setOpen(null);
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        }
    };
    const renderContent = (
        // return
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            {smUp && (
                <>
                    <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                        <Link to={`${homePageVisitor}`} component={RouterLink} sx={{ display: 'contents' }}>
                            <Logo disabledLink />
                        </Link>
                    </Box>

                    {accountData && (
                        <Box sx={{ mb: 5, mx: 2.5 }}>
                            <Link underline="none">
                                <StyledAccount>
                                    {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
                                    {roleData?.length === 0 ? (
                                        <>
                                            {/* Not Logged In */}
                                            <Box className="cursor-pointer" sx={{ mx: 'auto' }}>
                                                <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                                                    Showcase
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            {/* Logged IN */}
                                            {InitialIcon({
                                                initials: (() => {
                                                    try {
                                                        if (
                                                            account.displayName &&
                                                            typeof account.displayName === 'string' &&
                                                            account.displayName.trim().length > 0
                                                        ) {
                                                            return account.displayName
                                                                .split(' ')
                                                                .map((i) => i[0].toUpperCase())
                                                                .join('');
                                                        }
                                                        return 'AC';
                                                    } catch (error) {
                                                        return 'AC';
                                                    }
                                                })(),
                                            })}

                                            <Box sx={{ ml: 2 }}>
                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                    {account.displayName}
                                                </Typography>

                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                    {account.role}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </StyledAccount>
                            </Link>
                        </Box>
                    )}
                </>
            )}

            <NavSection data={navConfig} />

            {roleData?.length !== 0 && (
                <Box onClick={() => handleClose('logout')}>
                    <StyledNavItem
                        sx={{
                            color: '#161521',
                            margin: '0 0.5rem',
                            '&.active': {
                                color: 'white',
                                bgcolor: informaGreen,
                                fontWeight: 'fontWeightBold',
                            },
                            '&:hover': {
                                color: '#0E924D',
                            },
                        }}
                    >
                        <StyledNavItemIcon>{icon('logout')}</StyledNavItemIcon>
                        <ListItemText disableTypography primary={'Logout'} />
                    </StyledNavItem>
                </Box>
            )}

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );
    // };

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    // anchor={'bottom'}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

InitialIcon.propTypes = {
    initials: PropTypes.string,
};

function InitialIcon({ initials }) {
    return (
        <div
            style={{
                // backgroundColor: '#dedede',
                // color: 'black',
                backgroundColor: informaGreen,
                color: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                borderRadius: 30,
                width: 40,
                height: 40,
            }}
        >
            <span style={{ fontSize: 18 }}>{initials}</span>
        </div>
    );
}
