import PropTypes from 'prop-types';
import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';

import Iconify from '../iconify';

WarningModal.propTypes = {
    open: PropTypes.bool.isRequired,
    sectionName: PropTypes.string.isRequired,
    missingFields: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleProceed: PropTypes.func.isRequired,
};

export default function WarningModal({ open, sectionName, missingFields, handleClose, handleProceed }) {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 5,
                    borderRadius: 2,
                }}
            >
                <Stack direction={'column'} spacing={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'warning.main',
                            fontSize: 60,
                        }}
                    >
                        <Iconify width={50} icon="bi:exclamation-triangle-fill" />
                    </Box>
                    <Typography variant="h6" textAlign={"center"} >
                        You still have following items pending in {sectionName}
                    </Typography>

                    {/* missing field  */}
                    <Grid container spacing={1}>
                        {missingFields?.map((field) => (
                            <Grid item md={6} key={field}>
                                <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                    <Typography variant="body1">•</Typography>
                                    <Typography variant="body1">{field}</Typography>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="body1" textAlign={"center"} sx={{ fontWeight: 'bold' }}>
                        Are you sure you want to proceed to the next section?
                    </Typography>
                    <Stack direction={'row'} justifyContent={'space-around'}>
                        <Button variant="contained" onClick={handleClose} sx={{ width: 130, color : "#212b36" }}>
                            No
                        </Button>
                        <Button variant="outlined" onClick={handleProceed} sx={{ width: 130, color : "#212b36" }}>
                            Yes
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}
