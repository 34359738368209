import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Link } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import useResponsive from '../../../hooks/useResponsive';
import { homePageVisitor } from '../../../utils/constants';
import Logo from '../../../components/logo';
import useLocalStorage from '../../../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  backgroundColor: '#f2f7ff',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `100% `,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const smUp = useResponsive('up', 'sm');
  const roleData = useLocalStorage('sessionInfo')?.roles;

  return (
    <StyledRoot>
      <StyledToolbar>
        {/* {smUp && (
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )} */}
        {/* {!smUp && ( */}
        <Box sx={{ py: 'auto', display: 'inline-flex' }}>
          <Link to={`${homePageVisitor}`} component={RouterLink} sx={{ display: 'contents' }}>
            <Logo disabledLink sx={{ height: smUp ? 45 : 30 }} />
          </Link>
        </Box>
        {/* )} */}

        {/* <Searchbar /> */}
        {roleData?.includes('visitor') && (
          <>
            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
            >
              <AccountPopover />
            </Stack>
          </>
        )}
      </StyledToolbar>
    </StyledRoot>
  );
}
