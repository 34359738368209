/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Container, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
// import { sentenceCase } from 'change-case';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import moment from 'moment';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// @mui
import {
  Card,
  Table,
  Avatar,
  Button,
  Paper,
  Typography,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Tabs,
  Tab,
  styled,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import { Form, InputGroup } from 'react-bootstrap';

import useLocalStorage from '../../../hooks/useLocalStorage';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { BASE_URL, defaultError, informaGreen, tableBgColor } from '../../../utils/constants';
import { handleDownload, openInNewTab, timeZeroFormat, useQuery } from '../../../utils/helper';
import Iconify from '../../../components/iconify/Iconify';
import useResponsive from '../../../hooks/useResponsive';

function UserProductQueries() {
  const query = useQuery();

  const showcaseRef = useLocalStorage('showcaseID');
  const filterDataLocal = useLocalStorage('filterData');
  const sID = filterDataLocal?.showcaseID;

  const smUp = useResponsive('up', 'sm');

  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState(false);

  const { showcaseList } = useOutletContext();

  const [identifierObj, setIdentifierObj] = useState({});
  const [filterData, setFilterData] = useState({
    showcaseList: [],
    showcaseID: filterDataLocal?.showcaseID || '',
    searchTime: filterDataLocal?.searchTime || 'last7',
    startDate: filterDataLocal?.startDate || '',
    endDate: filterDataLocal?.endDate || '',
  });
  const [storeData, setStoreData] = useState({
    showcase: { data: [], copy: [], searchV: '', called: false, loading: false },
    product: { data: [], copy: [], searchV: '', called: false, loading: false },
  });
  const [currentTab, setCurrentTab] = useState(query.get('tab') === 'product' ? 1 : 0);
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState({
    showcase: 0,
    product: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState({
    showcase: 5,
    product: 5,
  });
  const [totalDataCount, setTotalDataCount] = useState({
    showcase: 0,
    product: 0,
  });

  useEffect(() => {
    if (filterData?.searchTime === 'custom' && filterData?.startDate !== '' && filterData?.endDate !== '') {
      secureLocalStorage?.setItem('filterData', {
        showcaseID: filterData?.showcaseID,
        searchTime: 'custom',
        startDate: filterData?.startDate,
        endDate: filterData?.endDate,
      });
      window.dispatchEvent(new Event('storage'));
      calledObjectToFalse();
      setCallApi((prev) => !prev);
    }
  }, [filterData]);

  useEffect(() => {
    if (!storeData?.showcase?.called && currentTab === 0)
      getInitialData({ name: 'showcase', url: '/showcase/showcase-query' });

    if (!storeData?.product?.called && currentTab === 1)
      getInitialData({ name: 'product', url: '/showcase/product-query' });
  }, [currentTab, callApi, page, rowsPerPage]);

  useEffect(() => {
    if (!filterDataLocal || !filterDataLocal?.showcaseID) {
      setFilterDataFunc();
    }
  }, []);

  const setFilterDataFunc = () => {
    setFilterData((prev) => ({
      ...prev,
      showcaseList,
      showcaseID: prev?.showcaseID || showcaseList?.[0]?._id || '',
    }));
    secureLocalStorage?.setItem('filterData', {
      showcaseID: filterData?.showcaseID || showcaseList?.[0]?._id || '',
      searchTime: filterData?.searchTime,
      startDate: filterData?.startDate,
      endDate: filterData?.endDate,
    });
    window.dispatchEvent(new Event('storage'));
  };

  const getInitialData = async ({ name, url }) => {
    try {
      setIsLoading(true);
      const sendData = {
        showcaseRef: filterData?.showcaseID,
        startDate: filterData?.startDate && timeZeroFormat({ date: filterData?.startDate }),
        endDate: filterData?.endDate && timeZeroFormat({ date: filterData?.endDate, hour: '48' }),
        page: Number(page?.[name]) + 1,
        items: rowsPerPage?.[name],
        // search: storeData?.[name]?.searchV,
      };
      const res = await apiMiddleWare({
        type: 'post',
        url,
        data: sendData,
      });
      if (!res) {
        setIsLoading(false);
        return false;
      }
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }

      const dataHere = res?.data?.packet;
      setTotalDataCount((prev) => ({ ...prev, [name]: res?.data?.count }));
      setStoreData((prev) => ({
        ...prev,
        [name]: { loading: false, data: dataHere, copy: dataHere, called: true },
      }));
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const handleChangePage = (event, newPage, type) => {
    if (currentTab === 0) {
      setStoreData((prev) => ({
        ...prev,
        showcase: {
          ...prev.showcase,
          called: false,
        },
      }));
    } else {
      setStoreData((prev) => ({
        ...prev,
        product: {
          ...prev.product,
          called: false,
        },
      }));
    }
    setPage((prev) => ({
      ...prev,
      [type]: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event, type) => {
    if (currentTab === 0) {
      setStoreData((prev) => ({
        ...prev,
        showcase: {
          ...prev.showcase,
          called: false,
        },
      }));
    } else {
      setStoreData((prev) => ({
        ...prev,
        product: {
          ...prev.product,
          called: false,
        },
      }));
    }
    setPage((prev) => ({
      ...prev,
      [type]: 0,
    }));
    setRowsPerPage((prev) => ({
      ...prev,
      [type]: parseInt(event.target.value, 10),
    }));
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
      startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
      endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
    }));

    const showcaseVal = name === 'showcaseID' ? value : filterData?.showcaseID;
    const searchTimeVal = name === 'showcaseID' ? filterData?.searchTime : value;
    secureLocalStorage?.setItem('filterData', {
      showcaseID: showcaseVal,
      searchTime: searchTimeVal,
      startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
      endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
    });
    window.dispatchEvent(new Event('storage'));

    if (value !== 'custom') {
      calledObjectToFalse();
      setCallApi((prev) => !prev);
    }
  };

  const calledObjectToFalse = () => {
    setStoreData((prev) => ({
      ...prev,
      showcase: { ...prev?.showcase, called: false },
      product: { ...prev?.product, called: false },
    }));
  };

  function handleTabChange(e, newValue) {
    setCurrentTab(newValue);
  }

  const filterTable = (name) => (e) => {
    const searchValue = e.target.value?.toLowerCase();
    const originalData = storeData?.[name]?.copy;
    let filteredData = [];
    if (name === 'showcase') {
      filteredData = originalData.filter(
        (i) =>
          i?.visitorRef?.profile?.fullName?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.visitorRef?.profile?.company?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.topic?.toLowerCase()?.indexOf(searchValue) !== -1
      );
    } else if (name === 'product') {
      filteredData = originalData.filter(
        (i) =>
          i?.visitorRef?.profile?.fullName?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.visitorRef?.profile?.company?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.productRef?.title?.toLowerCase()?.indexOf(searchValue) !== -1 ||
          i?.topic?.toLowerCase()?.indexOf(searchValue) !== -1
      );
    }
    setStoreData((prev) => ({
      ...prev,
      [name]: {
        ...prev?.[name],
        searchV: searchValue,
        data: filteredData,
      },
    }));
  };

  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleDeleteQuerySwal = () => {
    handleCloseMenu();

    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      customClass: { container: 'zIndex-10000 ' },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteQuery();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const handleDeleteQuery = async () => {
    const { index, name } = open.dataset;
    try {
      setIsLoading(true);
      let callurl = '';

      if (name === 'showcase') {
        callurl = `/showcase/showcase-query/delete/${storeData?.showcase?.data?.[index]?._id}`;
      } else if (name === 'product') {
        callurl = `/showcase/product-query/delete/${storeData?.product?.data?.[index]?._id}`;
      }

      const res = await apiMiddleWare({
        type: 'delete',
        url: callurl,
      });

      if (!res) {
        setIsLoading(false);
        return false;
      }
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }

      customToast({
        msg: `Successfully Deleted ${name === 'showcase' ? 'Showcase' : 'Product'} Query` || defaultError,
        type: 'success',
      });
      setIsLoading(false);
      handleCloseMenu();
      calledObjectToFalse();
      setCallApi((prev) => !prev);
      return true;
    } catch (err) {
      handleCloseMenu();
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const getIdentifierName = async (sid) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/get-identifier/${sid}` });

      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      const dataHere = res?.data?.packet;
      setIdentifierObj((prev) => ({
        ...prev,
        [sid]: dataHere?.identifier,
      }));
      return dataHere?.identifier;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const getDownloadData = async ({ apiUrl, apiType = 'get', apiBody = {} }) => {
    const res = await apiMiddleWare({ type: apiType, url: apiUrl, body: apiBody });
    if (!res?.data?.ok) {
      throw new Error(res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError);
    }
    return res?.data?.packet;
  };

  const callDownload = async (type) => {
    try {
      const data = await getDownloadData({ apiUrl: `/download/${type}-query/${sID}` });
      if (data.length === 0) {
        customToast({ msg: 'No Data Found', type: 'error' });
        return false;
      }
      customToast( { type:'success', msg : 'Download will start in few seconds'})

      let dataOrder;
      if (type === 'product') {
        dataOrder = [
          'index',
          'name',
          'email',
          'designation',
          'company',
          'phone',
          'productName',
          'topic',
          'description',
        ];
      } else if (type === 'showcase') {
        dataOrder = ['index', 'name', 'email', 'designation', 'company', 'phone', 'topic', 'description'];
      }
      const dataObj = {
        index: { label: '#', valueLocation: 'index' },
        name: { label: 'Name', valueLocation: 'visitorRef.profile.fullName' },
        email: { label: 'Email', valueLocation: 'visitorRef.email' },
        designation: { label: 'Designation', valueLocation: 'visitorRef.profile.designation' },
        company: { label: 'Company', valueLocation: 'visitorRef.profile.company' },
        phone: { label: 'Phone', valueLocation: 'visitorRef.profile.phone' },
        topic: { label: 'Topic', valueLocation: 'topic' },
        description: { label: 'Description', valueLocation: 'query' },
        productName: { label: 'Product Name', valueLocation: 'productRef.title' },
      };

      await handleDownload({ fileName: `${type}-query`, dataOrder, dataObj, data });

      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const onClickProduct = async (productID) => {
    const identifier = identifierObj[sID] || (await getIdentifierName(sID));
    openInNewTab(`/showcase/${identifier}/product/${productID}`)();
  };

  const TableHeadCustom = styled(TableHead)({
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: informaGreen,
    },
  });

  const tableCellStyle = {
    padding: '6px 14px',
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'white',
      borderRadius: '7px',
    },
  };

  return (
    <>
      <Container maxWidth="xl" className="mt-4 mt-md-0">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Queries
          </Typography>
        </Stack> */}

        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3} sx={{ mb: '2rem' }}>
              <Grid item xs={12} sm={6} md={3} className='d-none'>
                <TextField
                  fullWidth
                  name="showcaseID"
                  id="outlined-select-currency"
                  select
                  label="Showcase"
                  value={filterData?.showcaseID}
                  onChange={onChangeFilter}
                >
                  {showcaseList?.length > 0 &&
                    showcaseList?.map((i) => (
                      <MenuItem key={i?._id} value={i?._id}>
                        {i?.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  name="searchTime"
                  id="outlined-select-currency"
                  select
                  label="Search Time"
                  value={filterData?.searchTime}
                  onChange={onChangeFilter}
                  required
                >
                  <MenuItem key={'last7'} value={'last7'}>
                    Last 7 Days
                  </MenuItem>
                  <MenuItem key={'last30'} value={'last30'}>
                    Last 30 Days
                  </MenuItem>
                  <MenuItem key={'custom'} value={'custom'}>
                    Custom
                  </MenuItem>
                </TextField>
              </Grid>
              {filterData?.searchTime === 'custom' && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <DatePicker
                      disableFuture
                      label="Start Date"
                      openTo="day"
                      views={['year', 'month', 'day']}
                      value={filterData?.startDate}
                      onChange={(newValue) => {
                        setFilterData((prev) => ({
                          ...prev,
                          startDate: newValue,
                        }));
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DatePicker
                      disableFuture
                      label="End Date"
                      openTo="day"
                      minDate={filterData?.startDate || dayjs()}
                      views={['year', 'month', 'day']}
                      value={filterData?.endDate}
                      onChange={(newValue) => {
                        setFilterData((prev) => ({
                          ...prev,
                          endDate: newValue,
                        }));
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </LocalizationProvider>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Tabs value={currentTab} onChange={(e, newV) => handleTabChange(e, newV)} aria-label="Analytics Tabs">
                <Tab label="Company" />
                <Tab label="Product" />
              </Tabs>
            </Box>
            {isLoading ? (
              <>Loading...</>
            ) : (
              <>
                <div
                  role="tabpanel"
                  hidden={currentTab !== 0}
                  id={`simple-tabpanel-${0}`}
                  aria-labelledby={`simple-tab-${0}`}
                >
                  {/* <div className="d-flex mb-2 ">
                      <TextField
                        // fullWidth
                        className="ms-auto"
                        placeholder="Search"
                        value={}
                        onChange={}
                      />
                    </div> */}
                  <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                    <Form className="col-12 col-md-5 my-3 ">
                      <InputGroup>
                        <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                          <Iconify icon="ic:outline-search" />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-0 shadow-none"
                          placeholder="Search by Name, Company, Topic"
                          aria-label="Search"
                          value={storeData?.showcase?.searchV}
                          onChange={filterTable('showcase')}
                        />
                      </InputGroup>
                    </Form>
                    <div className="col-md-2 my-3">
                      <Button variant="contained" onClick={() => callDownload('showcase')} className=" w-100">
                        Download Excel
                      </Button>
                    </div>
                  </Card>
                  <Card>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                        <TableHeadCustom>
                          <TableRow>
                            <TableCell align="left">Index</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Designation</TableCell>
                            <TableCell align="left">Company</TableCell>
                            <TableCell align="left">Phone</TableCell>
                            <TableCell align="left">Topic</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </TableRow>
                        </TableHeadCustom>
                        <TableBody>
                          {storeData?.showcase?.data?.length > 0 &&
                            storeData?.showcase?.data?.map((row, index) => (
                              <TableRow
                                key={row._id}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  '&:nth-child(odd)': {
                                    backgroundColor: tableBgColor,
                                  },
                                  '&:nth-child(even)': {
                                    backgroundColor: 'white',
                                  },
                                }}
                              >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.profile?.fullName} placement="bottom-start">
                                    <div className="ellipse-text w-200px">
                                      {row?.visitorRef?.profile?.fullName || ''}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.email} placement="bottom-start">
                                    <div className="ellipse-text w-200px">{row?.visitorRef?.email || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.profile?.designation} placement="bottom-start">
                                    <div className="ellipse-text w-200px">
                                      {row?.visitorRef?.profile?.designation || ''}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.profile?.company} placement="bottom-start">
                                    <div className="ellipse-text w-200px">
                                      {row?.visitorRef?.profile?.company || ''}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.profile?.phone} placement="bottom-start">
                                    <div className="ellipse-text w-200px">{row?.visitorRef?.profile?.phone || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.topic} placement="bottom-start">
                                    <div className="ellipse-text w-200px">{row?.topic || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.query} placement="bottom-start">
                                    <div className="ellipse-text w-315px">{row?.query || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    size="medium"
                                    color="inherit"
                                    onClick={handleOpenMenu}
                                    data-name="showcase"
                                    data-index={index}
                                  >
                                    <Iconify icon={'eva:more-vertical-fill'} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          {storeData?.showcase?.data?.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                No Data Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalDataCount?.showcase}
                      rowsPerPage={rowsPerPage?.showcase}
                      page={page?.showcase}
                      onPageChange={(e, newPage) => handleChangePage(e, newPage, 'showcase')}
                      onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, 'showcase')}
                    />
                  </Card>
                </div>
                <div
                  role="tabpanel"
                  hidden={currentTab !== 1}
                  id={`simple-tabpanel-${1}`}
                  aria-labelledby={`simple-tab-${1}`}
                >
                  <Card className="bg-transparent bordre-0 shadow-none row justify-content-between">
                    <Form className="col-md-5 col-12 my-3 ">
                      <InputGroup>
                        <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                          <Iconify icon="ic:outline-search" />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-0 shadow-none"
                          placeholder="Search by Name, Company, Product, Topic"
                          aria-label="Search"
                          value={storeData?.product?.searchV}
                          onChange={filterTable('product')}
                        />
                      </InputGroup>
                    </Form>
                    <div className="col-md-2 my-3">
                      <Button variant="contained" onClick={() => callDownload('product')} className="w-100">
                        Download Excel
                      </Button>
                    </div>
                  </Card>
                  <Card>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: smUp ? 650 : 'auto' }} aria-label="simple table">
                        <TableHeadCustom>
                          <TableRow>
                            <TableCell align="left">Index</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Designation</TableCell>
                            <TableCell align="left">Company</TableCell>
                            <TableCell align="left">Phone</TableCell>
                            <TableCell align="left">Product Name</TableCell>
                            <TableCell align="left">Topic</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </TableRow>
                        </TableHeadCustom>
                        <TableBody>
                          {storeData?.product?.data?.length > 0 &&
                            storeData?.product?.data?.map((row, index) => (
                              <TableRow
                                key={row._id}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  '&:nth-child(odd)': {
                                    backgroundColor: tableBgColor,
                                  },
                                  '&:nth-child(even)': {
                                    backgroundColor: 'white',
                                  },
                                }}
                              >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer w-200px">
                                  <Tooltip title={row?.visitorRef?.profile?.fullName}>
                                    <div className="ellipse-text ">{row?.visitorRef?.profile?.fullName || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.email} placement="bottom-start">
                                    <div className="ellipse-text w-200px">{row?.visitorRef?.email || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.profile?.designation} placement="bottom-start">
                                    <div className="ellipse-text w-200px">
                                      {row?.visitorRef?.profile?.designation || ''}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer w-200px">
                                  <Tooltip title={row?.visitorRef?.profile?.company}>
                                    <div className="ellipse-text">{row?.visitorRef?.profile?.company || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer">
                                  <Tooltip title={row?.visitorRef?.profile?.phone} placement="bottom-start">
                                    <div className="ellipse-text w-200px">{row?.visitorRef?.profile?.phone || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer w-180px">
                                  <Tooltip title={row?.productRef?.title}>
                                    <div className="ellipse-text ">
                                      <span
                                        className="cursor-pointer me-2"
                                        onClick={() => onClickProduct(row?.productRef?._id)}
                                      >
                                        <Iconify icon="mdi:link-variant" sx={{ color: '#3f51b5' }} />
                                      </span>
                                      <span>{row?.productRef?.title || ''}</span>
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer w-200px">
                                  <Tooltip title={row?.topic}>
                                    <div className="ellipse-text ">{row?.topic || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={tableCellStyle} align="left" className="cursor-pointer w-200px">
                                  <Tooltip title={row?.query}>
                                    <div className="ellipse-text ">{row?.query || ''}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    size="medium"
                                    color="inherit"
                                    onClick={handleOpenMenu}
                                    data-name="product"
                                    data-index={index}
                                  >
                                    <Iconify icon={'eva:more-vertical-fill'} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          {storeData?.product?.data?.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                                No Data Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalDataCount?.product}
                      rowsPerPage={rowsPerPage?.product}
                      page={page?.product}
                      onPageChange={(e, newPage) => handleChangePage(e, newPage, 'product')}
                      onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, 'product')}
                    />
                  </Card>
                </div>
              </>
            )}
          </Box>
        </>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/*  <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> */}

        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteQuerySwal}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserProductQueries;
