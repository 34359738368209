// @mui
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { alpha, styled } from '@mui/material/styles';
import { Card, Tooltip, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: '1rem 1.5rem',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: 'white',
  boxShadow: 'inset 3px 3px 6px rgba(213, 221, 248, 0.22), inset -3px -3px 6px rgba(44, 44, 81, 0.22)'
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  iconBG: PropTypes.string,
  gotoPage: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired || PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', iconBG, sx, gotoPage, ...other }) {
  const navigate = useNavigate();
  const mdDown = useResponsive('down', 'md');
  return (
    <Card
      sx={{
        py: 1,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: 'white',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        borderRadius: '6px',
        display: 'flex',
        cursor: 'pointer',
        ...sx,
      }}
      {...other}
      onClick={() => gotoPage && navigate(gotoPage)}
    >
      <StyledIcon
        sx={{
          backgroundColor: iconBG
          // color: (theme) => theme.palette[color].dark,
          // backgroundImage: (theme) =>
          //   `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
          //     theme.palette[color].dark,
          //     0.24
          //   )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={36} height={36} />
      </StyledIcon>

      <div className="my-auto ">
        <Typography variant="subtitle2" className="text-start " sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
        <Typography variant={(total.trim().length > 5) ? "h6": 'h4'} className={`text-start ${mdDown ? ' w-200px' : 'w-200px'}`}>
          <Tooltip title={total} placement="bottom-start" className="cursor-pointer ">
            <div className="ellipse-text">{Number.isNaN(total) ? fShortenNumber(total) : total}</div>
          </Tooltip>
        </Typography>
      </div>
    </Card>
  );
}
