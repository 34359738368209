import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  Container,
  InputLabel,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import Iconify from '../../../components/iconify/Iconify';
import CommonModal from '../../../components/modal/CommonModal';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { S3BucketBaseUrl, defaultError } from '../../../utils/constants';
import customToast from '../../../utils/customToast';

const commonBoxStyles = {
  bgcolor: '#fff',
  borderColor: 'transparent',
  '&::placeholder': {
    color: '#707070',
  },
  p: 2,
  // border: 1,
  width: '100%',
  minHeight: '5rem',
};
function UserConnectEvent() {
  const navigate = useNavigate();
  const showcaseRef = useLocalStorage('showcaseID');
  const filterDataLocal = useLocalStorage('filterData');
  const sID = filterDataLocal?.showcaseID;

  // const { showcaseList } = useOutletContext();
  const [ showcaseList , setShowcaseList ] = useState([])

  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const [allData, setAllData] = useState({ list: [], count: 0 });
  const [connectModal, setConnectModal] = useState({
    open: false,
    data: {
      eventID: '',
      eventName: '',
      showcaseList: showcaseList || [],
      showcaseID: '',
    },
  });

  const timeout = useRef();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getInitialList();
    getShowcaseData();
  }, [page, rowsPerPage, callApi]);

  const getInitialList = async () => {
    try {
      setIsLoading(true);
      const sendData = {
        // showcaseRef: sID,
        page: Number(page) + 1,
        items: rowsPerPage,
        search: searchValue,
      };
      const res = await apiMiddleWare({
        type: 'post',
        url: '/showcase/get-event-request',
        data: sendData,
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      const resData = res?.data?.packet;
      setAllData((prev) => ({
        ...prev,
        list: resData?.list,
        count: resData?.count,
      }));
      if (searchValue === '')
        resData?.list?.forEach((i) => {
          checkEventStatus({ eventURL: i?.metaData?.eventURL, status: i?.metaData?.eventStatus, showcaseRef: i?._id });
        });

      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  // const callShowcaseList = async () => {
  //   try {
  //     const data = await apiMiddleWare({ type: 'get', url: '/showcase/list-all' });
  //     if (!data) {
  //       return false;
  //     }
  //     if (data?.data?.ok) {
  //       const allPacket = data?.data?.packet;
  //       setShowcaseList(allPacket);
  //       return true;
  //     }

  //     return false;
  //   } catch (err) {
  //     customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
  //     return false;
  //   }
  // };

  const getShowcaseData = async () => {
    try {
      setIsLoading(true);
      const res = await apiMiddleWare({
        type: 'get',
        url: '/showcase/list-all',
      });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      const resData = res?.data?.packet;
      setConnectModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          showcaseList: resData,
        },
      }));

      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const openModal = () => {
    setConnectModal((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const closeModal = () => {
    setConnectModal((prev) => ({
      ...prev,
      open: false,
      data: {
        ...prev.data,
        eventID: '',
        showcaseID: '',
      },
    }));
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setConnectModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  const onSubmitEventID = async () => {
    if (connectModal?.data?.eventID === '') {
      customToast({
        msg: 'Write Event ID !!!',
        type: 'error',
      });
      return false;
    }
    if (connectModal?.data?.showcaseID === '') {
      customToast({
        msg: 'Select Showcase ID !!!',
        type: 'error',
      });
      return false;
    }
    if (!connectModal?.data?.eventID.match(/^[^\s/$.?#\\]+.[^\s]+\/[\w\-\d]+$/gi)) {
      customToast({
        msg: 'Invalid Event ID !!!',
        type: 'error',
      });
      return false;
    }
    try {
      const sendData = {
        eventID: connectModal?.data?.eventID,
        showcaseRef: connectModal?.data?.showcaseID,
      };
      const res = await apiMiddleWare({ url: '/showcase/event-request', type: 'post', data: sendData });
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }
      customToast({
        msg: 'Request sent to the REI Connect Team',
        type: 'success',
      });
      closeModal();
      setCallApi((prev) => !prev);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const checkEventStatus = async ({ eventURL: url, id, status: prevStatus, showcaseRef }) => {
    try {
      const sendData = {
        eventURL: url,
        showcaseRef,
      };
      const res = await apiMiddleWare({ url: '/showcase/check-event-request', type: 'post', data: sendData });

      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }
      const currentStatus = res?.data?.packet;
      if (prevStatus !== currentStatus) {
        setCallApi((prev) => !prev);
        // setAllData((prev) => ({
        //   ...prev,
        //   list: prev?.list?.map((i) => {
        //     const updateData = i;
        //     if (updateData._id === id) {
        //       updateData.metaData.eventStatus = currentStatus;
        //     }
        //     return { ...updateData } ;
        //   }),
        // }));
      }
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const exploreButton = async (item) => {
    navigate('/user/dashboard/connect-event/product', { state: item });
  };

  const handleSearchDebounce = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      setCallApi((prev) => !prev);
    }, 1500);
  };

  return (
    <>
      <CommonModal
        title={`Connect With Event`}
        open={connectModal?.open}
        size={'sm'}
        onClose={closeModal}
        noTitleLine
        noFooterLine
        body={
          <div className="row m-1 py-2">
            <div className="col-md-4 mb-3">
              <InputLabel className="my-auto d-flex" style={{ lineHeight: '3.4rem' }}>
                Event ID
              </InputLabel>
            </div>
            <div className="col-md-8 mb-3">
              <TextField
                className="fullWidth-responsive"
                fullWidth
                name="eventID"
                value={connectModal?.data?.eventID}
                onChange={handleOnChange}
                // inputProps={{ maxLength: 50 }}
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <InputLabel className="my-auto d-flex" style={{ lineHeight: '3.4rem' }}>
                Showcase
              </InputLabel>
            </div>
            <div className="col-md-8 mb-3">
              <TextField
                fullWidth
                name="showcaseID"
                id="outlined-select-currency"
                select
                value={connectModal?.data?.showcaseID}
                onChange={handleOnChange}
                required
              >
                {connectModal?.data?.showcaseList?.length > 0 &&
                  connectModal?.data?.showcaseList?.map((i) => (
                    <MenuItem key={i?._id} value={i?._id}>
                      {i?.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </div>
        }
        customFooter={
          <div className="w-100 d-flex justify-content-center">
            <Button type="button" variant="contained" color="primary" disabled={isLoading} onClick={onSubmitEventID}>
              Submit
            </Button>
          </div>
        }
      />
      {isLoading ? (
        <>Loading</>
      ) : (
        <Container maxWidth="xl" className="">
          <div className="d-flex justify-content-between">
            <Button className="my-auto" type="button" onClick={openModal} disabled={isLoading} variant="contained">
              Connect
            </Button>
            <Form className=" ">
              <InputGroup>
                <InputGroup.Text className="bg-white border-0 pe-0 me-0 shadow-none">
                  <Iconify icon="ic:outline-search" />
                </InputGroup.Text>
                <Form.Control
                  className="border-0 shadow-none"
                  placeholder="Search By Event Name"
                  aria-label="Search"
                  // name="eventName"
                  value={searchValue}
                  onChange={handleSearchDebounce}
                />
              </InputGroup>
            </Form>
          </div>
          <div>
            <Card sx={{ mt: 3, pt: 3 }}>
              <Box sx={{ ...commonBoxStyles }} className="event_grid">
                {allData?.count > 0 &&
                  allData?.list?.map((i, index) => (
                    <div key={i?.eventID} className="event_box ">
                      <img src={`${S3BucketBaseUrl}${i?.logo}`} alt="" />
                      <div className="d-flex flex-column">
                        <div className="fs-6 fw-bolder mt-2 d-flex">
                          <Tooltip
                            title={`Showcase Name - ${i?.name}`}
                            placement="bottom-start"
                            className="cursor-pointer"
                          >
                            <span className="ellipse-text">{i?.name}</span>
                          </Tooltip>
                          <span>
                            <Tooltip title={i?.metaData?.eventStatus} placement="bottom" className="cursor-pointer">
                              <Iconify
                                width={24}
                                icon="octicon:dot-fill-16"
                                style={{
                                  color:
                                    i?.metaData?.eventStatus === 'Pending'
                                      ? 'yellow'
                                      : i?.metaData?.eventStatus === 'Accepted'
                                      ? 'green'
                                      : 'red',
                                }}
                              />
                            </Tooltip>
                          </span>
                        </div>
                        {i?.metaData?.eventURL && (
                          <div className="fs-6 fw-bolder mt-2">
                            <Tooltip
                              title={`Event URL - ${i?.metaData?.eventURL}`}
                              placement="bottom-start"
                              className="cursor-pointer"
                            >
                              <span className="ellipse-text">{i?.metaData?.eventURL}</span>
                            </Tooltip>
                          </div>
                        )}
                        {/* <span className="fw-lighter mt-2">16th Feb, 2023</span> */}
                        <div className="mt-3">
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() => exploreButton(i)}
                            disabled={isLoading}
                            sx={{ width: '100%' }}
                          >
                            Explore Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
              </Box>
              <TablePagination
                rowsPerPageOptions={[6, 12, 24]}
                component="div"
                count={allData?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        </Container>
      )}{' '}
    </>
  );
}

export default UserConnectEvent;
