import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import useLocalStorage from '../../hooks/useLocalStorage';
import { informaGreen, userRouteName } from '../../utils/constants';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], mobile, ...other }) {
  return (
    <Box
      {...other}
      sx={{
        flexGrow: mobile ? '1' : '0',
        borderRadius: mobile ? '16px 16px 0 0 ' : '10px',
        overFlow: 'hidden',
        backgroundColor: mobile ? 'black' : 'inherit',
        color: mobile ? 'inherit' : 'black',
      }}
    >
      <List disablePadding sx={{ p: mobile ? 0 : 1, display: mobile ? 'inline-flex' : '', width: '100%' }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} mobile={mobile} tour={item?.tour} />
          ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, mobile }) {
  const { title, path, icon, info, tour } = item;
  // const sID = useLocalStorage('showcaseID');

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      // disabled={
      //   (path === `/${userRouteName}/showcase/resource` ||
      //     path === `/${userRouteName}/showcase/product` ||
      //     path === `/${userRouteName}/showcase/contact` ||
      //     path === `/${userRouteName}/showcase/share`) &&
      //   !sID
      // }
      sx={{
        color: mobile ? 'white' : '#161521',
        // bgcolor: mobile ? '#161521' : '#f2f7ff',
        flexDirection: mobile ? 'column' : 'row',
        flex: mobile ? '1 1 0' : '',
        height: 'auto',
        borderRadius: mobile ? '16px 16px 0 0 ' : '8px',
        paddingBottom: mobile ? '0' : 'auto',
        '&.active': {
          color: 'white',
          bgcolor: informaGreen,
          fontWeight: 'fontWeightBold',
        },
        '&:hover': {
          color: '#0E924D',
        },
      }}
      data-tour={tour || ''}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={title}
        sx={{ textAlign: mobile ? 'center' : 'left', fontSize: '14px' }}
      />

      {info && info}
    </StyledNavItem>
  );
}
