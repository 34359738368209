/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box, Button, Container, TextField } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import Tooltip from '@mui/material/Tooltip';
import QRCode from 'qrcode';

import Iconify from '../../../components/iconify';
import { defaultError, BASE_URL, userRouteName } from '../../../utils/constants';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { copyText, openInNewTab } from '../../../utils/helper';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useResponsive from '../../../hooks/useResponsive';

function UserShare() {
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');

  const sID = useLocalStorage('showcaseID');
  if (!sID) {
    navigate(`/${userRouteName}/showcase/profile`);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const [url, setUrl] = useState('');
  const [imgData, setImgData] = useState('');

  useEffect(() => {
    getIdentifierName();
    createQR(`${BASE_URL}/qr/showcase/${sID}`);
  }, []);

  const getIdentifierName = async () => {
    try {
      setIsLoading(true);
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/get-identifier/${sID}` });
      if (!res) {
        setIsLoading(false);
        return false;
      }
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }
      const dataHere = res?.data?.packet;
      setIdentifier(dataHere?.identifier);
      setUrl(`${BASE_URL}/showcase/${dataHere?.identifier}`);

      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const createQR = (url1) => {
    QRCode?.toDataURL(
      url1,
      {
        width: 300,
        color: {
          dark: '#252525',
          light: '#fff',
        },
      },
      (err, url2) => {
        setImgData(url2);
      }
    );
  };

  return isLoading ? (
    <>Loading...</>
  ) : (
    <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="contact-page">
      {/* <Typography variant="h3" sx={{ mb: 5 }}>
        Showcase Share
      </Typography> */}
      <Box sx={{ maxWidth: '100%' }}>
        {identifier && imgData && url && (
          <div className="d-flex flex-column">
            <div className="mx-auto d-flex mb-2 flex-wrap">
              <div className="mx-auto mb-3">
                <TextField
                  className="mx-2 "
                  sx={{ width: 'auto', minWidth: '320px', maxWidth: '550px' }}
                  value={url}
                  disabled
                />
              </div>
              <div className="mx-auto mb-3">
                <Button variant="contained" className="display-5 mx-2 h-100" onClick={openInNewTab(url)}>
                  <Tooltip title={'Open Link'}>
                    <Iconify icon="mdi:link-variant" />
                  </Tooltip>
                </Button>
                <Button
                  variant="contained"
                  className="display-5 mx-2 h-100"
                  onClick={() => copyText(url, 'Link Copied')}
                >
                  <Tooltip title={'Copy Link'}>
                    <Iconify icon="material-symbols:content-copy-outline-rounded" />
                  </Tooltip>
                </Button>
              </div>
            </div>
            <div className="mx-auto border-custom p-1 mb-2">
              <img src={imgData} alt="QR CODE" width={250} />
            </div>
            <div className="mx-auto">
              <Button variant="contained" href={imgData} download={`${identifier || 'unnamed'}.png`}>
                <span>Download QR Code</span>
              </Button>
            </div>
            <div className="mx-auto d-flex gap-2 display-6 flex-wrap d-flex justify-content-center mt-4 mt-md-3">
              <div
                className="circle-icon"
                onClick={() =>
                  copyText(
                    `<div style="height: 100vh;width:100vw;"> <!-- Change dimensions according to your need -->
        <iframe id="samaaro-showcase" style="height: 100%;width:100%;margin: 0 auto ; overflow: auto;"src="${BASE_URL}/qr/showcase/${sID}" frameborder="0"> </iframe>
</div>`,
                    'Link Copied'
                  )
                }
              >
                <Tooltip title={'Copy Embeded Code'}>
                  <Iconify icon="fluent-mdl2:embed" width="10" />
                </Tooltip>
              </div>
              <div className="circle-icon" onClick={openInNewTab(`https://twitter.com/intent/tweet?url=${url}`)}>
                <Tooltip title={'Share in Twitter'}>
                  <Iconify icon="logos:twitter" width="10" />
                </Tooltip>
              </div>

              <div
                className="circle-icon"
                onClick={openInNewTab(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`)}
              >
                <Tooltip title={'Share in Linkedin'}>
                  <Iconify icon="logos:linkedin-icon" width="10" />
                </Tooltip>
              </div>

              <div className="circle-icon" onClick={openInNewTab(`https://www.facebook.com/sharer.php?u=${url}`)}>
                <Tooltip title={'Share in Facebook'}>
                  <Iconify icon="ic:baseline-facebook" width="10" />
                </Tooltip>
              </div>

              <div className="circle-icon" onClick={openInNewTab(`https://web.whatsapp.com/send?text=${url}`)}>
                <Tooltip title={'Share in Whatsapp'}>
                  <Iconify icon="ic:baseline-whatsapp" width="10" />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
}

export default UserShare;
