import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
// @mui
import { Button, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import secureLocalStorage from 'react-secure-storage';

// sections
import CommonModal from '../../../components/modal/CommonModal';
import useIsInViewport from '../../../hooks/useIsInViewport';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary } from '../../../sections/@dashboard/app';
import { defaultError, userRouteName } from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import { fShortenNumber } from '../../../utils/formatNumber';
import { timeZeroFormat } from '../../../utils/helper';
import apiMiddleWare from '../../../utils/useApiMiddleWare';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
    const theme = useTheme();

    const navigate = useNavigate();
    const location = useLocation();
    const sID = useLocalStorage('showcaseID');
    const filterDataLocal = useLocalStorage('filterData');

    const [isLoading, setIsLoading] = useState(false);
    const [modalData, setModalData] = useState({
        open: false,
    });
    const [showcaseID, setShowcaseID] = useState('');
    const [companyRef, setCompanyRef] = useState('');
    const [callApi, setCallApi] = useState(false);
    const [filterData, setFilterData] = useState({
        showcaseList: [],
        showcaseID: filterDataLocal?.showcaseID || '',
        searchTime: filterDataLocal?.searchTime || 'last7',
        startDate: filterDataLocal?.startDate || '',
        endDate: filterDataLocal?.endDate || '',
    });
    const [allData, setAllData] = useState({
        noOfLeads: { loading: true, data: '', called: false },
        noOfMeetingSch: { loading: true, data: '', called: false },
        totalShowcaseQ: { loading: true, data: '', called: false },
        totalProductQ: { loading: true, data: '', called: false },
        mostInquiredP: { loading: true, data: '', called: false },
        mostViewP: { loading: true, data: '', called: false },
        mostPopularV: { loading: true, data: '', called: false },
        mostPopularD: { loading: true, data: '', called: false },
        avgSession: { loading: true, data: '', called: false },
    });
    const { showcaseList } = useOutletContext();

    const noOfLeadsRef = useRef(null);
    const noOfMeetingSchRef = useRef(null);
    const totalShowcaseQRef = useRef(null);
    const totalProductQRef = useRef(null);
    const mostInquiredPRef = useRef(null);
    const mostViewPRef = useRef(null);
    const mostPopularVRef = useRef(null);
    const mostPopularDRef = useRef(null);
    const avgSessionRef = useRef(null);

    const noOfLeadsView = useIsInViewport(noOfLeadsRef);
    const noOfMeetingSchView = useIsInViewport(noOfMeetingSchRef);
    const totalShowcaseQView = useIsInViewport(totalShowcaseQRef);
    const totalProductQView = useIsInViewport(totalProductQRef);
    const mostInquiredPView = useIsInViewport(mostInquiredPRef);
    const mostViewPView = useIsInViewport(mostViewPRef);
    const mostPopularVView = useIsInViewport(mostPopularVRef);
    const mostPopularDView = useIsInViewport(mostPopularDRef);
    const avgSessionView = useIsInViewport(avgSessionRef);

    useEffect(() => {
        if (noOfLeadsView && !allData?.noOfLeads?.called && showcaseID)
            getCommonDataFunc({ scID: showcaseID, name: 'noOfLeads', url: '/dashboard/leads' });
    }, [noOfLeadsView, showcaseID, callApi]);

    useEffect(() => {
        if (noOfMeetingSchView && !allData?.noOfMeetingSch?.called && showcaseID) {
            // getCommonDataFunc({ scID: showcaseID, name: 'noOfMeetingSch', url: '/dashboard/leads' });
        }
    }, [noOfMeetingSchView, showcaseID, callApi]);

    useEffect(() => {
        if (totalShowcaseQView && !allData?.totalShowcaseQ?.called && showcaseID) {
            getCommonDataFunc({ scID: showcaseID, name: 'totalShowcaseQ', url: '/dashboard/showcase-query' });
        }
    }, [totalShowcaseQView, showcaseID, callApi]);

    useEffect(() => {
        if (totalProductQView && !allData?.totalProductQ?.called && showcaseID) {
            getCommonDataFunc({ scID: showcaseID, name: 'totalProductQ', url: '/dashboard/product-query' });
        }
    }, [totalProductQView, showcaseID, callApi]);

    useEffect(() => {
        if (mostInquiredPView && !allData?.mostViewP?.called && showcaseID) {
            getCommonDataFunc({ scID: showcaseID, name: 'mostViewP', url: '/dashboard/popular-product/views' });
        }
    }, [mostInquiredPView, showcaseID, callApi]);

    useEffect(() => {
        if (mostViewPView && !allData?.mostInquiredP?.called && showcaseID) {
            getCommonDataFunc({ scID: showcaseID, name: 'mostInquiredP', url: '/dashboard/popular-product/queries' });
        }
    }, [mostViewPView, showcaseID, callApi]);

    useEffect(() => {
        if (mostPopularVView && !allData?.mostPopularV?.called && showcaseID) {
            getCommonDataFunc({ scID: showcaseID, name: 'mostPopularV', url: '/dashboard/popular-video' });
        }
    }, [mostPopularVView, showcaseID, callApi]);

    useEffect(() => {
        if (mostPopularDView && !allData?.mostPopularD?.called && showcaseID) {
            getCommonDataFunc({ scID: showcaseID, name: 'mostPopularD', url: '/dashboard/popular-doc' });
        }
    }, [mostPopularDView, showcaseID, callApi]);

    useEffect(() => {
        if (avgSessionView && !allData?.avgSession?.called && showcaseID && companyRef) {
            getCommonDataFunc({ scID: showcaseID, name: 'avgSession', url: '/dashboard/average-time-spent' });
        }
    }, [avgSessionView, showcaseID, companyRef, callApi]);

    useEffect(() => {
        // getShowcaseList();
    }, []);

    useEffect(() => {
        setFilterDataFunc();
    }, [showcaseList]);

    useEffect(() => {
        if (filterData.searchTime === 'custom' && filterData.startDate !== '' && filterData.endDate !== '') {
            secureLocalStorage?.setItem('filterData', {
                showcaseID: filterData?.showcaseID,
                searchTime: 'custom',
                startDate: filterData?.startDate,
                endDate: filterData?.endDate,
            });
            window.dispatchEvent(new Event('storage'));

            calledObjectToFalse();
            setCallApi((prev) => !prev);
        }
    }, [filterData]);

    const onChangeFilter = (e) => {
        const { name, value } = e.target;
        if (name === 'showcaseID') {
            setShowcaseID(value);
        }
        setFilterData((prev) => ({
            ...prev,
            [name]: value,
            startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
            endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
        }));

        const showcaseVal = name === 'showcaseID' ? value : filterData?.showcaseID;
        const searchTimeVal = name === 'showcaseID' ? filterData?.searchTime : value;
        secureLocalStorage?.setItem('filterData', {
            showcaseID: showcaseVal,
            searchTime: searchTimeVal,
            startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
            endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
        });
        window.dispatchEvent(new Event('storage'));

        if (value !== 'custom') {
            calledObjectToFalse();
            setCallApi((prev) => !prev);
        }
    };

    const getShowcaseList = async () => {
        try {
            const data = await apiMiddleWare({ type: 'get', url: '/showcase/list-all' });
            if (!data) {
                return false;
            }
            if (data?.data?.ok) {
                const allPacket = data?.data?.packet;

                setShowcaseID(filterData?.showcaseID || allPacket?.[0]?._id);
                setCompanyRef(allPacket?.[0]?.companyRef);
                setFilterData((prev) => ({
                    ...prev,
                    showcaseList: allPacket.map((i) => ({ _id: i?._id, name: i?.name })),
                    showcaseID: filterData?.showcaseID || allPacket?.[0]?._id,
                }));

                return true;
            }

            return false;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    };

    const setFilterDataFunc = () => {
        setShowcaseID(filterData?.showcaseID || showcaseList?.[0]?._id || '');
        setCompanyRef(showcaseList?.[0]?.companyRef || '');
        setFilterData((prev) => ({
            ...prev,
            showcaseList,
            showcaseID: prev?.showcaseID || showcaseList?.[0]?._id || '',
        }));
        secureLocalStorage?.setItem('filterData', {
            showcaseID: filterData?.showcaseID || showcaseList?.[0]?._id || '',
            searchTime: filterData?.searchTime,
            startDate: filterData?.startDate,
            endDate: filterData?.endDate,
        });
        window.dispatchEvent(new Event('storage'));
    };

    const getCommonDataFunc = async ({ scID, name, url }) => {
        try {
            const sendData = {
                showcaseRef: scID,
                startDate: filterData?.startDate && timeZeroFormat({ date: filterData?.startDate }),
                endDate: filterData?.endDate && timeZeroFormat({ date: filterData?.endDate, hour: '48' }),
                companyRef,
            };
            const res = await apiMiddleWare({ type: 'post', url, callType: 'analytics', data: sendData });
            if (!res) {
                return false;
            }
            if (!res?.data?.ok) {
                customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
                return false;
            }

            const dataHere = res?.data?.packet;
            setAllData((prev) => ({
                ...prev,
                [name]: { loading: false, data: dataHere, called: true },
            }));
            return true;
        } catch (err) {
            console.log(err?.message || err?.msg || err);
            // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
            return false;
        }
    };

    const calledObjectToFalse = () => {
        setAllData((prev) => ({
            ...prev,
            avgSession: { ...prev?.avgSession, called: false },
            mostInquiredP: { ...prev?.mostInquiredP, called: false },
            mostPopularD: { ...prev?.mostPopularD, called: false },
            mostPopularV: { ...prev?.mostPopularV, called: false },
            mostViewP: { ...prev?.mostViewP, called: false },
            noOfLeads: { ...prev?.noOfLeads, called: false },
            noOfMeetingSch: { ...prev?.noOfMeetingSch, called: false },
            totalProductQ: { ...prev?.totalProductQ, called: false },
            totalShowcaseQ: { ...prev?.totalShowcaseQ, called: false },
        }));
    };

    const modalClose = () => {
        setModalData((prev) => ({ ...prev, open: false }));
    };

    const goToShowcase = () => {
        navigate(`/${userRouteName}/showcase`);
    };

    const refreshApiCall = () => {
        calledObjectToFalse();
        setCallApi((prev) => !prev);
    };

    return (
        <>
            <CommonModal
                title={`Alert`}
                open={modalData?.open}
                onClose={modalClose}
                size={'sm'}
                noTitleLine
                noFooterLine
                body={
                    <div className="d-flex align-items-center justify-content-center">
                        <Typography variant="h4" sx={{ p: 2 }}>
                            You don't have any Showcase
                        </Typography>
                    </div>
                }
                customFooter={
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <Button type="button" onClick={goToShowcase} variant="contained" className="mx-3">
                            Create Showcase
                        </Button>
                        <Button type="button" onClick={modalClose} variant="outlined" className="mx-3">
                            Skip
                        </Button>
                    </div>
                }
            />
            <Helmet>
                <title> Informa Marketplace </title>
            </Helmet>

            <Container maxWidth="xl" className="mt-4 mt-md-0">
                {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <Grid container spacing={3} className="" > */}
                    {/* <Grid
              item
              xs={12}
              md={1}
              className="d-flex justify-content-center align-items-center h-100 w-100"
              sx={{ mb: '2rem' }}
            >
              
              <Button
                type="button"
                variant="contained"
                color="success"
                onClick={refreshApiCall}
                className="text-white h-100 w-100"
              >
                <Iconify icon="ic:sharp-refresh" />
              </Button>
            </Grid> */}

                    {/* <Grid item xs={12} md={11}> */}
                    <Grid container spacing={3} sx={{ mb: '2rem' }}>
                        <Grid item xs={12} sm={6} md={3} className="d-none">
                            <TextField
                                fullWidth
                                name="showcaseID"
                                id="outlined-select-currency"
                                select
                                label="Showcase"
                                value={filterData?.showcaseID}
                                onChange={onChangeFilter}
                            >
                                {showcaseList?.length > 0 &&
                                    showcaseList?.map((i) => (
                                        <MenuItem key={i?._id} value={i?._id}>
                                            {i?.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                name="searchTime"
                                id="outlined-select-currency"
                                select
                                label="Search Time"
                                value={filterData?.searchTime}
                                onChange={onChangeFilter}
                                required
                            >
                                <MenuItem key={'last7'} value={'last7'}>
                                    Last 7 Days
                                </MenuItem>
                                <MenuItem key={'last30'} value={'last30'}>
                                    Last 30 Days
                                </MenuItem>
                                <MenuItem key={'custom'} value={'custom'}>
                                    Custom
                                </MenuItem>
                            </TextField>
                        </Grid>
                        {filterData?.searchTime === 'custom' && (
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DatePicker
                                        disableFuture
                                        label="Start Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={filterData?.startDate}
                                        onChange={(newValue) => {
                                            setFilterData((prev) => ({
                                                ...prev,
                                                startDate: newValue,
                                            }));
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DatePicker
                                        disableFuture
                                        label="End Date"
                                        openTo="day"
                                        minDate={filterData?.startDate || dayjs()}
                                        views={['year', 'month', 'day']}
                                        value={filterData?.endDate}
                                        onChange={(newValue) => {
                                            setFilterData((prev) => ({
                                                ...prev,
                                                endDate: newValue,
                                            }));
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {/* </Grid> */}
                    {/* </Grid> */}
                </LocalizationProvider>
                {/* </Box> */}

                {isLoading ? (
                    'Loader'
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} ref={noOfLeadsRef}>
                            <AppWidgetSummary
                                title="No. of Footfall"
                                total={fShortenNumber(allData?.noOfLeads?.data?.count) || '-'}
                                // correct
                                gotoPage={'/user/dashboard/lead'}
                                icon={'ant-design:star-filled'}
                                iconBG={'#9694FF'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={noOfMeetingSchRef}>
                            <AppWidgetSummary
                                title="No. of Meetings Scheduled"
                                // total={allData?.noOfMeetingSch?.data || '-'}
                                total={'-'}
                                color="info"
                                icon={'healthicons:group-discussion-meetingx3'}
                                iconBG={'#9694FF'}
                                // gotoPage={'/user/dashboard/meeting'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={totalShowcaseQRef}>
                            <AppWidgetSummary
                                title="No. of Queries Received"
                                total={fShortenNumber(allData?.totalShowcaseQ?.data) || '-'}
                                // correct
                                icon={'mdi:chat-question'}
                                iconBG={'#9694FF'}
                                gotoPage={'/user/dashboard/queries?tab=showcase'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={mostInquiredPRef}>
                            <AppWidgetSummary
                                title="Most Inquired Product"
                                total={
                                    allData?.mostInquiredP?.data?.queries
                                        ? `${allData?.mostInquiredP?.data?.productName || ''} (${fShortenNumber(
                                              allData?.mostInquiredP?.data?.queries
                                          )})`
                                        : '-'
                                }
                                gotoPage={'/user/dashboard/queries?tab=product'}
                                icon={'mdi:question-box-multiple'}
                                iconBG={'#5DDAB4'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={totalProductQRef}>
                            <AppWidgetSummary
                                title="Total Product Queries"
                                total={fShortenNumber(allData?.totalProductQ?.data?.count) || '-'}
                                color="info"
                                icon={'mdi:chat-question'}
                                iconBG={'#5DDAB4'}
                                gotoPage={'/user/dashboard/queries?tab=product'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={mostViewPRef}>
                            <AppWidgetSummary
                                title="Most Viewed Product"
                                total={
                                    allData?.mostViewP?.data?.clicks
                                        ? `${allData?.mostViewP?.data?.productName || ''} (${fShortenNumber(allData?.mostViewP?.data?.clicks)})`
                                        : '-'
                                    // Object.keys(allData?.mostViewP?.data).length !== 0
                                    //   ? `${allData?.mostViewP?.data?.productName || ''} (${fShortenNumber(
                                    //       allData?.mostViewP?.data?.clicks
                                    //     )})`
                                    //   : '-'
                                }
                                gotoPage={`/user/dashboard/resource-analytics?tab=product`}
                                icon={'carbon:cube-view'}
                                iconBG={'#5DDAB4'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} ref={mostPopularVRef}>
                            <AppWidgetSummary
                                title="Most Popular Video"
                                total={allData?.mostPopularV?.data?.resourceName || '-'}
                                // correct
                                icon={'material-symbols:play-circle-rounded'}
                                iconBG={'#FF7976'}
                                gotoPage={`/user/dashboard/resource-analytics?tab=video`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={mostPopularDRef}>
                            <AppWidgetSummary
                                title="Most Popular Document"
                                total={allData?.mostPopularD?.data?.title || '-'}
                                // correct
                                color="info"
                                icon={'mdi:drive-document'}
                                iconBG={'#FF7976'}
                                gotoPage={`/user/dashboard/resource-analytics?tab=document`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} ref={avgSessionRef}>
                            <AppWidgetSummary
                                title="Average Session Duration"
                                // total={'-'}
                                // total={allData?.avgSession?.data}
                                total={moment.utc(allData?.avgSession?.data * 60 * 1000).format('HH:mm:ss') || '-'}
                                // total={moment.utc(1.83 * 60 * 1000).format('HH:mm:ss') || '-'}
                                icon={'pepicons-pop:rewind-time'}
                                // gotoPage={'/user/dashboard/lead'}
                                iconBG={'#FF7976'}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={8}>
                            <Typography variant="h5" className="black-login fs-5 my-3">
                                Daywise Leads
                            </Typography>
                            <AppWebsiteVisits
                                title="Daywise Leads"
                                // subheader="(+43%) than last year"
                                chartLabels={
                                    (allData?.noOfLeads?.data?.graph?.length > 0 && allData?.noOfLeads?.data?.graph?.map((i) => i?._id)) || []
                                    //   [
                                    //   '01-01-2003',
                                    //   '02-01-2003',
                                    //   '03-01-2003',
                                    //   '04-01-2003',
                                    //   '05-01-2003',
                                    //   '06-01-2003',
                                    //   '07-01-2003',
                                    //   '08-01-2003',
                                    //   '09-01-2003',
                                    //   '10-01-2003',
                                    //   '11-01-2003',
                                    // ]
                                }
                                chartData={[
                                    // {
                                    //   name: 'Team A',
                                    //   type: 'column',
                                    //   fill: 'solid',
                                    //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                                    // },
                                    {
                                        name: 'Count',
                                        type: 'area',
                                        fill: 'gradient',
                                        data:
                                            (allData?.noOfLeads?.data?.graph?.length > 0 && allData?.noOfLeads?.data?.graph?.map((i) => i?.count)) ||
                                            [],
                                        // [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
                                    },
                                    // {
                                    //   name: 'Team C',
                                    //   type: 'line',
                                    //   fill: 'solid',
                                    //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                                    // },
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h5" className="black-login fs-5 my-3">
                                Top Product Query
                            </Typography>
                            <AppCurrentVisits
                                // title="Top Product Query"
                                chartData={
                                    (allData?.totalProductQ?.data?.graph?.length > 0 &&
                                        allData?.totalProductQ?.data?.graph?.map((i) => ({ label: i?.title, value: i?.count }))) ||
                                    []
                                    // [
                                    //   { label: 'America', value: 4344 },
                                    //   { label: 'Asia', value: 5435 },
                                    //   { label: 'Europe', value: 1443 },
                                    //   { label: 'Africa', value: 4443 },
                                    // ]
                                }
                                chartColors={[
                                    theme.palette.primary.main,
                                    theme.palette.error.main,
                                    theme.palette.warning.main,
                                    theme.palette.info.main,
                                ]}
                            />
                        </Grid>

                        {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
                    </Grid>
                )}
            </Container>
        </>
    );
}
