import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function TawkToWidget() {
  const onLoad = () => {
    console.log('onLoad Tawk!');
  };

  return <TawkMessengerReact propertyId="64ec69d1a91e863a5c1035fd" widgetId="1h8tnqn34" onLoad={onLoad} />;
}

export default TawkToWidget;
