import { createContext, useEffect, useState } from 'react';
import { defaultError, stepDetail } from '../../utils/constants';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';

export const Progresscontext = createContext(null);

const stepOrder = [
    'profile',
    'resource',
    'product',
    'contact',
    'showcase',
    'emailVerified',
    // 'phoneVerified'
];

export default function ProgressContext({ children }) {
    const [callGuideStatus, setCallGuideStatus] = useState(false);
    const [currentScore, setCurrentScore] = useState(0);
    const [status, setStatus] = useState({
        profile: false,
        resource: false,
        product: false,
        contact: false,
        showcase: false,
        emailVerified: false,
        // phoneVerified: false,
    });

    const totalStep = stepOrder?.length;

    const callCurrentStatus = async () => {
        try {
            const res = await apiMiddleWare({ url: `/users/guide-status`, type: 'get' });
            if (!res?.data?.ok) {
                customToast({
                    type: 'error',
                    msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                });
                return;
            }
            setStatus(res?.data?.status);

            let score = 0;
            for (let i = 0; i < totalStep; i += 1) {
                if (res?.data?.status?.[stepOrder?.[i]]) {
                    score += stepDetail?.[stepOrder?.[i]]?.score || 1;
                }
            }
            setCurrentScore(score);
            return;
        } catch (err) {
            customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
        }
    };

    useEffect(() => {
        callCurrentStatus();
    }, [callGuideStatus]);

    return (
        <Progresscontext.Provider
            value={{
                callGuideStatus,
                setCallGuideStatus,
                status,
                setStatus,
                currentScore,
                setCurrentScore,
            }}
        >
            {children}
        </Progresscontext.Provider>
    );
}
