/* eslint-disable react/self-closing-comp */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Box, Button, Grid, InputLabel, Paper, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { Masonry, TabContext, TabPanel } from '@mui/lab';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player';

import { defaultError, S3BucketBaseUrl, defaultProductImg } from '../../utils/constants';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import useResponsive from '../../hooks/useResponsive';
import { copyText, openInNewTab, parseVideo, randomNumber } from '../../utils/helper';
import CommonModal from '../../components/modal/CommonModal';
import useLocalStorage from '../../hooks/useLocalStorage';
import Iconify from '../../components/iconify/Iconify';

function ShowcaseAllInOne() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const lgUp = useResponsive('up', 'lg');

  const navigate = useNavigate();
  const location = useLocation();

  const { showcaseUnique } = useParams();
  const { showcaseData, HEADER_MOBILE, HEADER_DESKTOP } = useOutletContext();
  const roleData = useLocalStorage('sessionInfo')?.roles;

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [galleryTab, setGalleryTab] = useState(0);

  const [allData, setAllData] = useState({
    showcaseData: {},
    resourceData: {
      documentItems: [],
      galleryItems: [],
    },

    contact: {
      contactList: [],
      calendlyLink: '',
      website: '',
      email: '',
      phone: '',
      address: '',
      socialMedia: {
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        pinterest: '',
        youtube: '',
      },
    },
    products: [],
  });
  const [likedData, setLikedData] = useState([]);
  const [callLikedApi, setCallLikedApi] = useState(false);
  const [inquireModal, setInquireModal] = useState({
    open: false,
    data: {
      topic: '',
      query: '',
    },
  });
  const [modalData, setModalData] = useState({
    open: false,
    data: { source: '', type: '' },
  });
  const [videoModalData, setVideoModalData] = useState({
    open: false,
    data: { source: '', type: '' },
  });
  const [allTeamModal, setAllTeamModal] = useState({ open: false });
  const [showMoreProduct, setShowMoreProduct] = useState(false);

  useEffect(() => {
    if (!smUp) {
      navigate(`/showcase/${showcaseUnique}/home`);
    }
  }, [smUp]);

  useEffect(() => {
    if (Object.keys(showcaseData).length > 0) setDataShowcase();
  }, [showcaseData]);

  useEffect(() => {
    getLikedData();
  }, [callLikedApi]);

  const Label = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
  }));

  const setDataShowcase = async () => {
    try {
      setIsLoading(true);
      const allData2 = showcaseData;
      if (!allData2) {
        setIsLoading(false);
        return false;
      }

      setAllData((prev) => ({
        ...prev,
        showcaseData: allData2,
        contact: {
          ...prev.contact,
          address: allData2?.address,
          calendlyLink: allData2?.calendlyLink,
          email: allData2?.email,
          phone: allData2?.phone,
          website: allData2?.website,
          socialMedia: {
            ...prev.contact.socialMedia,
            facebook: allData2?.socialMedia?.facebook,
            instagram: allData2?.socialMedia?.instagram,
            linkedin: allData2?.socialMedia?.linkedin,
            twitter: allData2?.socialMedia?.twitter,
            pinterest: allData2?.socialMedia?.pinterest,
            youtube: allData2?.socialMedia?.youtube,
          },
        },
      }));
      // }
      if (getResource(allData2?._id) && getRep(allData2?._id) && getProducts(allData2?._id)) {
        setIsLoading(false);
      }

      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const getResource = async (showcaseID) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/resource/list-all/${showcaseID}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }
      const docList = res?.data?.packet?.documentItems?.filter((i) => i?.docID);
      const docSorted = docList?.sort((a, b) => a.priority - b.priority);
      const docSpread = docSorted?.map((i) => i?.docID);

      const galleryList = res?.data?.packet?.galleryItems?.filter((i) => i?.galleryID);
      const gallerySorted = galleryList?.sort((a, b) => a.priority - b.priority);
      const gallerySpread = gallerySorted?.map((i) => i?.galleryID);

      setAllData((prev) => ({
        ...prev,
        resourceData: {
          ...prev.resourceData,
          galleryItems: gallerySpread,
          documentItems: docSpread,
        },
      }));
      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const getRep = async (showcaseID) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/rep/get/${showcaseID}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }

      const repList = res?.data?.packet?.filter((i) => i?.repID);
      const repSorted = repList?.sort((a, b) => a.priority - b.priority);
      const repSpread = repSorted?.map((i) => {
        const thisData = i?.repID;
        let image;
        const base = `/assets/icons/avatar/`;

        if (thisData?.photo) {
          image = S3BucketBaseUrl + thisData?.photo;
        } else {
          if (thisData?.gender === 'Male') {
            image = `${base}M-${randomNumber(1, 4)}.svg`;
          } else if (thisData?.gender === 'Female') {
            image = `${base}F-${randomNumber(1, 4)}.svg`;
          } else {
            image = `${base}M-1.svg`;
          }
        }
        i.repID.photo = image;

        return i?.repID;
      });

      setAllData((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          contactList: repSpread,
        },
      }));
      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const getProducts = async (showcaseID) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/product/list-all/${showcaseID}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }

      const productList = res?.data?.packet?.filter((i) => i?.productID);
      const productSorted = productList?.sort((a, b) => a.priority - b.priority);
      const productSpread = productSorted?.map((i) => i?.productID);

      setAllData((prev) => ({ ...prev, products: productSpread }));
      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const onOpenInquireModal = () => {
    setInquireModal((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const onCloseInquireModal = () => {
    setInquireModal((prev) => ({
      ...prev,
      open: false,
      data: {
        query: '',
        topic: '',
      },
    }));
  };

  const submitInquire = async () => {
    try {
      // setIsLoading(true);

      const sendData = await validateInquireModal();

      const finalData = {
        ...sendData,
        companyRef: allData?.showcaseData?.companyRef,
        showcaseRef: allData?.showcaseData?._id,
      };

      const res = await apiMiddleWare({
        type: 'post',
        url: `/visitors/showcase-query`,
        data: finalData,
      });

      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          type: 'error',
        });
        // setIsLoading(false);
        return false;
      }

      customToast({
        msg: 'Query Submitted Successfully',
        type: 'success',
      });
      onCloseInquireModal();
      // setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
      // setIsLoading(false);
      return false;
    }
  };

  const validateInquireModal = async () =>
    new Promise((resolve, reject) => {
      const sendData = {};

      if (inquireModal?.data?.topic === '') {
        reject(new Error(`Topic is required`));
        return false;
      }
      sendData.topic = inquireModal?.data?.topic?.trim();

      if (inquireModal?.data?.query === '') {
        reject(new Error(`Query is required`));
        return false;
      }
      sendData.query = inquireModal?.data?.query?.trim();

      resolve(sendData);
      return true;
    });

  const onChangeInquire = (e) => {
    const { name, value } = e.target;
    setInquireModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  const analyticsSocial = async (name) => {
    // 'facebook', 'instagram', 'linkedin', 'twitter', 'pinterest', 'youtube'
    try {
      // if (!roleData?.length > 0 || roleData?.includes('owner')) {
      //   return false;
      // }
      const sendData = {
        companyRef: allData?.showcaseData?.companyRef,
        showcaseRef: allData?.showcaseData?._id,
        socialMedia: name,
      };
      const data = await apiMiddleWare({ type: 'post', url: '/click/social', callType: 'analytics', data: sendData });
      if (!data) {
        return false;
      }
      if (!data?.data?.ok) {
        return false;
      }
      return true;
    } catch (err) {
      console.error(err);
      // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const callLikeApi = async ({ productRef, showcaseRef, companyRef }) => {
    try {
      const sendData = {
        productRef,
        showcaseRef,
        companyRef,
      };
      const res = await apiMiddleWare({ type: 'post', url: `/visitors/product-like`, data: sendData });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      setCallLikedApi((prev) => !prev);
      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  async function callAnalyticsRes(resourceID, type) {
    try {
      // if (!roleData?.length > 0 || roleData?.includes('owner')) {
      //   return false;
      // }
      const sendData = {
        showcaseRef: allData?.showcaseData?._id,
        companyRef: allData?.showcaseData?.companyRef,
        resourceRef: resourceID,
        type,
      };
      const data = await apiMiddleWare({ type: 'post', url: '/click/resource', callType: 'analytics', data: sendData });
      if (!data) {
        return false;
      }
      if (!data?.data?.ok) {
        console.log('ok false', data?.data);
        return false;
      }
      return true;
    } catch (err) {
      console.error('ok false', err);
      // customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  }

  const getLikedData = async () => {
    try {
      if (!roleData?.length > 0 || roleData?.includes('owner')) {
        return false;
      }
      // const sendData = {
      //   showcaseRef: showcaseData?._id,
      //   companyRef: showcaseData?.companyRef,
      // };
      const res = await apiMiddleWare({
        type: 'get',
        url: `/visitors/product-like`,
        // data: sendData
      });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      setLikedData(res?.data?.package);
      return true;
    } catch (err) {
      console.error(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const openProduct = (id) => {
    navigate(`/showcase/${showcaseUnique}/product/${id}`, {
      state: { showcaseID: allData?.showcaseData?._id, companyRef: allData?.showcaseData?.companyRef },
    });
  };

  const documentContent = allData?.resourceData?.documentItems?.map((i, index) => (
    <div key={index} className="col-6 col-md-4 col-lg-3 py-2">
      <div
        className="doc__box"
        onClick={() => {
          openInNewTab(`${S3BucketBaseUrl}${i?.source}`)();
          // callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
        }}
      >
        <div className={`icon__box p-3 fs-6`}>
          {i?.extension === 'pdf' ? (
            // <Iconify icon="ph:file-pdf-light" width="10" />
            <img src="/assets/icons/pdf-icon-sm.png" alt="PDF-ICON" className="doc_icon_size" />
          ) : i?.extension === 'xls' || i?.extension === 'xlsx' ? (
            // <Iconify icon="ph:file-xls-light" width="10" />
            <img src="/assets/icons/xls-icon-sm.png" alt="XLS-ICON" className="doc_icon_size" />
          ) : (
            // 'doc', 'docx'
            // <Iconify icon="ph:file-doc-light" width="10" />
            <img src="/assets/icons/doc-icon-sm.png" alt="DOC-ICON" className="doc_icon_size" />
          )}
        </div>
        <div className={`title__box p-2 w-50`}>
          <Tooltip
            title={i?.title}
            placement={'bottom-start'}
            className="cursor-pointer"
            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
          >
            <div className="ellipse-text text-center text-md-start font-body-custom">{i?.title}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  ));

  const imageContent =
    allData?.resourceData?.galleryItems
      ?.map((i, index) => {
        if (i?.type !== 'image') {
          return null;
        }
        return (
          <div key={index} className="custom-shadow2">
            <div
              style={{
                backgroundColor: '#1A2027',
                borderRadius: '12px',
                textAlign: 'center',
                color: 'white',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: '0.5px solid white',
              }}
              className="font-body-custom"
            >
              {i?.title}
            </div>
            <div
              className="img_vid__box2"
              onClick={() => {
                openModal({ source: `${S3BucketBaseUrl}${i?.source}`, type: i?.type });
                // callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
              }}
            >
              <img src={`${S3BucketBaseUrl}${i?.source}`} className="image" alt="" />
            </div>
          </div>
        );
      })
      .filter((i) => i) || [];

  const videoContent =
    allData?.resourceData?.galleryItems
      ?.map((i, index) => {
        if (i?.type === 'image') {
          return null;
        }
        return (
          <div key={index} className="custom-shadow2">
            <div
              style={{
                backgroundColor: '#1A2027',
                borderRadius: '12px',
                textAlign: 'center',
                color: 'white',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: '0.5px solid white',
              }}
              className="font-body-custom"
            >
              {i?.title}
            </div>
            <div
              className="img_vid__box2"
              onClick={() => {
                // openInNewTab(i?.source)();
                openVideoModal({ source: i?.source, type: i?.title });
                // callAnalyticsRes(i?._id, i?.type); // uncomment after analytics done
              }}
            >
              <img src={getThumbnailLink(i?.source)} className="video" alt="" />
              <img src="/assets/play_arrow.png" alt="play" className="playBtn" />
            </div>
          </div>
        );
      })
      .filter((i) => i) || [];

  const openModal = ({ source, type }) => {
    setModalData((prev) => ({
      ...prev,
      open: true,
      data: {
        source,
        type,
      },
    }));
  };

  const onCloseModal = () => {
    setModalData((prev) => ({
      ...prev,
      open: false,
      data: {
        source: '',
        type: '',
      },
    }));
  };

  const openVideoModal = ({ source, type = '' }) => {
    setVideoModalData((prev) => ({
      ...prev,
      open: true,
      data: {
        source,
        type,
      },
    }));
  };

  const onCloseVideoModal = () => {
    setVideoModalData((prev) => ({
      ...prev,
      open: false,
      data: {
        source: '',
        type: '',
      },
    }));
  };

  const openAllTeamModal = () => {
    setAllTeamModal((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const onCloseAllTeam = () => {
    setAllTeamModal((prev) => ({
      ...prev,
      open: false,
    }));
  };

  function getThumbnailLink(link) {
    const data = parseVideo(link);
    return data?.thumb;
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGalleryTabChange = (event, newValue) => {
    setGalleryTab(newValue);
  };

  // console.log(allData);
  return (
    <>
      <CommonModal
        open={inquireModal?.open}
        onClose={onCloseInquireModal}
        title={`Showcase Inquiry`}
        onSubmit={submitInquire}
        body={
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Topic</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    // fullWidth
                    name="topic"
                    value={inquireModal?.data?.topic}
                    placeholder="Type Topic of Query Here"
                    onChange={onChangeInquire}
                    inputProps={{ maxLength: 100 }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Query</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    // sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    fullWidth
                    name="query"
                    placeholder="Type Descriptive Query Here"
                    value={inquireModal?.data?.query}
                    onChange={onChangeInquire}
                    multiline
                    rows={5}
                    // inputProps={{ maxLength: 30 }}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CommonModal
        open={modalData?.open}
        onClose={onCloseModal}
        title={`View${modalData?.data?.type === 'image' ? ' Image' : ''}`}
        body={
          <div className="modal_view__img ">
            <img src={modalData?.data?.source || ''} alt="" className="mx-auto object-fit-cover h-inherit" />
          </div>
        }
      />

      <CommonModal
        open={videoModalData?.open}
        onClose={onCloseVideoModal}
        title={videoModalData?.data?.type}
        body={
          <div className="modal_view__img ">
            <ReactPlayer url={videoModalData?.data?.source || ''} playing controls loop height={'400px'} />
          </div>
        }
      />
      <CommonModal
        open={allTeamModal?.open}
        onClose={onCloseAllTeam}
        title={`View Teams`}
        body={
          <>
            <div className="row px-3 pt-2">
              {allData?.contact?.contactList?.map((i) => (
                <div className="col-lg-4 col-12 mb-2" key={i?._id}>
                  <div className=" border border_radius_8px p-3 mb-2 ">
                    <div className="d-flex justify-content-center mb-3">
                      <img src={i?.photo} alt="" className="border_radius_100 h-130px round-border overflow-hidden" />
                    </div>
                    <div>
                      <Tooltip title={i?.name} placement="bottom">
                        <div className="ellipse-text cursor-pointer text-center fw-bold fs-6">{i?.name || ''}</div>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title={i?.designation} placement="bottom">
                        <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                          {i?.designation || ''}
                        </div>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title={i?.email} placement="bottom">
                        <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                          {i?.email || ''}
                        </div>
                      </Tooltip>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Button
                        type="button"
                        // disabled={!addProduct2?.data?.contactList?.value?.includes(i?._id)}
                        // onClick={sendInvite(i)}
                        variant="contained"
                        className="mt-3 box-shadow-none w-100 green-btn"
                      >
                        Chat
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        }
      />

      <Container
        style={{ marginTop: lgUp ? HEADER_DESKTOP : HEADER_MOBILE }}
        maxWidth="xl"
        className="px-4 px-md-5 tab-container"
        sx={{
          pb: 2,
        }}
      >
        <div className="img__block ">
          <div className="banner__img banner__img2">
            {allData?.showcaseData?.banner?.type === 'image' ? (
              <img src={S3BucketBaseUrl + allData?.showcaseData?.banner?.source} alt="" />
            ) : (
              <ReactPlayer url={allData?.showcaseData?.banner?.source || ''} muted playing loop height={'400px'} />
            )}
          </div>
          <div className="logo__img d-flex">
            <img
              src={S3BucketBaseUrl + allData?.showcaseData?.logo}
              alt=""
              className="aspect-ratio-1 background-white"
            />
          </div>
        </div>

        <div className="showcase__title d-flex justify-content-between ">
          <Typography variant="string" sx={{ my: 2, mb: 4.5 }} className="font-main-title-custom">
            {allData?.showcaseData?.name || ''}
          </Typography>
        </div>

        {allData?.showcaseData?.hashtags?.length > 0 &&
          allData?.showcaseData?.hashtags?.map((i, index) => (
            <HashtagBox className="showcase__title">
              <span className="hashtag-box-informa" key={index}>
                {i}
              </span>
            </HashtagBox>
          ))}

        <TabContext value={currentTab}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="Overview" />
            <Tab label="Product" />
            <Tab label="Contact" />
          </Tabs>
          <TabPanel value={0} className="">
            <div className=" font-body-custom mb-4">
              <div dangerouslySetInnerHTML={{ __html: allData?.showcaseData?.description || '' }} />
            </div>

            <div className=" mb-4 ">
              <Typography variant="h5" className="text-black mb-2 font-title-custom">
                Catalouge
              </Typography>
              <div>
                {allData?.resourceData?.documentItems?.length === 0 ? (
                  <div className="text-center fs-5 fw-lighter opacity-5">No Catalouge Added</div>
                ) : (
                  <div className="row font-body-custom">{documentContent}</div>
                )}
              </div>
            </div>

            <div className="mb-4">
              <Typography variant="h5" className="text-black mb-2 font-title-custom">
                Gallery
              </Typography>
              <TabContext value={galleryTab}>
                <Tabs value={galleryTab} onChange={handleGalleryTabChange}>
                  <Tab label="Image" />
                  <Tab label="Video" />
                </Tabs>

                <TabPanel value={0} className="px-0">
                  {imageContent?.length === 0 ? (
                    <div className="text-center fs-5 fw-lighter opacity-5">No Image Added</div>
                  ) : (
                    <Box sx={{ width: '100%', minHeight: 393 }} className="font-body-custom">
                      <Masonry columns={mdUp ? 4 : smUp ? 3 : 2} spacing={2} className="m-0">
                        {imageContent}
                      </Masonry>
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={1} className="px-0">
                  {videoContent?.length === 0 ? (
                    <div className="text-center fs-5 fw-lighter opacity-5">No Video Added</div>
                  ) : (
                    <Box sx={{ width: '100%', minHeight: 393 }} className="font-body-custom">
                      <Masonry columns={mdUp ? 4 : smUp ? 3 : 2} spacing={2} className="m-0">
                        {videoContent}
                      </Masonry>
                    </Box>
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </TabPanel>
          <TabPanel value={1} className="">
            <div className="row">
              {allData?.products?.length === 0 ? (
                <div className="text-center fs-5 fw-lighter opacity-5">No Products Added</div>
              ) : (
                allData?.products?.map((i, index) => (
                  <div key={index} className="col-6 col-sm-4 col-md-3 mb-3 product_max_ht">
                    <div
                      className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white cursor-pointer"
                      onClick={() => openProduct(i?._id)}
                    >
                      <div className="display-3 position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                        {i?.thumbnail ? (
                          <img
                            src={`${S3BucketBaseUrl}${i?.thumbnail}`}
                            alt=""
                            className="aspect-ratio-1 background-white"
                          />
                        ) : (
                          <img
                            src={`${S3BucketBaseUrl}${defaultProductImg}`}
                            alt=""
                            className="aspect-ratio-1 background-white"
                          />
                        )}
                        {/* <span
                          className=" position-absolute d-flex scale-like"
                          style={{ left: '8px', top: '8px' }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!roleData?.length > 0 || roleData?.includes('owner')) {
                              navigate(`/login`, {
                                state: { from: location },
                              });
                            } else {
                              callLikeApi({
                                productRef: i?._id,
                                showcaseRef: i?.showcaseRef,
                                companyRef: i?.companyRef,
                              });
                            }
                          }}
                        >
                          {likedData?.includes(i?._id) ? (
                            <Iconify icon="mdi:cards-heart" width={25} sx={{ color: 'red' }} />
                          ) : (
                            <Iconify
                              icon="mdi:cards-heart-outline"
                              width={25}
                              style={{
                                mixBlendMode: 'difference',
                                // color: i?.productResourceRef?.galleryItems[0] ? 'white' : 'black',
                              }}
                            />
                          )}
                        </span> */}
                      </div>
                      <div
                        className="title__box p-2 bg-light font-body-custom cursor-pointer"
                        style={{ height: '40px' }}
                        // onClick={() => {
                        //   // openProduct(i?._id);
                        // }}
                      >
                        <span className={smUp ? 'w-200px' : 'w-100'}>
                          <Tooltip
                            title={i?.title}
                            placement={'bottom-start'}
                            className="cursor-pointer"
                            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                          >
                            <div className="ellipse-text">{i?.title}</div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </TabPanel>
          <TabPanel value={2} className="">
            {allData?.contact?.contactList?.length > 0 && (
              <>
                <div className="mb-4 ">
                  <Typography variant="h5" className="text-black mb-2 font-title-custom">
                    Company Info
                  </Typography>
                  <div className="border_radius_8px p-3 custom-shadow2">
                    <div className="row mt-2 ">
                      <div className="col-md-6 row display-name-value flex-grow-1">
                        <div className="col-4 text-end">Website Link</div>
                        <div className="col-8">
                          <Tooltip
                            title={allData?.contact?.website}
                            placement="bottom-start"
                            className="cursor-pointer"
                            onClick={() =>
                              allData?.contact?.website && copyText(allData?.contact?.website, 'Website Link Copied')
                            }
                          >
                            <div className="ellipse-text font-body-14">{allData?.contact?.website || '-'}</div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-md-6 row display-name-value flex-grow-1">
                        <div className="col-4 text-end">Phone Number</div>
                        <div className="col-8">
                          <Tooltip
                            title={allData?.contact?.phone}
                            placement="bottom-start"
                            className="cursor-pointer"
                            onClick={() =>
                              allData?.contact?.phone && copyText(allData?.contact?.phone, 'Phone Number Copied')
                            }
                          >
                            <div className="ellipse-text font-body-14">{allData?.contact?.phone || '-'}</div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-md-6 row display-name-value flex-grow-1">
                        <div className="col-4 text-end">Address</div>
                        <div className="col-8">
                          <Tooltip
                            title={allData?.contact?.address}
                            placement="bottom-start"
                            className="cursor-pointer"
                            onClick={() =>
                              allData?.contact?.address && copyText(allData?.contact?.address, 'Address Copied')
                            }
                          >
                            <div className="ellipse-text font-body-14">{allData?.contact?.address || '-'}</div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-md-6 row display-name-value flex-grow-1">
                        <div className="col-4 text-end">Email</div>
                        <div className="col-8">
                          <Tooltip
                            title={allData?.contact?.email}
                            placement="bottom-start"
                            className="cursor-pointer"
                            onClick={() => allData?.contact?.email && copyText(allData?.contact?.email, 'Email Copied')}
                          >
                            <div className="ellipse-text font-body-14">{allData?.contact?.email || '-'}</div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-md-6 row display-name-value flex-grow-1">
                        <div className="col-4 text-end">Calendly Link</div>
                        <div className="col-8">
                          <Tooltip
                            title={allData?.contact?.calendlyLink}
                            placement="bottom-start"
                            className="cursor-pointer"
                            onClick={() =>
                              allData?.contact?.calendlyLink &&
                              copyText(allData?.contact?.calendlyLink, 'Calendly Link Copied')
                            }
                          >
                            <div className="ellipse-text font-body-14">{allData?.contact?.calendlyLink || '-'}</div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-md-6 row display-name-value flex-grow-1">
                        <div className="col-4 text-end">Social Media</div>
                        <div className="col-8">
                          <div className="display-6 d-flex flex-wrap row">
                            {allData?.contact?.socialMedia?.facebook && (
                              <>
                                <div
                                  className="cursor-pointer mb-2 social_allin1_icon_sz"
                                  onClick={() => {
                                    // analyticsSocial('facebook'); // uncomment after analytics done
                                    openInNewTab(allData?.contact?.socialMedia?.facebook)();
                                  }}
                                >
                                  <img src="/assets/icons/social_media/facebook-icon.png" alt="" />
                                </div>
                              </>
                            )}

                            {allData?.contact?.socialMedia?.instagram && (
                              <>
                                <div
                                  className="cursor-pointer mb-2 social_allin1_icon_sz"
                                  onClick={() => {
                                    // analyticsSocial('instagram'); // uncomment after analytics done
                                    openInNewTab(allData?.contact?.socialMedia?.instagram)();
                                  }}
                                >
                                  <img src="/assets/icons/social_media/instagram-icon.png" alt="" />
                                </div>
                              </>
                            )}

                            {allData?.contact?.socialMedia?.linkedin && (
                              <>
                                <div
                                  className="cursor-pointer mb-2 social_allin1_icon_sz"
                                  onClick={() => {
                                    // analyticsSocial('linkedin'); // uncomment after analytics done
                                    openInNewTab(allData?.contact?.socialMedia?.linkedin)();
                                  }}
                                >
                                  <img src="/assets/icons/social_media/linkedin-icon.png" alt="" />
                                </div>
                              </>
                            )}

                            {allData?.contact?.socialMedia?.twitter && (
                              <>
                                <div
                                  className="cursor-pointer mb-2 social_allin1_icon_sz"
                                  onClick={() => {
                                    // analyticsSocial('twitter'); // uncomment after analytics done
                                    openInNewTab(allData?.contact?.socialMedia?.twitter)();
                                  }}
                                >
                                  <img src="/assets/icons/social_media/twitter-icon.png" alt="" />
                                </div>
                              </>
                            )}

                            {allData?.contact?.socialMedia?.pinterest && (
                              <>
                                <div
                                  className="cursor-pointer mb-2 social_allin1_icon_sz"
                                  onClick={() => {
                                    // analyticsSocial('pinterest'); // uncomment after analytics done
                                    openInNewTab(allData?.contact?.socialMedia?.pinterest)();
                                  }}
                                >
                                  <img src="/assets/icons/social_media/pinterest-icon.png" alt="" />
                                </div>
                              </>
                            )}

                            {allData?.contact?.socialMedia?.youtube && (
                              <>
                                <div
                                  className="cursor-pointer mb-2 social_allin1_icon_sz"
                                  onClick={() => {
                                    // analyticsSocial('youtube'); // uncomment after analytics done
                                    openInNewTab(allData?.contact?.socialMedia?.youtube)();
                                  }}
                                >
                                  <img src="/assets/icons/social_media/youtube-icon.png" alt="" />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 ">
                  <Typography variant="h5" className="text-black mb-2 font-title-custom">
                    Team
                  </Typography>

                  <div className="row mt-2 ">
                    {allData?.contact?.contactList?.length === 0 ? (
                      <div className="text-center fs-5 fw-lighter opacity-5">No support team added</div>
                    ) : (
                      allData?.contact?.contactList?.map((i) => (
                        <div className=" col-6 col-lg-4 col-lg-2 mb-2" key={i?._id}>
                          <div className=" border border_radius_8px p-3 custom-shadow2">
                            <div className="d-flex justify-content-center mb-3 ">
                              <img
                                src={i?.photo}
                                alt=""
                                className="border_radius_100 round-border h-100px overflow-hidden aspect-ratio-1 background-white fit-contain"
                              />
                            </div>
                            <div>
                              <Tooltip title={i?.name} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-bold fs-6">
                                  {i?.name || ''}
                                </div>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title={i?.designation} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                                  {i?.designation || ''}
                                </div>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title={i?.email} placement="bottom">
                                <div className="ellipse-text cursor-pointer text-center fw-light font-body-14">
                                  {i?.email || ''}
                                </div>
                              </Tooltip>
                            </div>

                            <div className="d-flex justify-content-center">
                              <Button
                                type="button"
                                // disabled={!addProduct2?.data?.contactList?.value?.includes(i?._id)}
                                // onClick={sendInvite(i)}
                                variant="contained"
                                className="mt-3 box-shadow-none w-100 green-btn"
                              >
                                Chat
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </>
            )}
          </TabPanel>
        </TabContext>

        {/* <div className="row px-2">
          <div className="col-md-4">
            
           
            <div className="mt-3 row">
              
              <div className="col-6 ">
                <Button
                  variant="primary"
                  type="button"
                  className="green-btn w-100 text-light fw-normal"
                  onClick={() => {
                    if (!roleData?.length > 0 || roleData?.includes('owner')) {
                      navigate(`/login`, {
                        state: { from: location },
                      });
                    } else {
                      onOpenInquireModal();
                    }
                  }}
                >
                  Inquire Now
                </Button>
              </div>

              <div className="col-6 ">
                <Button
                  variant="primary"
                  className="bg-dark text-light w-100 fw-normal"
                  onClick={openInNewTab(allData?.contact?.calendlyLink)}
                >
                  Schedule Meeting
                </Button>
              </div>
            </div>
           
           
          
          </div>

        </div> */}
      </Container>
    </>
  );
}

export default ShowcaseAllInOne;

const HashtagBox = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  // width: 70%;
  margin-bottom: 1.5rem;
  // margin-left: auto;
`;
