/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { Input, Button, Box } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

import 'react-image-crop/dist/ReactCrop.css';
import CommonModal from '../modal/CommonModal';
import customToast from '../../utils/customToast';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from '../../hooks/useDebounceEffect';
import Iconify from '../iconify/Iconify';
import { getBlob } from '../../utils/helper';

centerAspectCrop.propTypes = {
  mediaWidth: PropTypes.number,
  mediaHeight: PropTypes.number,
  aspect: PropTypes.number,
};
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect || 16 / 9,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

ImageUpload.propTypes = {
  title: PropTypes.string,
  propAspect: PropTypes.number,
  propSize: PropTypes.number, // in mb
  propHeight: PropTypes.number,
  propWidth: PropTypes.number,
  onClose: PropTypes.func,
  previousImgSrc: PropTypes.string,
};

export default function ImageUpload({
  title,
  propAspect,
  propSize,
  propHeight,
  propWidth,
  onClose,
  previousImgSrc,
  customClass,
  customOpen,
}) {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(false);
  const [scale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (openModal) {
      setRotate(0);
      if (!!propAspect) {
        setAspect(propAspect);
      }
      if (!!previousImgSrc) {
        // compressFunction(previousImgSrc);
        setImgSrc(previousImgSrc);
        openAgain(previousImgSrc);
      }
    }
  }, [openModal]);

  useEffect(() => {
    if (customOpen || customOpen === false) {
      if (customOpen) {
        handleOpenModal();
      } else {
        onCloseHere();
      }
    }
  }, [customOpen]);

  const compressFunction = async (file) => {
    const options = {
      maxSizeMB: 1,
    };

    console.log('Before Compression', file?.size);
    const compressedFile = await imageCompression(file, options);
    console.log('After Compression', compressedFile);

    setImgSrc(compressedFile);
  };

  const openAgain = async (previousImgSrc) => {
    // the below lines are same as the onSelectFile() but this is used when we have 64 bit image (i.e. previousImgSrc)
    setCrop(undefined); // Makes crop preview update between images.

    const reader = new FileReader();
    reader.addEventListener('load', async (e) => {
      try {
        if (e.total > propSize * 1048576) {
          // propsize should be in MEGABYTE and it will be converted to BYTE
          customToast({ type: 'error', msg: `Size of the Image cannot be more than ${propSize}mb` });
          return false;
        }
        setImgSrc(reader.result?.toString() || '');
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const blob = await getBlob(previousImgSrc);
    reader.readAsDataURL(blob);
  };

  function handleOpenModal() {
    setOpenModal(true);
  }

  async function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', async (e) => {
        try {
          // const status = await checkHandW(e.target.result);

          // if (!status) {
          //   customToast({ type: 'error', msg: status });
          //   // return false
          // }

          if (e.total > propSize * 1048576) {
            // propsize should be in MEGABYTE and it will be converted to BYTE
            customToast({ type: 'error', msg: `Size of the Image cannot be more than ${propSize}mb` });
            return false;
          }
          setImgSrc(reader.result?.toString() || '');
          return true;
        } catch (err) {
          console.error(err);
          return false;
        }
      });

      const options = {
        maxSizeMB: 1,
      };
      const toastID = toast.loading('Processing...');

      console.log('Before Compression', e.target.files[0]?.size);
      const compressedFile = await imageCompression(e.target.files[0], options);
      console.log('After Compression', compressedFile);
      customToast({ updateId: toastID, type: 'update', msg: 'Image Processed', updateType: 'success' });

      reader.readAsDataURL(compressedFile);
      // reader.readAsDataURL(e.target.files[0]);
    }
  }

  function checkHandW(src) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      // const status = [true, ''];
      image.src = src;
      image.onload = function () {
        // if (!propHeight || !propWidth) {
        //   resolve();
        // }
        // eslint-disable-next-line react/no-this-in-sfc
        if (propHeight && this.height > propHeight) {
          customToast({ type: 'error', msg: `Height of the Image cannot be more than ${propHeight}px` });
          reject(new Error(`Height of the Image cannot be more than ${propHeight}px`));
          return;
        }
        // eslint-disable-next-line react/no-this-in-sfc
        if (propWidth && this.width > propWidth) {
          customToast({ type: 'error', msg: `Width of the Image cannot be more than ${propWidth}px` });
          reject(new Error(`Width of the Image cannot be more than ${propWidth}px`));
          return;
        }
        resolve();
      };
    });
  }

  function onImageLoad(e) {
    // if (aspect) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
    canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);

    // }
  }

  function onCloseHere() {
    setImgSrc('');
    setCompletedCrop(false);
    setOpenModal(false);
    onClose();
  }

  async function onSubmitHere() {
    if (!previewCanvasRef?.current?.toDataURL()) {
      customToast({ type: 'error', msg: `Please Update/Crop Image` });
      return false;
    }
    if (!!previewCanvasRef?.current?.toDataURL()) {
      // const options = {
      //   maxSizeMB: 1,
      // };
      // const compressedFile = await imageCompression(previewCanvasRef?.current?.toDataURL(), options);
      // onClose(compressedFile);
      onClose(previewCanvasRef?.current?.toDataURL());
    } else {
      onClose();
    }
    setImgSrc('');
    setCompletedCrop(false);
    setOpenModal(false);
    return true;
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <Button type="button" variant="outlined" className={customClass} onClick={() => handleOpenModal()}>
        Select Photo
      </Button>
      <CommonModal
        open={openModal}
        onClose={() => onCloseHere()}
        onSubmit={() => onSubmitHere()}
        title={title}
        body={
          <div className="App">
            {!!imgSrc && (
              <center className="mb-3">
                {/* <label htmlFor="rotate-input">Rotate: </label>
                <input
                  id="rotate-input"
                  type="number"
                  value={rotate}
                  disabled={!imgSrc}
                  onChange={(e) => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
                /> */}
                <Button variant="contained" type="button" onClick={(e) => setRotate(Number(rotate + 90))}>
                  <Iconify icon="material-symbols:rotate-right" width={25} />
                </Button>
              </center>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                '@media (max-width:900px)': { flexDirection: 'column' },
              }}
            >
              {!!imgSrc && (
                <div style={{ flexGrow: '1', maxWidth: '55vh' }}>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect || ''}
                  >
                    <img
                      crossOrigin="anonymous"
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                </div>
              )}
              <div>
                {!!completedCrop && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                )}
              </div>
            </Box>
            <div className="Crop-Controls">
              <Box
                sx={{
                  display: 'flex',
                  fontSize: '1.2rem',
                  justifyContent: 'center',
                  '@media (max-width:900px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <div style={{ marginRight: '10px' }}>Upload Image</div>
                <Input type="file" onChange={(e) => onSelectFile(e)} inputProps={{ accept: 'image/*' }} />
              </Box>
              {(propSize || propWidth || propHeight) && (
                <div className="img-validation">
                  <ul>
                    {propSize && <li>Maximum Size of the Image should be {propSize}MB.</li>}
                    {(propWidth || propHeight) && (
                      <li>
                        Maximum Resolution of the Image should be {propWidth && `${propWidth} (Width)`}
                        {propHeight && ` - ${propHeight} (Height)`}.
                      </li>
                    )}
                  </ul>
                </div>
              )}
              {/* <input type="file" accept="image/*" /> */}
            </div>
          </div>
        }
      />
    </>
  );
}
