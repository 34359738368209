/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Container, styled, Tooltip, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

import Iconify from '../../components/iconify';
import { defaultError, defaultProductImg, S3BucketBaseUrl } from '../../utils/constants';
import customToast from '../../utils/customToast';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import { parseVideo } from '../../utils/helper';
import useResponsive from '../../hooks/useResponsive';
import useLocalStorage from '../../hooks/useLocalStorage';

function VisitorProduct() {
  const { showcaseUnique } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const lgUp = useResponsive('up', 'lg');
  const smUp = useResponsive('up', 'sm');

  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState({
    showcase: {},
    products: [],
  });
  const [likedData, setLikedData] = useState([]);
  const [callLikedApi, setCallLikedApi] = useState(false);

  const roleData = useLocalStorage('sessionInfo')?.roles;

  const { showcaseData, onOpenNav, StyledRoot, StyledToolbar, HEADER_DESKTOP, HEADER_MOBILE } = useOutletContext();

  useEffect(() => {
    if (smUp) {
      navigate(`/showcase/${showcaseUnique}`);
    }
  }, [smUp]);

  useEffect(() => {
    if (Object.keys(showcaseData).length > 0) {
      setDataShowcase();
    }
  }, [showcaseData]);

  useEffect(() => {
    getLikedData();
  }, [callLikedApi]);

  const setDataShowcase = async () => {
    try {
      setIsLoading(true);
      const allData2 = showcaseData;
      if (!allData2) {
        setIsLoading(false);
        return false;
      }
      setAllData((prev) => ({ ...prev, showcase: allData2 }));

      // const allData = allData2;
      if (allData2?.banner?.type === 'video') {
        let videoSource = getIframeEmbed(allData2?.banner?.source);
        const autoplayIndex = videoSource.indexOf('autoplay=');
        videoSource = `${videoSource.substring(0, autoplayIndex + 9)}1${videoSource.substring(
          autoplayIndex + 10
        )}&disablekb=1&controls=0`;
        // modestbranding=1&showinfo=1&controls=1
        // don't forget to change the showcaseDashboard too
        setAllData((prev) => ({
          ...prev,
          showcase: {
            ...allData2,
            banner: { ...allData2?.banner, source: videoSource },
          },
        }));
      } else {
        setAllData((prev) => ({
          ...prev,
          showcaseData: allData2,
        }));
      }

      if (getProducts(allData2?._id)) {
        setIsLoading(false);
      }

      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);

      return false;
    }
  };

  const getProducts = async (showcaseID) => {
    try {
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/product/list-all/${showcaseID}` });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      const productList = res?.data?.packet?.filter((i) => i?.productID);
      const productSorted = productList?.sort((a, b) => a.priority - b.priority);
      const productSpread = productSorted?.map((i) => i?.productID);

      setAllData((prev) => ({ ...prev, products: productSpread }));
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const callLikeApi = async ({ productRef, showcaseRef, companyRef }) => {
    try {
      const sendData = {
        productRef,
        showcaseRef,
        companyRef,
      };
      const res = await apiMiddleWare({ type: 'post', url: `/visitors/product-like`, data: sendData });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      setCallLikedApi((prev) => !prev);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const getLikedData = async () => {
    try {
      if (!roleData?.length > 0 || roleData?.includes('owner')) {
        return false;
      }
      // const sendData = {
      //   showcaseRef: showcaseData?._id,
      //   companyRef: showcaseData?.companyRef,
      // };
      const res = await apiMiddleWare({
        type: 'get',
        url: `/visitors/product-like`,
        // data: sendData
      });
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        return false;
      }
      setLikedData(res?.data?.package);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const openProduct = (id) => {
    navigate(`/showcase/${showcaseUnique}/product/${id}`, {
      state: { showcaseID: allData?.showcase?._id, companyRef: allData?.showcase?.companyRef },
    });
  };

  const getIframeEmbed = (link) => {
    const allData = parseVideo(link);
    return allData?.iframe;
  };

  return isLoading ? (
    <>Loading...</>
  ) : Object.keys(allData?.showcase).length !== 0 ? (
    <>
      <div
        style={{ marginTop: lgUp ? '0px' : HEADER_MOBILE }}
        // maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }}
      >
        {/* <div className="img__block">
          <div className="banner__img">
            {allData?.showcase?.banner?.type === 'image' ? (
              <img src={S3BucketBaseUrl + allData?.showcase?.banner?.source} alt="" />
            ) : (
              <iframe
                title="video-banner"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={allData?.showcase?.banner?.source}
              />
            )}
          </div>
          <div className="logo__img">
            <img src={S3BucketBaseUrl + allData?.showcase?.logo} alt="" />
          </div>
        </div> */}
        <div className="img__block">
          <div className="banner__img banner__img2">
            {allData?.showcase?.banner?.type === 'image' ? (
              <img src={S3BucketBaseUrl + allData?.showcase?.banner?.source} alt="" />
            ) : (
              // <iframe
              //   title="video-banner"
              //   sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              //   src={allData?.showcase?.banner?.source}
              // />
              <ReactPlayer url={allData?.showcase?.banner?.source || ''} muted playing loop height={'300px'} />
            )}
          </div>
          <div className="logo__img">
            <img src={S3BucketBaseUrl + allData?.showcase?.logo} alt="" />
          </div>
        </div>

        <Container
          maxWidth="xl"
          className="px-4 px-md-5"
          sx={{
            pb: 2,
          }}
        >
          <div className="showcase__title d-flex justify-content-between ">
            <Typography variant="string" sx={{ my: 2, mb: 1.5 }} className="font-main-title-custom">
              {allData?.showcase?.name || ''}
            </Typography>
          </div>

          <HashtagBox>
            {allData?.showcase?.hashtags?.length > 0 &&
              allData?.showcase?.hashtags?.map((i, index) => (
                <span className="hashtag-box-informa" key={index}>
                  {i}
                </span>
              ))}
          </HashtagBox>

          <div className="my-2 mt-3 ">
            <Typography variant="h5" className="text-black mt-4 mb-3 font-title-custom">
              Products
            </Typography>

            <div className="row">
              {allData?.products?.length > 0 &&
                allData?.products?.map((i, index) => (
                  <div key={index} className="col-6 col-md-3 mb-3 ">
                    <div
                      className="d-flex p-0 flex-column justify-content-center align-items-center  border-custom bg-white cursor-pointer"
                      onClick={() => openProduct(i?._id)}
                    >
                      <div className="display-3 position-relative w-100 h-100 justify-content-center align-items-center d-flex">
                        {/* <Iconify icon="material-symbols:hide-image-outline-rounded" width="10" /> */}

                        {i?.thumbnail ? (
                          <img src={`${S3BucketBaseUrl}${i?.thumbnail}`} alt="" />
                        ) : (
                          <img src={`${S3BucketBaseUrl}${defaultProductImg}`} alt="" />
                        )}
                        {/* <span
                          className=" position-absolute d-flex scale-like"
                          style={{ left: '8px', top: '8px' }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!roleData?.length > 0 || roleData?.includes('owner')) {
                              navigate(`/login`, {
                                state: { from: location },
                              });
                            } else {
                              callLikeApi({
                                productRef: i?._id,
                                showcaseRef: i?.showcaseRef,
                                companyRef: i?.companyRef,
                              });
                            }
                          }}
                        >
                          {likedData?.includes(i?._id) ? (
                            <Iconify icon="mdi:cards-heart" width={25} sx={{ color: 'red' }} />
                          ) : (
                            <Iconify
                              icon="mdi:cards-heart-outline"
                              width={25}
                              style={{
                                color: i?.thumbnail ? 'white' : 'black',
                              }}
                            />
                          )}
                        </span> */}
                      </div>
                      <div
                        className="title__box p-2 bg-light font-body-custom cursor-pointer"
                        style={{ height: '40px' }}
                        // onClick={() => {
                        //   // openProduct(i?._id);
                        // }}
                      >
                        <span className={smUp ? 'w-200px' : 'w-100'}>
                          <Tooltip
                            title={i?.title}
                            placement={'bottom-start'}
                            className="cursor-pointer"
                            // onClick={() => i?.designation && copyText(i?.designation, 'Designation Copied')}
                          >
                            <div className="ellipse-text">{i?.title}</div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  ) : (
    <>Showcase Not Found</>
  );
}

export default VisitorProduct;

const HashtagBox = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  // width: 70%;
  margin-bottom: 1.5rem;
  // margin-left: auto;
`;
