import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

import ProgressContext from '../../components/circular-progress/ProgressContext';

ProtectedLayout.propTypes = {
    allowedRoles: PropTypes?.array,
    children: PropTypes?.element,
};

function ProtectedLayout({ allowedRoles, children }) {
    const { auth } = useAuth();
    const location = useLocation();
    return auth.user ? (
        auth?.roles.find((i) => allowedRoles.includes(i)) ? (
            <ProgressContext>{children}</ProgressContext>
        ) : (
            <Navigate to="/unauthorized" state={{ from: location }} replace />
        )
    ) : (
        //  location.pathname.includes('/user/') ? (
        <Navigate to="/user/login" state={{ from: location }} replace />
        // )
        // : (
        // <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default ProtectedLayout;
