import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import customToast from '../../utils/customToast';
import { defaultError } from '../../utils/constants';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

function SearchQR() {
  const { showcaseID } = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    redirectShowcase(showcaseID);
  }, []);

  const redirectShowcase = async (showcaseID) => {
    try {
      setIsLoading(true);
      const res = await apiMiddleWare({ type: 'get', url: `/showcase/get-identifier/${showcaseID}` });
      if (!res) {
        setIsLoading(false);
        return false;
      }
      if (!res?.data?.ok) {
        customToast({ msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError, type: 'error' });
        setIsLoading(false);
        return false;
      }
      const data = res?.data?.packet;
      navigate(`/showcase/${data?.identifier}`);
      return true;
    } catch (err) {
      console.log(err);
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);

      return false;
    }
  };
  return isLoading ? (
    <div>Redirecting...</div>
  ) : (
    <Container>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Sorry, Showcase not found!
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>Sorry, we couldn’t find the page you’re looking for.</Typography>

        <Box
          component="img"
          src="/assets/illustrations/illustration_404.svg"
          sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button to="/search" size="large" variant="contained" component={RouterLink}>
          Go to Search Page
        </Button>
      </StyledContent>
    </Container>
  );
}

export default SearchQR;
