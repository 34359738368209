import { useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

const getStorageData = (name) => secureLocalStorage.getItem(name);

export default function useStorageData(name) {
  const [data, setData] = useState(getStorageData(name));

  useEffect(() => {
    function handleChangeStorage() {
      setData(getStorageData(name));
    }

    window.addEventListener('storage', handleChangeStorage);
    return () => window.removeEventListener('storage', handleChangeStorage);
  }, []);

  return data;
}
