import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import secureLocalStorage from 'react-secure-storage';
// import moment from 'moment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
// import Webcam from 'react-webcam';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import useLocalStorage from '../../../hooks/useLocalStorage';
import Iconify from '../../../components/iconify/Iconify';
import ImageUpload from '../../../components/image-upload/ImageUpload';
import { useQuery } from '../../../utils/helper';
import useResponsive from '../../../hooks/useResponsive';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { defaultError, informaGreen } from '../../../utils/constants';

const commonBoxStyles = {
  bgcolor: '#fff',
  borderColor: 'transparent',
  '&::placeholder': {
    color: '#707070',
  },
  p: 2,
  // border: 1,
  width: '100%',
  minHeight: '5rem',
};

function UserLeadCapture() {
  // const filterDataLocal = useLocalStorage('filterData');
  const smUp = useResponsive('up', 'sm');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  // const [callApi, setCallApi] = useState(false);
  // const [filterData, setFilterData] = useState({
  //   searchTime: filterDataLocal?.searchTime || 'last7',
  //   startDate: filterDataLocal?.startDate || '',
  //   endDate: filterDataLocal?.endDate || '',
  // });

  const showcaseRef = useLocalStorage('showcaseID');
  const filterDataLocal = useLocalStorage('filterData');
  const sID = filterDataLocal?.showcaseID || '';

  const { showcaseList } = useOutletContext();
  const [filterData, setFilterData] = useState({
    showcaseID: filterDataLocal?.showcaseID || '',
  });

  const sesInfo = useLocalStorage('sessionInfo');

  const query = useQuery();
  const tabParam = query.get('tab');
  const [currentTab, setCurrentTab] = useState(tabParam === 'scan-card' ? 1 : 0);

  const imageUploadRef = useRef(null);
  const [customOpenImageModal, setCustomOpenImageModal] = useState(false);
  const toastProgress = React.useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    designation: '',
    countryCode: { value: '', list: [] },
    notes: '',
    whatsappMsg: '',
    source: tabParam === 'scan-card' ? 'scan-business-card' : 'manual', // ['manual', 'scan-business']
    cardPhoto: '',
    includeImage: false,
  });
  const [cameraError, setCameraError] = useState({ status: false, error: {} });

  // useEffect(() => {
  //   if (filterData?.searchTime === 'custom' && filterData?.startDate !== '' && filterData?.endDate !== '') {
  //     secureLocalStorage?.setItem('filterData', {
  //       searchTime: 'custom',
  //       startDate: filterData?.startDate,
  //       endDate: filterData?.endDate,
  //     });
  //     console.log('call API');
  //     // calledObjectToFalse();
  //     setCallApi((prev) => !prev);
  //   }
  // }, [filterData]);

  useEffect(() => {
    if (formData?.countryCode?.list?.length === 0) {
      getCountryCode();
    }
  }, []);

  // const onChangeFilter = (e) => {
  //   const { name, value } = e.target;
  //   setFilterData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //     startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
  //     endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
  //   }));
  //   secureLocalStorage?.setItem('filterData', {
  //     searchTime: value,
  //     startDate: value === 'last7' || value === 'custom' ? '' : moment().subtract(1, 'month'),
  //     endDate: value === 'last7' || value === 'custom' ? '' : new Date(),
  //   });
  //   if (value !== 'custom') {
  //     // calledObjectToFalse();
  //     setCallApi((prev) => !prev);
  //   }
  // };

  function handleTabChange(e, newValue) {
    setCurrentTab(newValue);
  }

  async function getCountryCode() {
    setIsLoading(true);
    try {
      const resData = await apiMiddleWare({ url: '/json/country-code', type: 'get' });
      if (!resData?.data?.ok) {
        setIsLoading(false);
        return;
      }
      setFormData((prev) => ({
        ...prev,
        countryCode: {
          ...prev.countryCode,
          list: resData?.data?.countryCode,
        },
      }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'countryCode') {
      setFormData((prev) => ({
        ...prev,
        countryCode: {
          ...prev.countryCode,
          value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmitForm = async (e) => {
    try {
      e.preventDefault();
      if (!filterData?.showcaseID) {
        customToast({
          msg: 'Showcase is mandatory to add leads',
          type: 'error',
        });
        return false;
      }
      const sendData = {
        showcaseRef: filterData?.showcaseID,
        // companyRef: '637e24a34a3c6440f95a731b',
        email: formData?.email,
        countryCode: formData?.countryCode?.value,
        phone: formData?.phone,
        notes: formData?.notes,
        fullName: formData?.name,
        designation: formData?.designation,
        company: formData?.company,
        whatsappMsg: formData?.whatsappMsg,
        source: formData?.source,
      };
      const res = await apiMiddleWare({
        type: 'post',
        url: '/users/add-lead',
        data: sendData,
      });
      if (!res) {
        return false;
      }
      if (!res?.data?.ok) {
        customToast({
          msg: res?.data?.errors?.[0]?.message || res?.data?.errors?.[0]?.msg || defaultError,
          type: 'error',
        });
        return false;
      }
      resetForm();
      customToast({
        msg: 'Lead Registered Successfully',
        type: 'success',
      });
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const resetForm = () => {
    setFormData((prev) => ({
      ...prev,
      name: '',
      email: '',
      phone: '',
      notes: '',
      whatsappMsg: '',
      countryCode: { ...prev.countryCode, value: '' },
      designation: '',
      company: '',
      source: 'manual',
    }));
  };

  const handlePhotoChange = (data) => {
    if (!!data) {
      setFormData((prev) => ({
        ...prev,
        cardPhoto: data,
      }));
      // setCustomOpenImageModal(false);
    }
  };
  const resetForm2 = () => {
    setFormData((prev) => ({
      ...prev,
      cardPhoto: '',
    }));
    resetForm();
  };

  const scanImage2 = async () => {
    const start = Date.now();

    const autoClose = 3000;
    const theme = 'dark';
    if (formData?.cardPhoto === '') {
      toast.error('Select Image First', { autoClose, theme });
      return;
    }
    setIsLoadingPhoto(true);
    toastProgress.current = toast.loading('Uploading...');
    let newToast;

    const getPhotoDataLink =
      'https://prod-07.centralindia.logic.azure.com:443/workflows/a9bb1adfbae74324b5cb7b2b9bbb505f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=npbvWJ6VSgBUMc5A4ImWU-IWv9US3drtOAvh0586W3c';
    // future change, send the link in constants

    try {
      const res = await axios({
        method: 'post',
        url: getPhotoDataLink,
        data: {
          image: formData?.cardPhoto,
          // apiKey: 'Jxc2sDRBLYcpK2s7XVbXQHbCT88jMu5gDgr9VQB',
          // 'x-auth': Cookies.get('x-auth') || sesInfo?.xAuth || false,
        },
        onUploadProgress: (p) => {
          // console.log('progress', p);
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastProgress.current === null) {
            toastProgress.current = toast('Upload in Progress', { progress });
          } else {
            toast.update(toastProgress.current, { progress });
          }
          if (p.loaded === p.total) {
            toast.update(toastProgress.current, {
              type: 'success',
              render: 'Image Uploaded Successfully',
              progress,
              autoClose,
              theme,
              closeOnClick: true,
              closeButton: <Iconify icon={'material-symbols:close-rounded'} sx={{ color: 'white' }} />,
              // isLoading: false,
            });

            newToast = toast.loading('Processing...');
          }
        },
      });
      if (res?.data) {
        customToast({ updateId: newToast, type: 'update', msg: 'Processing is done', updateType: 'success' });
        // navigate('user/dashboard/lead-capture?tab=card', { state: { userData: res?.data } });
        sendDataToManual(res?.data);
      } else {
        customToast({
          updateId: newToast,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
      }
      const end = Date.now();
      console.log(`Execution time: ${end - start} ms`);
      setIsLoadingPhoto(false);
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoadingPhoto(false);
    }
  };
  const sendDataToManual = (data) => {
    const phone = removeSlash(data?.phone) || removeSlash(data?.phone2);
    const phoneData = checkCountryCode(phone);
    setFormData((prev) => ({
      ...prev,
      name: removeSlash(data?.fullname),
      phone: phoneData?.number,
      email: removeSlash(data?.email),
      company: data?.company ? removeSlash(data?.company) : data?.email && getCompnayNameFromEmail(data?.email),
      designation: removeSlash(data?.designation),
      countryCode: { ...prev?.countryCode, value: phoneData?.countryCode },
      source: 'scan-business-card', // ['manual', 'scan-business']
    }));
    setCurrentTab(0);
  };

  const removeSlash = (str) => (str ? str.replace(/[\n\t]/, ' ') : null);

  const getCompnayNameFromEmail = (email) => {
    const removeList = ['gmail', 'yahoo', 'hotmail'];

    // eslint-disable-next-line no-useless-escape
    const regEx = /\@(\w*)./;

    const compnayName = email?.match(regEx)[1];
    if (removeList.includes(compnayName)) {
      return null;
    }
    return compnayName.charAt(0).toUpperCase() + compnayName.slice(1);
  };

  const checkCountryCode = (phoneNumber) => {
    if (phoneNumber) {
      const reomveSpace = phoneNumber?.replaceAll(/[ \-()]*/gi, '');
      const allCountryCode = formData?.countryCode?.list;

      for (let i = 0; i < allCountryCode?.length; i += i) {
        if (reomveSpace?.includes(allCountryCode[i]?.code)) {
          const result = {
            countryCode: allCountryCode[i]?.code,
            number: reomveSpace?.slice(allCountryCode[i]?.code?.length),
          };
          return result;
        }
      }
      return null;
    }

    return null;
  };

  // const WebcamCapture = () => {
  //   console.log('/');
  //   return (
  //     <Webcam
  //       height={smUp ? 281 : 196}
  //       screenshotFormat="image/jpeg"
  //       width={smUp ? 545 : 350}
  //       videoConstraints={{ facingMode: { exact: smUp ? 'user' : 'environment' } }}
  //     >
  //       {({ getScreenshot }) => (
  //         <button
  //           type="button"
  //           onClick={() => {
  //             const imageSrc = getScreenshot();
  //             console.log(imageSrc);
  //           }}
  //         >
  //           Capture
  //         </button>
  //       )}
  //     </Webcam>
  //   );
  // };

  const ReactHTML5Cam = () => {
    console.log();

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Camera
          idealResolution={{ width: smUp ? 450 : 300, height: smUp ? 250 : 300 }}
          idealFacingMode={FACING_MODES?.ENVIRONMENT}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri);
          }}
          onCameraError={handleCameraError}
        />
      </div>
    );
  };

  // useEffect(() => {
  //   if (!!formData?.cardPhoto) {
  //     setCustomOpenImageModal(formData?.cardPhoto);
  //   } else {
  //     setCustomOpenImageModal(false);
  //   }
  // }, [formData?.cardPhoto]);

  const handleCameraError = (error) => {
    setCameraError(() => ({ status: true, error }));
  };

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto', dataUri);
    setFormData((prev) => ({ ...prev, cardPhoto: dataUri }));
    imageUploadRef.current.querySelector('button').click();
  }

  return (
    <>
      <Container maxWidth="xl" className="mt-4 mt-md-0">
        {isLoading ? (
          <>Loading...</>
        ) : (
          <>
            {/* <button onClick={() => checkCountryCode('')}>test</button> */}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3} sx={{ mb: '2rem' }}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    name="searchTime"
                    id="outlined-select-currency"
                    select
                    label="Search Time"
                    value={filterData?.searchTime}
                    onChange={onChangeFilter}
                    required
                  >
                    <MenuItem key={'last7'} value={'last7'}>
                      Last 7 Days
                    </MenuItem>
                    <MenuItem key={'last30'} value={'last30'}>
                      Last 30 Days
                    </MenuItem>
                    <MenuItem key={'custom'} value={'custom'}>
                      Custom
                    </MenuItem>
                  </TextField>
                </Grid>
                {filterData?.searchTime === 'custom' && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <DatePicker
                        disableFuture
                        label="Start Date"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={filterData?.startDate}
                        onChange={(newValue) => {
                          setFilterData((prev) => ({
                            ...prev,
                            startDate: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DatePicker
                        disableFuture
                        label="End Date"
                        openTo="day"
                        minDate={filterData?.startDate || dayjs()}
                        views={['year', 'month', 'day']}
                        value={filterData?.endDate}
                        onChange={(newValue) => {
                          setFilterData((prev) => ({
                            ...prev,
                            endDate: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </LocalizationProvider> */}
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={3} sx={{ mb: '2rem' }}>
                <Grid item sm={12} md={6} className='d-none'>
                  <TextField
                    fullWidth
                    name="showcaseID"
                    id="outlined-select-currency"
                    select
                    label="Showcase"
                    value={filterData?.showcaseID}
                    onChange={(e) => setFilterData((prev) => ({ ...prev, showcaseID: e.target.value }))}
                    className="h-100"
                    sx={{ '& .MuiInputBase-root': { 'flex-grow': '1' } }}
                  >
                    {showcaseList?.length > 0 &&
                      showcaseList?.map((i) => (
                        <MenuItem key={i?._id} value={i?._id} sx={{ flexGrow: '1' }}>
                          {i?.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Tabs
                    value={currentTab}
                    onChange={(e, newV) => handleTabChange(e, newV)}
                    aria-label="Tabs where selection follows focus"
                    sx={{
                      '& .MuiTabs-flexContainer': { gap: smUp ? '25px' : '10px', flexWrap: 'wrap' },
                      '& .Mui-selected': {
                        color: 'white !important',
                        backgroundColor: informaGreen,
                      },
                      '& .MuiTabs-indicator': {
                        width: '0 !important',
                      },
                      '& .MuiTab-root': {
                        minHeight: '60px',
                        maxWidth: 'unset',
                      },
                    }}
                    className="expand_tabs"
                  >
                    <Tab
                      iconPosition={smUp ? 'start' : 'top'}
                      icon={<Iconify icon="material-symbols:edit-outline-sharp" width={smUp ? 30 : 20} />}
                      label="Manual Capture"
                    />
                    {/* <Tab
                      iconPosition={smUp ? 'start' : 'top'}
                      icon={<Iconify icon="mdi:credit-card-scan-outline" width={smUp ? 30 : 20} />}
                      label="Scan Business Card"
                    /> */}
                  </Tabs>
                </Grid>
              </Grid>
              <div
                role="tabpanel"
                hidden={currentTab !== 0}
                id={`simple-tabpanel-${0}`}
                aria-labelledby={`simple-tab-${0}`}
              >
                <Box sx={{ ...commonBoxStyles, mt: 3, py: 3 }}>
                  <form onSubmit={handleSubmitForm}>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={8} flexGrow={1}>
                        <Grid container spacing={2}>
                          <Grid item sm={12} md={12} flexGrow={1}>
                            <TextField
                              fullWidth
                              name="name"
                              label="Full Name"
                              value={formData?.name}
                              onChange={handleChange}
                              required
                            />
                          </Grid>
                          <Grid item sm={12} md={12} flexGrow={1}>
                            <TextField
                              fullWidth
                              name="email"
                              label="Email Address"
                              value={formData?.email}
                              onChange={handleChange}
                              required
                            />
                          </Grid>

                          <Grid item xs={6} sm={6} md={6} flexGrow={1}>
                            <TextField
                              fullWidth
                              name="countryCode"
                              id="outlined-select-currency"
                              select
                              label="Country Code"
                              value={formData?.countryCode?.value}
                              onChange={handleChange}
                              required
                            >
                              <MenuItem key={''} value={''}>
                                -- Select --
                              </MenuItem>
                              {formData?.countryCode?.list?.length > 0 &&
                                formData?.countryCode?.list?.map((i) => (
                                  <MenuItem key={`${i?.code}-${i?.name}`} value={i?.code}>
                                    {`${i?.name} (${i?.code})`}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} flexGrow={1}>
                            <TextField
                              fullWidth
                              name="phone"
                              label="Phone"
                              value={formData?.phone}
                              onChange={handleChange}
                              required
                            />
                          </Grid>

                          <Grid item sm={12} md={6} flexGrow={1}>
                            <TextField
                              fullWidth
                              name="company"
                              label="Company"
                              value={formData?.company}
                              onChange={handleChange}
                              required
                            />
                          </Grid>

                          <Grid item sm={12} md={6} flexGrow={1}>
                            <TextField
                              fullWidth
                              name="designation"
                              label="Designation"
                              value={formData?.designation}
                              onChange={handleChange}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={4} flexGrow={1}>
                        <Grid container spacing={2}>
                          <Grid item sm={12} flexGrow={1}>
                            <TextField
                              type={'textarea'}
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  padding: '6px 8px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  padding: '0',
                                },
                              }}
                              fullWidth
                              placeholder="Note"
                              name={'notes'}
                              onChange={handleChange}
                              value={formData?.notes}
                              multiline
                              rows={smUp ? 11 : 5}
                              // inputProps={{ 'data-index': index1 }}
                            />
                          </Grid>
                          {/* <Grid item sm={12} flexGrow={1}>
                            <TextField
                              type={'textarea'}
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  padding: '6px 8px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  padding: '0',
                                },
                              }}
                              fullWidth
                              placeholder="Whatsapp Message"
                              name={'whatsappMsg'}
                              onChange={handleChange}
                              value={formData?.whatsappMsg}
                              multiline
                              rows={5}
                              // inputProps={{ 'data-index': index1 }}
                            />
                          </Grid> */}
                        </Grid>
                      </Grid>

                      {/* {formData?.source === 'scan-business-card' && (
                        <Grid item sm={12} md={4} flexGrow={1}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="includeImage"
                                  onChange={handleChange}
                                  checked={formData?.includeImage}
                                />
                              }
                              label="Save Image"
                            />
                          </FormGroup>
                        </Grid>
                      )} */}

                      <div className="d-flex ms-auto mt-3">
                        <div>
                          <Button type="button" onClick={resetForm} variant="outlined" color="error">
                            Reset
                          </Button>
                        </div>

                        <div className="ms-3">
                          <Button type="submit" variant="contained">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </form>
                </Box>
              </div>
              <div
                role="tabpanel"
                hidden={currentTab !== 1}
                id={`simple-tabpanel-${1}`}
                aria-labelledby={`simple-tab-${1}`}
              >
                <Box sx={{ ...commonBoxStyles, mt: 3, py: 3 }} className="d-flex flex-column align-items-center">
                  {/* <WebcamCapture /> */}
                  <InputLabel className="my-auto me-3">Scan Card</InputLabel>
                  {currentTab === 1 && !cameraError?.status && !isLoadingPhoto && (
                    <>
                      <ReactHTML5Cam />
                      <h2 className="mt-2"> OR </h2>
                    </>
                  )}
                  <div className="d-flex flex-column flex-md-row " ref={imageUploadRef}>
                    <ImageUpload
                      customClass="my-auto mt-2 mt-md-0"
                      title={'Upload Business Card'}
                      // propAspect={16 / 9}

                      propSize={10}
                      customOpen={customOpenImageModal}
                      onClose={handlePhotoChange}
                      previousImgSrc={formData?.cardPhoto}
                    />
                  </div>
                  {!!formData?.cardPhoto && (
                    <div className="mt-3 flex-wrap mx-auto">
                      <img
                        src={formData?.cardPhoto}
                        alt="Business Card"
                        height={150}
                        // onError={handleImgErrorLogo}
                        // style={{ marginRight: smUp ? '2rem' : '0' }}
                      />
                      <div className="w-100 px-auto">Selected Image </div>
                    </div>
                  )}
                  <div className="d-flex mt-3">
                    <div>
                      <Button
                        type="button"
                        disabled={isLoadingPhoto}
                        onClick={resetForm2}
                        variant="outlined"
                        color="error"
                      >
                        Reset Photo
                      </Button>
                    </div>

                    <div className="ms-3">
                      <Button type="button" disabled={isLoadingPhoto} onClick={scanImage2} variant="contained">
                        {isLoadingPhoto ? 'Processing...' : 'Start Processing'}
                      </Button>
                    </div>
                  </div>
                </Box>
              </div>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}

export default UserLeadCapture;
