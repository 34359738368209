import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useParams } from 'react-router-dom';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
// layouts
import SimpleLayout from './layouts/simple';
//
import MergeImageTest from './pages/MergeImageTest';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import Page404 from './pages/common/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { LoginForm } from './sections/auth/login';
import { RegisterForm } from './sections/auth/register';
import ProtectedLayout from './layouts/protectedLayout/index';
import LandingPage from './pages/common/LandingPage';
import Unauthorized from './pages/common/Unauthorized';
import TestPage from './pages/adminPages/TestPage';
import UnderConstruction from './pages/common/UnderConstruction';
import useLocalStorage from './hooks/useLocalStorage';

// User Dashboard
import UserDashboardLayout from './layouts/user/dashboard/UserDashboardLayout';
import UserDashboard from './pages/userPages/dashboard/UserDashboard';
import UserLeads from './pages/userPages/dashboard/UserLeads';
import UserResourceAnalytics from './pages/userPages/dashboard/UserResourceAnalytics';
import UserProductQueries from './pages/userPages/dashboard/UserProductQueries';
import UserMarketplace from './pages/userPages/dashboard/UserMarketplace';
import UserMeetings from './pages/userPages/dashboard/UserMeetings';
import UserLeadCapture from './pages/userPages/dashboard/UserLeadCapture';
import UserConnectEvent from './pages/userPages/dashboard/UserConnectEvent';
import UserConnectEventProduct from './pages/userPages/dashboard/UserConnectEventProduct';

// User Showcase
import UserShowcaseLayout from './layouts/user/showcase/UserShowcaseLayout';
import UserProfile from './pages/userPages/showcase/UserProfile';
import UserResource from './pages/userPages/showcase/UserResource';
import UserProduct from './pages/userPages/showcase/UserProduct';
import UserContact from './pages/userPages/showcase/UserContact';
import UserShare from './pages/userPages/showcase/UserShare';
import UserTemplate from './pages/userPages/showcase/template/UserTemplate';

// Myprofile
import UserProfileLayout from './layouts/user/profile/UserProfileLayout';
import UserMyProfile from './pages/userPages/profile/UserMyProfile';
import UserVerify from './pages/userPages/profile/UserVerify';
import UserChangePassword from './pages/userPages/profile/UserChangePassword';

// Visitor pages
import VisitorDashoboardLayout from './layouts/visitor/VisitorDashboardLayout';
import VisitorFeed from './pages/visitorPages/VisitorFeed';
import VisitorProfile from './pages/visitorPages/VisitorProfile';
import VisitorQuery from './pages/visitorPages/VisitorQuery';

// Showcase pages
import ShowcaseLayout from './layouts/displayShowcase/ShowcaseLayout';
import ShowcaseAllInOne from './pages/displayShowcasePages/ShowcaseAllInOne';
import ShowcaseDashboard from './pages/displayShowcasePages/ShowcaseDashboard';
import ShowcaseProduct from './pages/displayShowcasePages/ShowcaseProduct';
import ShowcaseProductSingle from './pages/displayShowcasePages/ShowcaseProductSingle';
import ShowcaseContextVisitor from './pages/displayShowcasePages/ShowcaseContextVisitor';

import ShowcaseContact from './pages/displayShowcasePages/ShowcaseContact';

// Search Pages
import SearchLayout from './layouts/search/SearchLayout';
import SearchShowcase from './pages/search/SearchShowcase';
import SearchVisitor from './pages/search/SearchVisitor';
import SearchQRShowcase from './pages/search/SearchQRShowcase';

import { userRouteName, visitorRouteName, showcaseRouteName, adminRouteName } from './utils/constants';
import UserContext from './pages/userPages/dashboard/UserContext';

// ----------------------------------------------------------------------

export default function Router() {
    const roleData = useLocalStorage('sessionInfo')?.roles;

    const routes = useRoutes([
        {
            path: '/test',
            children: [
                { element: <Navigate to="/test/1" replace /> },
                { path: '1', element: <UserPage />, index: true },
                { path: '2', element: <BlogPage /> },
                { path: '3', element: <ProductsPage /> },
                { path: '4', element: <DashboardAppPage /> },
                { path: 'merge-image', element: <MergeImageTest /> },
            ],
        },
        {
            path: `/${userRouteName}`,
            element: <LandingPage type={userRouteName} />,
            children: [
                { path: '', element: <Navigate to={`/${userRouteName}/login`} replace /> },
                { path: 'login', element: <LoginForm type={userRouteName} />, index: true },
                { path: 'register', element: <RegisterForm type={userRouteName} /> },
            ],
        },
        {
            path: `/${userRouteName}/dashboard`,
            element: (
                // <UserDashboardLayout />,
                <ProtectedLayout allowedRoles={['owner']}>
                    <UserContext Layout={UserDashboardLayout} />
                </ProtectedLayout>
            ),
            children: [
                { path: '', element: <Navigate to={`/${userRouteName}/dashboard/overview`} replace /> },
                { path: 'overview', element: <UserDashboard />, index: true },
                { path: 'lead', element: <UserLeads /> },
                { path: 'resource-analytics', element: <UserResourceAnalytics /> },
                { path: 'queries', element: <UserProductQueries /> },
                { path: 'marketplace', element: <UserMarketplace /> },
                { path: 'meeting', element: <UserMeetings /> },
                { path: 'lead-capture', element: <UserLeadCapture /> },
                // { path: 'connect-event', element: <UserConnectEvent /> },
                // { path: 'connect-event/product', element: <UserConnectEventProduct /> },
            ],
        },
        {
            path: `/${userRouteName}/myprofile`,
            element: (
                // <UserDashboardLayout />,
                <ProtectedLayout allowedRoles={['owner']}>
                    <UserProfileLayout />
                </ProtectedLayout>
            ),
            children: [
                { path: '', element: <Navigate to={`/${userRouteName}/myprofile/edit`} replace /> },
                { path: 'edit', element: <UserMyProfile />, index: true },
                { path: 'verify', element: <UserVerify /> },
                { path: 'change-password', element: <UserChangePassword /> },
            ],
        },
        {
            path: `/${userRouteName}/showcase`,
            element: (
                // <UserShowcaseLayout />,
                <ProtectedLayout allowedRoles={['owner']}>
                    <UserShowcaseLayout />
                </ProtectedLayout>
            ),
            children: [
                { path: '', element: <Navigate to={`/${userRouteName}/showcase/profile`} replace /> },
                { path: 'profile', element: <UserProfile />, index: true },
                { path: 'resource', element: <UserResource /> },
                { path: 'product', element: <UserProduct /> },
                { path: 'contact', element: <UserContact /> },
                { path: 'share', element: <UserShare /> },
                { path: 'template', element: <UserTemplate /> },
                // { path: 'connect-event', element: <UserConnectEvent /> },
                // { path: 'connect-event/product', element: <UserConnectEventProduct /> },
                { path: 'template/product', element: <UserConnectEventProduct /> },
            ],
        },

        // {
        //   path: `/`,
        //   element: <LandingPage type={visitorRouteName} />,
        //   children: [
        //     // { element: <Navigate to={`/${visitorRouteName}/login`} />, index: true },
        //     { path: 'login', element: <LoginForm type={visitorRouteName} /> },
        //     { path: 'register', element: <RegisterForm type={visitorRouteName} /> },
        //   ],
        // },
        {
            path: `/${showcaseRouteName}`,
            element: <Navigate to={`/search/showcase`} replace />,
        },
        {
            path: `/${showcaseRouteName}/:showcaseUnique`,
            element: (
                // <ProtectedLayout allowedRoles={['visitor', 'owner']}>
                <ShowcaseLayout>
                    <ShowcaseContextVisitor />
                </ShowcaseLayout>
                // </ProtectedLayout>
            ),
            children: [
                {
                    path: '',
                    element: <ShowcaseAllInOne />,
                    // element: <NavigateSearchShowcaseHome />,
                    index: true,
                },
                { path: 'home', element: <ShowcaseDashboard /> },
                // ^^ company overview
                { path: 'product', element: <ShowcaseProduct /> },
                { path: 'contact', element: <ShowcaseContact /> },
                { path: 'product/:productID', element: <ShowcaseProductSingle /> },
            ],
        },
        // {
        //   path: `/`,
        //   element: (
        //     <ProtectedLayout allowedRoles={['visitor']}>
        //       <VisitorDashoboardLayout />
        //     </ProtectedLayout>
        //   ),
        //   children: [
        //     // { path: 'feed', element: <VisitorFeed /> },
        //     { path: 'profile', element: <VisitorProfile /> },
        //     { path: 'query', element: <VisitorQuery /> },
        //   ],
        // },
        // {
        //   path: `/search`,
        //   element: (
        //     <ProtectedLayout allowedRoles={['visitor', 'owner']}>
        //       <SearchLayout />
        //     </ProtectedLayout>
        //   ),
        //   children: [
        //     // { element: <Navigate to={`/search/showcase`} />,  },
        //     { path: 'showcase', element: <SearchShowcase />, index: true },
        //     { path: 'u', element: <SearchVisitor /> },
        //   ],
        // },
        {
            path: `qr`,
            element: (
                // <ProtectedLayout allowedRoles={['visitor', 'owner']}>
                <SimpleLayout />
                // </ProtectedLayout>
            ),
            children: [{ path: 'showcase/:showcaseID', element: <SearchQRShowcase /> }],
        },

        // {
        //   path: `/${adminRouteName}`,
        //   element: <LandingPage type={adminRouteName} />,
        //   children: [
        //     { element: <Navigate to="/admin/login" />, index: true },
        //     { path: 'login', element: <LoginForm type={adminRouteName} /> },
        //     { path: 'register', element: <RegisterForm type={adminRouteName} /> },
        //   ],
        // },
        {
            element: <SimpleLayout />,
            children: [
                {
                    element: (
                        <Navigate to={roleData?.includes('visitor') ? `/profile` : roleData?.includes('owner') ? '/user/showcase' : '/user/login'} />
                    ),
                    index: true,
                },
                { path: '404', element: <Page404 /> },
                { path: 'under-construction', element: <UnderConstruction /> },
                { path: 'unauthorized', element: <Unauthorized /> },
                // { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/user/login" replace />,
        },
    ]);

    return routes;
}

const NavigateSearchShowcaseHome = () => {
    const { showcaseUnique } = useParams();
    // useEffect(() => {}, []);

    return <Navigate to={`/${showcaseRouteName}/${showcaseUnique}/home`} replace />;
};
