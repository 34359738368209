import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiMiddleWare from '../../utils/useApiMiddleWare';
import { changeUser } from '../../redux/user/user.action';

function TestPage() {
  const UserData = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleUserChange = () => {
    const dataHere = { name: 'dalal' };
    dispatch(changeUser(dataHere));
  };

  const testapi = async () => {
    const data = await apiMiddleWare.getData('/test/alive');
    console.log(data);
  };

  return (
    <div>
      <h1>TestPage Redux</h1>
      <div>{JSON.stringify(UserData)}</div>
      <div>
        <button onClick={handleUserChange}>Change Name</button>
      </div>
      <div>
        <button onClick={testapi}>test api </button>
      </div>
    </div>
  );
}

export default TestPage;
