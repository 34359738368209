import { Box, Button, Container, Grid, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { defaultError, S3BucketBaseUrl, userRouteName, informaGreen } from '../../../utils/constants';
import Loader from '../../../components/loader';
import Iconify from '../../../components/iconify/Iconify';
import useResponsive from '../../../hooks/useResponsive';

function UserChangePassword() {
  const smUp = useResponsive('up', 'sm');

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [userData, setUserData] = useState({
    oldPass: {
      value: '',
    },
    newPass: {
      value: '',
    },
    newPass2: {
      value: '',
    },
  });

  const handleChange1 = (e) => {
    const { value, name } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value,
      },
    }));
  };

  const resetForm1 = () => {
    setUserData((prev) => ({
      ...prev,
      oldPass: { value: '' },
      newPass: { value: '' },
      newPass2: { value: '' },
    }));
    setShowPassword(false);
  };

  const handleNewPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const id = toast.loading('Please wait...');

    try {
      const sendData = await validateForm();
      const res = await apiMiddleWare({ url: '/users/change-password', data: sendData, type: 'post' });
      if (res?.data?.ok === true) {
        customToast({ updateId: id, type: 'update', msg: 'Password Successfully Changed', updateType: 'success' });
      } else {
        customToast({
          updateId: id,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
          updateType: 'error',
        });
      }
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      setIsLoading(false);
      return false;
    }
  };

  const validateForm = async () =>
    new Promise((resolve, reject) => {
      const sendData = {
        oldPass: userData?.oldPass?.value || '',
        newPass: userData?.newPass?.value || '',
      };

      if (!sendData?.oldPass) {
        reject(new Error(`Current password is required`));
        return false;
      }
      if (!sendData?.newPass) {
        reject(new Error(`New password is required`));
        return false;
      }
      if (sendData?.newPass !== userData?.newPass2?.value) {
        reject(new Error(`Both New Password should be same`));
        return false;
      }

      resolve(sendData);
      return true;
    });

  return (
    <Container maxWidth="xl" sx={{ px: smUp ? 4 : 2, pb: 2 }} className="h-100 w-100">
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <form onSubmit={handleNewPassword}>
            <Grid container spacing={2} className="">
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Current Password</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    name="oldPass"
                    type={showPassword ? 'text' : 'password'}
                    value={userData.oldPass.value}
                    onChange={handleChange1}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>New Password</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    // sx={{ width: '50%' }}
                    className="fullWidth-responsive"
                    name="newPass"
                    type={'password'}
                    value={userData.newPass.value}
                    onChange={handleChange1}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="side-by-side ">
                <Grid item xs={12} md={3}>
                  <InputLabel>Confirm Password</InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    name="newPass2"
                    type={showNewPassword ? 'text' : 'password'}
                    value={userData.newPass2.value}
                    onChange={handleChange1}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                            <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className=" d-flex justify-content-end mt-3">
              <div className="mx-3">
                <Button type="button" variant="outlined" color="error" disabled={isLoading} onClick={resetForm1}>
                  Reset
                </Button>
              </div>
              <div>
                <Button type="submit" variant="contained" disabled={isLoading}>
                  Change Password
                </Button>
              </div>
            </Grid>
          </form>
        </Box>
      )}
    </Container>
  );
}

export default UserChangePassword;
