// component
import SvgColor from '../../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'My Profile',
    path: '/user/myprofile/edit',
    icon: icon('my-profile-icon'),
  },
  {
    title: 'Verify Email/Phone',
    path: '/user/myprofile/verify',
    icon: icon('Verify-icon'),
  },
  {
    title: 'Change Password',
    path: '/user/myprofile/change-password',
    icon: icon('change-pass-icon'),
  },
];

export default navConfig;
