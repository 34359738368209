/* eslint-disable prefer-template */
import axios from 'axios';
import Cookies from 'js-cookie';
import secureLocalStorage from 'react-secure-storage';
import useClearUser from '../hooks/useClearUser';
import customToast from './customToast';
import {
  API_VER,
  API_BASE,
  BASE_URL,
  defaultError,
  userRouteName,
  visitorRouteName,
  adminRouteName,
  BASE_ANALYTICS_URL,
  API_BASE_ANALYTICS,
} from './constants';

const cleanerFunction = async () => {
  const sesInfo = secureLocalStorage.getItem('sessionInfo');
  secureLocalStorage.clear();
  Cookies.remove('x-auth');
  if (sesInfo.roles[0] === userRouteName) {
    window.location.href = BASE_URL + `/${userRouteName}/login`;
    // navigate('/user/login', { replace: true });
  } else if (sesInfo.roles[0] === visitorRouteName) {
    window.location.href = BASE_URL + `/login`;
    // navigate('/login', { replace: true });
  } else if (sesInfo.roles[0] === adminRouteName) {
    window.location.href = BASE_URL + `/${adminRouteName}/login`;
    // navigate('/admin/login', { replace: true });
  } else {
    window.location.href = BASE_URL + `/login`;
  }
};

const apiMiddleWare = async ({ type, url, data, callType = '' }) => {
  try {
    // const cleanerFunction = async () => {
    //   const sesInfo = secureLocalStorage.getItem('sessionInfo');
    //   secureLocalStorage.clear();
    //   Cookies.remove('x-auth');
    //   if (sesInfo.roles[0] === userRouteName) {
    //     window.location.href = BASE_URL + `/${userRouteName}/login`;
    //     // navigate('/user/login', { replace: true });
    //   } else if (sesInfo.roles[0] === visitorRouteName) {
    //     window.location.href = BASE_URL + `/login`;
    //     // navigate('/login', { replace: true });
    //   } else if (sesInfo.roles[0] === adminRouteName) {
    //     window.location.href = BASE_URL + `/${adminRouteName}/login`;
    //     // navigate('/admin/login', { replace: true });
    //   }
    // };
    // const clearFunction = useClearUser();
    let res;
    const sesInfo = secureLocalStorage.getItem('sessionInfo');
    const role = sesInfo?.roles?.[0] || 'guest';
    if (type === 'get') {
      const headers = {
        'content-type': 'application/json',
        'x-user': role,
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuth || false;
      if (callType) headers['Access-Control-Allow-Origin'] = '*';
      res = await axios({
        method: 'GET',
        url:
          callType === 'analytics' ? BASE_ANALYTICS_URL + API_BASE_ANALYTICS + API_VER + url : API_BASE + API_VER + url,
        headers,
      });
      if (res.status === 401) {
        // location.href = 'http:localhost:3000/'
        // clearFunction();
        console.log('clearner running GET');
        cleanerFunction();
      }
      // return res;
    } else if (type === 'post') {
      const headers = {
        // 'content-type': 'application/json',
        'x-user': role,
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuth || false;
      if (callType) headers['Access-Control-Allow-Origin'] = '*';
      // const a1 = data;
      res = await axios({
        method: 'post',
        url:
          callType === 'analytics' ? BASE_ANALYTICS_URL + API_BASE_ANALYTICS + API_VER + url : API_BASE + API_VER + url,
        headers,
        data,
      });
      if (res.status === 401) {
        // clearFunction();
        console.log('clearner running POST');
        cleanerFunction();
      }
    } else if (type === 'delete') {
      const headers = {
        'content-type': 'application/json',
        'x-user': role,
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuth || false;
      res = await axios.delete(API_BASE + API_VER + url, headers);
      if (res.status === 401) {
        // clearFunction();
        console.log('clearner running DELETE');
        cleanerFunction();
      }
    } else if (type === 'patch') {
      const headers = {
        'x-user': role,
        // 'content-type': 'application/json',
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuth || false;
      if (callType) headers['Access-Control-Allow-Origin'] = '*';
      // const a1 = data;
      res = await axios({
        method: 'patch',
        url: API_BASE + API_VER + url,
        headers,
        data,
      });
      if (res.status === 401) {
        // clearFunction();
        console.log('clearner running POST');
        cleanerFunction();
      }
    } else if (type === 'put') {
      const headers = {
        'x-user': role,
        // 'content-type': 'application/json',
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuth || false;
      if (callType) headers['Access-Control-Allow-Origin'] = '*';
      // const a1 = data;
      res = await axios({
        method: 'put',
        url: API_BASE + API_VER + url,
        headers,
        data,
      });
      if (res.status === 401) {
        // clearFunction();
        console.log('clearner running POST');
        cleanerFunction();
      }
    }
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      cleanerFunction();
    }

    customToast({
      msg:
        err?.response?.data?.errors?.[0]?.message || err?.response?.data?.errors?.[0]?.msg || err?.message || err?.msg,
      type: 'error',
    });
    if (err?.response?.data?.errors?.[0]?.message === 'Email is not registered!') {
      // for visitor login
      return 'Email is not registered!';
    }
    return false;
  }
};
export default apiMiddleWare;
