import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';
import { informaGreen } from '../../../utils/constants';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '6%' } },
    fill: { type: chartData.map((i) => i.fill) },
    colors: [informaGreen],
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    yaxis: { forceNiceScale: true, min: 0 },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card
      sx={{
        bgcolor: 'white',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      }}
      {...other}
    >
      <CardHeader
        // title={title}
        subheader={subheader}
      />
      {chartLabels?.length === 0 ? (
        <div
          style={{ height: '100%', minHeight: '380px' }}
          className="d-flex justify-content-center align-items-center"
        >
          No Data Found
        </div>
      ) : (
        <Box sx={{ p: 2, pb: 1 }} dir="ltr">
          <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
        </Box>
      )}
    </Card>
  );
}
